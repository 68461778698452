import {globalDataActionTypes} from "./globalDataActions";
import {globalDataInitialState, myChoicePartnerItem} from './GlobalDataContext';
import {postalCodeFormat} from '../../helpers/postalCodeFormat';
import {myChoicePartnerId} from "../../config/partnersConfig";
import {clearLocalGlobalState, getLocalGlobalState, setLocalGlobalState} from "./helper";

const updateUser = (data, oldData) => {
    return {
        id: data.id ? data.id : oldData.id,
        email: data.email ? data.email : oldData.email,
        first_name: data.first_name ? data.first_name : oldData.first_name,
        last_name: data.last_name ? data.last_name : oldData.last_name
    }
};

export const globalDataReducer = (state, action) => {

    switch (action.type) {

        case globalDataActionTypes.UPDATE_UID:
            return {...state, uid: action.uid};

        case globalDataActionTypes.UpdateAuth:
            return {...state, isAuth: action.isAuth};

        case globalDataActionTypes.UpdateSignedInRedirect:
            return {...state, signedInRedirect: action.payload};

        case globalDataActionTypes.UpdateUser:
            return {...state, ...updateUser(action.data, state)};

        case globalDataActionTypes.GetLocalPostal:
            return {...state, ...getLocalGlobalState(state.partner.id)};

        case globalDataActionTypes.UpdatePostal:
            if (action.payload) {
                if (action.payload.city && action.payload.postal_code && action.payload.location_index) {
                    setLocalGlobalState({
                        city: action.payload.city,
                        postal_code: postalCodeFormat(action.payload.postal_code),
                        location_index: action.payload.location_index,
                        province_name: action.payload.province_name,
                        province_code: action.payload.province_code,
                    }, state.partner.id);

                    return {
                        ...state,
                        isPostal: true,
                        postal: {
                            city: action.payload.city,
                            postal_code: postalCodeFormat(action.payload.postal_code),
                            location_index: action.payload.location_index,
                            province_name: action.payload.province_name,
                            province_code: action.payload.province_code
                        }
                    }
                }
            }
            return state;

        case globalDataActionTypes.UpdatePostalErrors:
            return {...state, postalError: action.payload};

        case globalDataActionTypes.ClearPostal:
            clearLocalGlobalState(state.partner.id);
            return {...state, isPostal: false, postal: {}};

        case globalDataActionTypes.ClearUser:
            return globalDataInitialState;

        case globalDataActionTypes.UpdatePartnerData:
            const localID = action.payload.url_path || myChoicePartnerId;
            return {
                ...state,
                partner: {
                    id: localID,
                    name: action.payload.name || 'My Choice',
                    logotype: action.payload.logo || '',
                    logotypeLink: action.payload.redirect_url || '',
                    primaryActiveColor: action.payload.primary_color || '#F9733E',
                    primarySecondaryColor: action.payload.secondary_color || '#4ABDAC',
                },
                ...getLocalGlobalState(localID),
            };

        case globalDataActionTypes.ClearPartnerData:
            return {...state, partner: {
                    ...myChoicePartnerItem,
                    ...getLocalGlobalState(myChoicePartnerItem.id),
                }};

        default:
            return state;
    }
};
