import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import DriverList from "../../../../ui/vehicleFormInputs/DriverLists";
import {
    formOptionsGroupTrafficTickets,
    formOptionsInsuranceCancellation,
    formOptionsLicenseSuspensions
} from "../../../../../config/formConstants";
import {currentDate} from "../../../../../config/dateConfig";
import DriverDataContext from "../../../../../contexts/formContexts/DriverDataContext";
import {MinTicketDate} from "../../constants";

const ListItems = () => {

    const {driverData, driverTab} = useContext(DriverDataContext);
    const [driverName, setDriverName]= useState('');
    const [minGOneDate, setMinGOneDate] = useState('');

    useEffect(()=>{
        setDriverName(driverData.items[driverTab.activeItem].first_name);
    },[driverData.items[driverTab.activeItem].first_name]);

    useEffect(()=>{
        setMinGOneDate(driverData.items[driverTab.activeItem].licence_info.g1_licence_date || driverData.items[driverTab.activeItem].minDates.gOneMinDate);
    },[driverData.items[driverTab.activeItem].licence_info.g1_licence_date, driverData.items[driverTab.activeItem].minDates.gOneMinDate]);

    return(
        <>
            <div className="bottomFormWrapper">
                <DriverList
                    title='Insurance Cancellation'
                    description={`Has ${driverName} had an insurance company \n cancellation or a time without insurance \n coverage, within the past 3 years?`}
                    toolTip='If an insurance company cancels your policy, it will increase your future insurance premiums. The most common reasons for cancellations include missed payments, excessive claims, false declarations, or criminal actions, for example. If you cancel the insurance policy, it is not relevant.'
                    toolTipId='insurence-tool-tip'
                    isOpen={true}
                    navigationName='insurance_cancellation'
                    listName='insurance_cancellation_list'
                    emptyItem={{reason: '',start_date:'',end_date:''}}
                    itemStructure={[
                        {
                            type:'select',
                            name: 'Reason',
                            formName: 'reason',
                            optionsGroup:[],
                            options: formOptionsInsuranceCancellation
                        },
                        {
                            type: 'month',
                            name: 'Start date',
                            formName: 'start_date',
                            minDate: minGOneDate,
                            maxDate: currentDate
                        },
                        {
                            type: 'month',
                            name: 'End date',
                            startDateName: 'start_date',
                            dateType: 'end_date',
                            formName: 'end_date',
                            minDate: minGOneDate,
                            maxDate: currentDate
                        }
                    ]}
                />

            </div>

            <div className="bottomFormWrapper">

                <DriverList
                    title='Licence Suspensions'
                    description={`Has ${driverName}  had a licence  \n suspension within the past 3 years?`}
                    toolTip='Three licence suspensions are common: 1) driving under the influence, 2) being found guilty of drinking and driving, and 3) a police officer finding reasonable grounds for a driving offence. Licence suspensions are also different from driving prohibitions as part of a criminal sentence.'
                    toolTipId='licence-suspensions-tool-tip'
                    navigationName='licence_suspension'
                    listName='licence_suspension_list'
                    emptyItem={{reason: '',suspension_date:'',reinstatement_date:''}}
                    itemStructure={[
                        {
                            type:'select',
                            name: 'Reason for licence suspension',
                            formName: 'reason',
                            optionsGroup:[],
                            options: formOptionsLicenseSuspensions
                        },
                        {
                            type: 'month',
                            name: 'Licence suspension date',
                            formName: 'suspension_date',
                            minDate: minGOneDate,
                            maxDate: currentDate
                        },
                        {
                            type: 'month',
                            name: 'Licence reinstatement date',
                            formName: 'reinstatement_date',
                            startDateName: 'suspension_date',
                            dateType: 'end_date',
                            minDate: minGOneDate,
                            maxDate: currentDate
                        }
                    ]}
                />


                <DriverList
                    title='Accidents'
                    description={`Has ${driverName} ever been at-fault for \n any accidents?`}
                    toolTip='An at-fault accident means you are responsible for the car accident according to the investigative report. Whether you did not obey traffic signals or hit the brakes too late, it will show up on your insurance record for up to ten years. Insurers access at-fault information if one party reports it, so it is best to disclose previous incidents. A clean driving record mitigates additional hikes to your insurance premium.'
                    toolTipId='accidents-tool-tip'
                    navigationName='accident'
                    listName='accident_list'
                    emptyItem={{accident_date:''}}
                    itemStructure={[
                        {
                            type: 'month',
                            name: 'Accident date',
                            formName: 'accident_date',
                            minDate: minGOneDate,
                            maxDate: currentDate
                        }
                    ]}
                />

                <DriverList
                    title='Traffic Tickets'
                    description={`Except for parking, has ${driverName} had any \n driving related tickets within the past 3 years?`}
                    toolTip='Insurers want to make sure drivers are safe, which means minimizing driving infractions and encouraging good behaviour on the roads. Some of the most common liabilities include speeding, failure to stop, running a red light, reckless driving, and seatbelt negligence. It affects your insurance premium for up to three years and may void your policy.'
                    toolTipId='traffic-ticket-tool-tip'
                    navigationName='ticket'
                    listName='ticket_list'
                    emptyItem={{reason: '',ticket_date:''}}
                    itemStructure={[
                        {
                            type:'select',
                            name: 'Reason for ticket',
                            formName: 'reason',
                            optionsGroup: formOptionsGroupTrafficTickets,
                            options:[]
                        },
                        {
                            type: 'month',
                            name: 'Ticket date',
                            formName: 'ticket_date',
                            minDate: moment(new Date(minGOneDate)).isAfter(new Date(MinTicketDate))? minGOneDate:MinTicketDate,
                            maxDate: currentDate
                        }
                    ]}
                />
            </div>
        </>
    )
}
export default ListItems;
