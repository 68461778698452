export const validationManagerActionTypes = {
    UpdateValid: 'UpdateValid',
    UpdateProcess: 'UpdateStatus',
    UpdateError: 'UpdateError'
}

export const validationStatusTypes = {
    Waiting: 'Waiting',
    InProcess: 'InProcess',
    Rejected: 'Rejected',
    Validated: 'Validated'
}

export const NO_ERRORS = 'NO_ERRORS';

export const validationManagerState = {
    isValid: false,
    status: validationStatusTypes.Waiting,
    errorMessage: ''
};
