import React, {useState} from "react";

export const ToolTipIcon = (props) => {
    const [isHover, setIsHover] = useState(false);
  return(
      <div
          style={{
              width: '18px',
              height: '18px',
              overFlow: 'hidden',
              cursor: 'pointer',
          }}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
          onBlur={() => setIsHover(false)}
          {...props}
      >
          <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 18 18"
              width="18px"
              height="18px"
          >
              {
                  !isHover &&
                  <path fill="var(--secondary-color)" d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,1C4.6,1,1,4.6,1,9s3.6,8,8,8s8-3.6,8-8
	S13.4,1,9,1z"/>
              }
              {
                  isHover &&
                  <circle fill="var(--secondary-color)" cx="9" cy="9" r="9"/>
              }
              <g fill={isHover ? "#FFF" : "var(--secondary-color)" }>
                  <path d="M9.8,13.8c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8S8.6,13,9,13S9.8,13.3,9.8,13.8z"/>
                  <path d="M9,11.6c-0.3,0-0.6-0.3-0.6-0.6v-0.8c0-0.7,0.5-1.4,1.1-1.6c0.8-0.3,1.4-1.1,1.5-2 c0-1.1-1-2-2.1-2c-1.1,0-2,0.9-2,2c0,0.3-0.3,0.6-0.6,0.5C6.1,7.2,5.8,7,5.8,6.7c0-1.8,1.4-3.2,3.1-3.2c1.8,0,3.2,1.4,3.2,3.1 c0,0,0,0.1,0,0.1c-0.1,1.4-1,2.6-2.2,3C9.7,9.8,9.6,10,9.6,10.2V11C9.6,11.3,9.3,11.6,9,11.6C9,11.6,9,11.6,9,11.6z"/>
              </g>
              <circle fill="transparent" cx="9" cy="9" r="9"/>
          </svg>
      </div>
  );
};
