import React, {useContext, useEffect, useReducer} from "react";
import {Link} from "react-router-dom";

import {UserDataManager} from "../../managers/DataManagers";

import {myProfileReducer} from "./myProfileReducer";
import {requestStatusTypes} from "../../config/constants";
import {MyProfileActionTypes, myProfileInitialState} from "./constants";
import {formMaritalTypes} from "../../config/formConstants";
import GlobalDataContext from "../../contexts/GlobalDataContext";

function returnMaritalStatusName(value){
    let maritalName = formMaritalTypes.length>0 ? formMaritalTypes[0].name : '';
    for(let k in formMaritalTypes){
        if(value===formMaritalTypes[k].value){
            maritalName = formMaritalTypes[k].name;
        }
    }
    return maritalName;
}

export const MyProfileView = () => {

    const {globalData} = useContext(GlobalDataContext);
    const [localState, updateLocalState] = useReducer(myProfileReducer, myProfileInitialState);

    const {userRequestState, userResponseData, getUserData} = UserDataManager();

    useEffect(() => {
        if (!localState.isFirst) {
            getUserData();
            updateLocalState({type: MyProfileActionTypes.UPDATE_IS_FIRST, payload: true});
        }
    });

    return (
        <div className='myProfileWrapper'>
            {

                userRequestState === requestStatusTypes.Success && userResponseData &&
                <>
                    <div className='myProfileInfo'>
                        <div className='myProfileFiledLeftSide'>
                            <div>
                                <h5>Full Name</h5>
                                <p>{`${userResponseData.last_name} ${userResponseData.first_name}`}</p>
                                <h5>Marital Status</h5>
                                <p>{returnMaritalStatusName(userResponseData.marital_status)}</p>
                                <h5>Date of birth</h5>
                                <p>{userResponseData.dob}</p>
                            </div>
                            <div>
                                <h5>Occupation</h5>
                                <p>{userResponseData.occupation}</p>
                                <h5>Gender</h5>
                                <p>{userResponseData.gender}</p>
                                <h5>Email</h5>
                                <p>{userResponseData.email}</p>
                            </div>
                        </div>
                        <div className='myProfileFiledRightSide'>
                            <div>
                                <h5>Password</h5>
                                <Link to={`/${globalData.partner.id}/profile/password/change`} className='passChangeLink'>Change Password</Link>
                            </div>
                        </div>
                    </div>

                    < div className='btnWrapper'>
                        <Link to={`/${globalData.partner.id}/profile/edit`} className='profileActionBtn'>Edit</Link>
                    </div>
                </>
            }
        </div>
    );
};
