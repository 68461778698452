import {useContext} from "react";
import {calculateRangeHours} from "../dateHelper";
import {currentDate} from "../../config/dateConfig";
import {MAX_LOCAL_STORAGE_HOURS} from "../../config/viewConfig";
import {viewModalActionTypes, viewRedirectActionTypes} from "../../contexts/ViewContext/viewContextActions";
import ViewContext from "../../contexts/ViewContext";
import GlobalDataContext from "../../contexts/GlobalDataContext";
import {globalDataActionTypes} from "../../contexts/GlobalDataContext/globalDataActions";
import {PageConfigContext} from "../../contexts/formContexts/PageConfigContext";

const UseClearForm = () => {

    const { globalData, updateGlobalData } = useContext(GlobalDataContext);
    const { updateViewModal, updateViewRedirect } = useContext(ViewContext);
    const { pageConfig } = useContext(PageConfigContext);

    const checkIsExpiredWithClear = () => {
        const inactiveHours = Number(calculateRangeHours(currentDate.toISOString(), pageConfig.requestDate));
        if (pageConfig.isQuoteRequested && (inactiveHours > (MAX_LOCAL_STORAGE_HOURS -1))) {
            updateViewRedirect({type:viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}/vehicle/form/clear`});
        }
        /* todo please remove after 22.02.2022 */
        if (globalData.postal.original_postal_code) {
            updateGlobalData({type: globalDataActionTypes.ClearPostal});
            updateViewRedirect({type:viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}/vehicle/form/clear`});
        }
        /* end */
    }

    const checkIsExpiredWithModal = () => {
        updateViewModal({
            type: viewModalActionTypes.OpenMessageModal,
            title: 'Your session has expired',
            description: 'Please start the quoter again.',
        });
        checkIsExpiredWithClear();
    }

    const checkIsExpired = () => {
        const inactiveHours = Number(calculateRangeHours(currentDate.toISOString(), pageConfig.requestDate));
        return !!(pageConfig.isQuoteRequested && (inactiveHours > (MAX_LOCAL_STORAGE_HOURS - 1)));
    }

    return {
        checkIsExpiredWithClear,
        checkIsExpiredWithModal,
        checkIsExpired,
    }
}

export default UseClearForm;
