import React, {useContext, useMemo} from "react";
import ViewContext from "../../../contexts/ViewContext";
import FormMobileTab from "./FormMobileTab";
import FormDesktopTab from "./FormDesktopTab";

const FormTabWrapper = (props) => {

    const {viewSize} = useContext(ViewContext);

    const localIsMobile = useMemo(()=>{
        return viewSize.isMobile;
    },[viewSize.isMobile]);

    return (
        <div className={`${localIsMobile ? 'mobileTabNavigation' : 'tabNavigationWrapper'}`}>
            {
                localIsMobile &&
                <FormMobileTab isVehicle={props.isVehicle} isDriver={props.isDriver} isRecalculate={props.isRecalculate}/>
            }
            {
                !localIsMobile &&
                <FormDesktopTab isVehicle={props.isVehicle} isDriver={props.isDriver} isRecalculate={props.isRecalculate}/>

            }
        </div>
    )
}

export default FormTabWrapper;
