import {useContext, useReducer} from "react";
import {validationManagerReducer} from "./validationManagerReducer";
import {NO_ERRORS, validationManagerActionTypes, validationManagerState, validationStatusTypes} from "./constants";
import ViewContext from "../../contexts/ViewContext";
import {
    viewModalActionTypes,
    viewRedirectActionTypes
} from "../../contexts/ViewContext/viewContextActions";
import {PrimaryUseTypes} from "../../contexts/formContexts/VehicleDataContext/vehicleDataReducer";
import VehicleDataContext from "../../contexts/formContexts/VehicleDataContext";
import {vehicleDataActionTypes} from "../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import GlobalDataContext from "../../contexts/GlobalDataContext";

const StepOneValidationManager = () => {

    const [localState, updateLocalState] = useReducer(validationManagerReducer, validationManagerState);

    const {globalData} = useContext(GlobalDataContext);
    const {vehicleData, updateVehicleTab} = useContext(VehicleDataContext);

    const {updateViewRedirect, updateViewModal} = useContext(ViewContext);

    function returnObjectValid(obj, id) {
        let errorMessage = NO_ERRORS;

        if (!obj.year) {
            return `Please enter your Vehicle ${id} year`;
        }
        if (!obj.make) {
            return `Please enter your Vehicle ${id} manufacture`;
        }

        if (!obj.model) {
            return `Please enter your ${obj.make} model`;
        }

        if (!obj.purchase_date) {
            return `Please enter when you purchased your ${obj.make}`;
        }

        if (!obj.parking_location) {
            return `Please enter your ${obj.make} parking location`;
        }

        if (!obj.distance_daily) {
            return `Please enter your average daily distance driven for your ${obj.make}`;
        }

        if (!obj.distance_business && obj.primary_use === PrimaryUseTypes.BUSINESS) {
            return `Please enter you ${obj.make} distance business`;
        }

        if (!obj.distance_yearly) {
            return `Please enter your yearly distance driven for your  ${obj.make}`;
        }

        return errorMessage;
    }

    const localValidation = () => {

        updateLocalState({type: validationManagerActionTypes.UpdateProcess, payload: validationStatusTypes.InProcess});

        let allErrors = [];

        if (vehicleData.items) {
            if (vehicleData.items.length > 0) {
                for (let k in vehicleData.items) {
                    let obj = returnObjectValid(vehicleData.items[k], (Number(k) + 1));

                    if (obj !== NO_ERRORS) {
                        if (allErrors.length < 1) {
                            updateVehicleTab({type:vehicleDataActionTypes.SelectItem,payload:Number(k)})
                            //errorDispatch({type: errorContextActionTypes.AddVehicleId, payload: Number(k)});
                        }
                        allErrors.push(obj);
                    }
                }
            } else {
                allErrors.push('You have no Vehicles, please enter your vehicle');
            }
        } else {
            allErrors.push('You have no Vehicles, please enter your vehicle');
        }

        if (allErrors.length > 0) {
            updateViewModal({
                type: viewModalActionTypes.OpenMessageModal,
                title: allErrors[0],
                description: '',
                submitPath: ''
            });
            updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}/`});
            updateLocalState({type: validationManagerActionTypes.UpdateProcess, payload: validationStatusTypes.Rejected});
        } else {
            updateLocalState({type: validationManagerActionTypes.UpdateValid, payload: true});
            updateLocalState({type: validationManagerActionTypes.UpdateProcess, payload: validationStatusTypes.Validated});
        }
    }

    return {
        stepOneIsValid: localState.isValid,
        stepOneStatus: localState.status,
        stepOneValidation: localValidation
    }
}

export default StepOneValidationManager;
