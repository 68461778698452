import React, {useContext} from "react";
import ViewContext from "../../../contexts/ViewContext";
import {viewHeaderActionTypes} from "../../../contexts/ViewContext/viewContextActions";

const ButtonSignOut = (props) => {

    const {updateViewHeader} = useContext(ViewContext);

    const handleSignOut = () => {
      if(props.signOut){
          props.signOut();
      }
      updateViewHeader({type:viewHeaderActionTypes.CloseAll});
    };

    return(
        <button onClick={handleSignOut} className="logOutBtn">Logout
        </button>
    )
};

export default ButtonSignOut;
