import React, {useContext, useReducer} from "react";
import {quoteDataReducer} from "./quoteDataReducer";
import {myChoicePartnerId} from "../../../config/partnersConfig";
import {getLocalInitialValue} from "../helper";
import GlobalDataContext from "../../GlobalDataContext";

const QuoteDataContext = React.createContext();

const quoteDataDefaultState = {
    quoteTab: [],
    quotes: [],
    isRated: false,
    partnerID: myChoicePartnerId,
}

export const QuoteDataContextProvider = (props) => {

    const {globalData} = useContext(GlobalDataContext);

    const [quoteData, updateQuoteData] = useReducer(quoteDataReducer, getLocalInitialValue('quoteData', quoteDataDefaultState, globalData.partner.id));

    return(
        <QuoteDataContext.Provider value={{
            quoteData, updateQuoteData
        }}>
            {
                props.children
            }
        </QuoteDataContext.Provider>
    )
}

export default QuoteDataContext;
