import moment from "moment";

export const monthArray = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

export function returnDateNumber(nmb) {
    return `${nmb<10 ? '0' : ''}${nmb}`;
}

export const monthObjects = [
    {name:'January',value:1,maxDays:31},
    {name:'February',value:2,maxDays:28},
    {name:'March',value:3,maxDays:31},
    {name:'April',value:4,maxDays:30},
    {name:'May',value:5,maxDays:31},
    {name:'June',value:6,maxDays:30},
    {name:'July',value:7,maxDays:31},
    {name:'August',value:8,maxDays:31},
    {name:'September',value:9,maxDays:30},
    {name:'October',value:10,maxDays:31},
    {name:'November',value:11,maxDays:30},
    {name:'December',value:12,maxDays:31}
];

export function returnDayRamge(){
    let dayArray = [];
    for(let i=1;i<32;i++){
        dayArray.push({name:returnDateNumber(i), value:Number(i)})
    }
    return dayArray;
}

export function returnYearRange(minDate, maxDate) {
    let yearArray = [];
    for(let i=Number(moment(maxDate).format('YYYY'));i>=Number(moment(minDate).format('YYYY'));i--){
        yearArray.push({
            name: i,
            value: i
        });
    }
    return yearArray;
}

export function returnMonthToRend(str){
    let expYear = '';
    let expMonth = '';
    const dateArray = str.split('-');
    for(let k in dateArray){
        if(dateArray[k].length>2){
            expYear = dateArray[k]
        }
        else{
            expMonth = monthArray[Number(Number(dateArray[k])-1)];
        }
    }
    return `${expMonth} ${expYear}`
}

export function compareNumbers(number,min, max) {
    if(number<min){
        return min;
    }
    else{
        if(number>max){
            return max;
        }
        else{
            return number;
        }
    }

}

export function returnFullDate(dateStr) {
    return dateStr.length === 7 ? `${dateStr}-01` : dateStr;
}

export function calculateRangeHours(dateFrom, dateTo) {
    return moment(dateFrom).diff(moment(dateTo),'hours');
}

export function calculateRangeYear(dateFrom, dateTo) {
  return moment(dateFrom).diff(moment(dateTo),'years');
}

export function addYearToDate(date, year) {
  return moment(date).add(year, 'years');
}

export function subMonthFromDate(date, month) {
  return moment(date).subtract(month, 'months');
}
