import React, {useContext, useEffect, useReducer, useState} from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useParams,
} from "react-router-dom";
import ErrorPage from "../views/ErrorPage";
import {myChoicePartnerId} from "../config/partnersConfig";
import {AuthRefreshDataManager, PartnerDataManager, PostalDataManager} from "../managers/DataManagers";
import GlobalDataContext from "../contexts/GlobalDataContext";
import {ContentRedirect, RouterListener} from "../AppWrapper/AppHelper";
import AppHeader from "../components/layout/headers/AppHeader/AppHeaderWrapper";
import VehicleHeader from "../components/layout/headers/VehicleHeader/VehicleHeaderWrapper";

import {VehicleDataContextProvider} from "../contexts/formContexts/VehicleDataContext";
import {DriverDataContextProvider} from "../contexts/formContexts/DriverDataContext";
import {DiscountDataContextProvider} from "../contexts/formContexts/DiscountDataContext";
import {QuoteDataContextProvider} from "../contexts/formContexts/QuoteDataContext";
import {FormConfigStateProvider} from "../contexts/formContexts/FormConfigState";
import {PageConfigContextProvider} from "../contexts/formContexts/PageConfigContext";
import ViewContext from "../contexts/ViewContext";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../helpers/hooksLocalRenderState";
import {globalDataActionTypes} from "../contexts/GlobalDataContext/globalDataActions";
import {ContentWrapper} from "./ContentWrapper";
import {setInterceptors} from "../services/axiosInterceptors";
import localStorageService from "../services/localStorageService";
import {requestStatusTypes} from "../config/constants";
import {viewLoaderActionTypes} from "../contexts/ViewContext/viewContextActions";
import {AppRoutes} from "./AppRoutes";
import ModalWrapper from "../components/layout/modals/ModalWrapper";
import {convertHexToRGBA} from "../helpers/hexToRgba";

const RedirectWithQuery = ({ preserveQueryString, ...props }) => {

  const { to, ...rest } = props
  const search = window.location.search
  return <Redirect to={{...to, search }} {...rest} />
}

const AppNestedSwitch = () => {
    const {globalData} = useContext(GlobalDataContext);

    const lS = new localStorageService();

    const {viewHeader, updateViewLoader} = useContext(ViewContext);

    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount:2});

    const {refreshSignIn, authRequestState, authRequestError} = AuthRefreshDataManager();

    const {postalRequestState, getPostal} = PostalDataManager();


    useEffect(()=>{
        if(!renderState.isRender && !renderState.isFirst){
            if(renderState.requestCount===2){
                if(authRequestState!==requestStatusTypes.Waiting && authRequestError!==requestStatusTypes.Loading){
                    updateRenderState({
                        type:renderStateActionTypes.UpdateRequest,
                        payload:1
                    });
                }

            }
            if(renderState.requestCount===1){
                if(globalData.isPostal){
                    if(postalRequestState===requestStatusTypes.Waiting){
                        getPostal(globalData.postal.postal_code, false);
                    }
                    if(postalRequestState!==requestStatusTypes.Waiting && postalRequestState!==requestStatusTypes.Loading){
                        updateRenderState({type:renderStateActionTypes.UpdateRequest, payload:0});
                    }
                }
                else{
                    updateRenderState({type:renderStateActionTypes.UpdateRequest, payload:0});
                }
            }
            if(renderState.requestCount===0){
                updateRenderState({type:renderStateActionTypes.UpdateRender,payload:true});
                updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
            }
        }

    }, [renderState,authRequestState, postalRequestState])

    useEffect(() => {
        if (renderState.isFirst) {
            if (lS.getRefreshToken(globalData.partner.id)) {
                refreshSignIn();
            } else {
                updateRenderState({type:renderStateActionTypes.UpdateRequest, payload:1});
            }
            updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
            document.documentElement.style.setProperty('--primary-color', globalData.partner.primaryActiveColor);
            document.documentElement.style.setProperty('--primary-hover', convertHexToRGBA(globalData.partner.primaryActiveColor,0.6));
            document.documentElement.style.setProperty('--secondary-color', globalData.partner.primarySecondaryColor);
        }
    });

    return (
        <>
            {
                renderState.isRender &&
                <PageConfigContextProvider>
                    <FormConfigStateProvider>
                        <VehicleDataContextProvider>
                            <DriverDataContextProvider>
                                <DiscountDataContextProvider>
                                    <QuoteDataContextProvider>
                                        <Router>
                                            <ModalWrapper />
                                            <RouterListener />
                                            <ContentRedirect />
                                            <AppHeader />
                                            <ContentWrapper>
                                                {
                                                    viewHeader.vehicleNavigation &&
                                                    <VehicleHeader />
                                                }
                                                <AppRoutes />
                                            </ContentWrapper>
                                        </Router>
                                    </QuoteDataContextProvider>
                                </DiscountDataContextProvider>
                            </DriverDataContextProvider>
                        </VehicleDataContextProvider>
                    </FormConfigStateProvider>
                </PageConfigContextProvider>
            }
        </>
    );
};

const AppPartnerPreload = () => {
    const [isReady, setIsReady] = useState(false);
    const [isRender, setIsRender] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);

    const {partnerId} = useParams();
    const {globalData, updateGlobalData} = useContext(GlobalDataContext);
    const {getPartner, status} = PartnerDataManager();

    useEffect(() => {
        switch (status) {
            case 200:
                setInterceptors(globalData.partner.id);
                updateGlobalData({type: globalDataActionTypes.GetLocalPostal});
                setIsRender(true);
                break;
            case 418:
                console.log('network error');
                break;
            case 404:
                updateGlobalData({type: globalDataActionTypes.ClearPartnerData});
                setInterceptors(myChoicePartnerId);
                updateGlobalData({type: globalDataActionTypes.GetLocalPostal});
                setIsRedirect(true);
                break;
            default:
                break;
        }

    },[status]);


    useEffect(() => {
        if (isReady) {
            if (partnerId !== myChoicePartnerId) {
                getPartner(partnerId);
            }
            else {
                updateGlobalData({type: globalDataActionTypes.ClearPartnerData});
                setInterceptors(myChoicePartnerId);
                updateGlobalData({type: globalDataActionTypes.GetLocalPostal});
                setIsRender(true);
            }
        }
    }, [isReady]);

    useEffect(() => {
        setIsReady(true);
    },[]);

    return (
        <>
            {
                isRedirect &&
                <Redirect to={`/`} />
            }
            {
                isRender &&
                <AppNestedSwitch />
            }
        </>
    );
}

export const AppPartnerRoutes = () => {
    const [isRender, setIsRender] = useState(false);
    useEffect(() => {
        setIsRender(true);
    },[]);

    return(
        <div className="app-wrapper">
            {
                isRender &&
                <Switch>
                    <Route exact path='/accounts/signin'>
                        <Redirect to={`/${myChoicePartnerId}/accounts/signin`}/>
                    </Route>
                    <Route exact path='/accounts/email/verification/:emailKey'>
                        <Redirect to={`/${myChoicePartnerId}/accounts/email/verification/:emailKey`}/>
                    </Route>
                    <Route exact path='/accounts/signup'>
                        <Redirect to={`/${myChoicePartnerId}/accounts/signup`}/>
                    </Route>
                    <Route exact path='/accounts/password/reset/:localUID/:localTOKEN'>
                        <Redirect to={`/${myChoicePartnerId}/accounts/password/reset/:localUID/:localTOKEN`}/>
                    </Route>
                    <Route exact path='/profile/view'>
                        <Redirect to={`/${myChoicePartnerId}/accounts/profile/view`}/>
                    </Route>
                    <Route exact path='/profile/edit'>
                        <Redirect to={`/${myChoicePartnerId}/accounts/profile/edit`}/>
                    </Route>
                    <Route exact path='/profile/password/change'>
                        <Redirect to={`/${myChoicePartnerId}/accounts/profile/password/change`}/>
                    </Route>
                    <Route exact path="/profile/quotes-history">
                        <Redirect to={`/${myChoicePartnerId}/profile/quotes-history`}/>
                    </Route>
                    <Route exact path="/vehicle/form/clear">
                        <Redirect to={`/${myChoicePartnerId}/quote/clear`}/>
                    </Route>
                    <Route exact path="/quote">
                        <Redirect to={`/${myChoicePartnerId}/quote`}/>
                    </Route>
                    <Route exact path="/info">
                        <Redirect to={`/${myChoicePartnerId}/info`}/>
                    </Route>
                    <Route exact path="/driver">
                        <Redirect to={`/${myChoicePartnerId}/driver`}/>
                    </Route>
                    <Route exact path='/postal'>
                        <RedirectWithQuery to={`/${myChoicePartnerId}/postal`} preserveQueryString/>
                    </Route>
                    <Route exact path='/'>
                        <Redirect to={`/${myChoicePartnerId}/`}/>
                    </Route>
                    <Route path='/:partnerId'>
                        <AppPartnerPreload />
                    </Route>
                    <Route path='*' exact component={() => <ErrorPage/>}/>
                </Switch>
            }
        </div>
    );
}
