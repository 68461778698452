import React from "react";

import {requestStatusTypes} from "../../../config/constants";
import ButtonLoader from "./ButtonLoader";

const ButtonSubmit = (props) => {
    return (
        <div className="submit-button-wrapper">
            {
                props.requestState === requestStatusTypes.Loading &&
                <button className={props.classNames}>
                    <ButtonLoader />
                </button>
            }
            {
                props.requestState === requestStatusTypes.Success && !props.formLoop &&
                <div>
                    {
                        props.SuccessMessage &&
                        props.SuccessMessage
                    }
                </div>
            }
            {
                props.requestState !== requestStatusTypes.Loading && props.formLoop &&
                <button className={props.classNames} type='submit' onClick={props.submitHandler}
                        disabled={props.disabled}>{props.title}</button>
            }
            {
                props.requestState !== requestStatusTypes.Loading && props.requestState !== requestStatusTypes.Success && !props.formLoop &&
                <button className={props.classNames} type='submit' onClick={props.submitHandler}
                        disabled={props.disabled}>{props.title}</button>
            }
        </div>
    );
};
export default ButtonSubmit;
