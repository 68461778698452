import React, {useContext} from "react";
import {defaultModalStyle} from "../ModalWrapper/constants";
import {viewModalActionTypes, viewRedirectActionTypes} from "../../../../contexts/ViewContext/viewContextActions";
import Modal from "react-modal";
import ViewContext from "../../../../contexts/ViewContext";
import GlobalDataContext from "../../../../contexts/GlobalDataContext";

const FinaleModal = () => {

    const {globalData} = useContext(GlobalDataContext);
    const {updateViewModal, updateViewRedirect} = useContext(ViewContext);

    return (
        <Modal
            isOpen={true}
            style={defaultModalStyle}
            ariaHideApp={false}
        >
            <div className='viewModalWrapper modal-large'>
                <h3>Thank You!</h3>
                <p>Thanks for using My Choice to find your best car insurance.</p>
                {
                    /*
                    !globalData.isAuth &&
                    <p>If you want to save your data and sign up for our Price Drop Alerts, please create account.</p>
                     */
                }


                {
                    /*
                    !globalData.isAuth &&
                    <>
                        <div className="actionModalButtonsWrapper">
                            <button className="deSelectedBtn"
                                    onClick={() => updateViewModal({type: viewModalActionTypes.ClearModal})}>Cancel
                            </button>
                            <button className="selectedBtn" onClick={() => {

                                updateViewRedirect({
                                    type: viewRedirectActionTypes.Redirect,
                                    payload: `/${globalData.partner.id}/accounts/signup`
                                });
                                updateViewModal({type: viewModalActionTypes.ClearModal});
                            }}>Create Account
                            </button>

                        </div>
                        <p className="alreadyMemberHintText"> Already have an Account?
                            <span onClick={() => {
                                updateViewRedirect({
                                    type: viewRedirectActionTypes.Redirect,
                                    payload: `/${globalData.partner.id}/accounts/signin`
                                });
                                updateViewModal({type: viewModalActionTypes.ClearModal});
                            }}>Log In </span>
                        </p>
                    </>
                     */
                }
                <div className="viewModalButtonsWrapper">
                    <button className="selectedBtn"
                            onClick={() => {
                                updateViewRedirect({type: viewRedirectActionTypes.Redirect,payload:`/${globalData.partner.id}/vehicle/form/clear`});
                                updateViewModal({type: viewModalActionTypes.ClearModal})
                            }}>OK
                    </button>
                </div>
                <p className="alreadyMemberHintText"> Already have an Account?
                    <span onClick={() => {
                        updateViewRedirect({
                            type: viewRedirectActionTypes.Redirect,
                            payload: `/${globalData.partner.id}/accounts/signin`
                        });
                        updateViewModal({type: viewModalActionTypes.ClearModal});
                    }}>Log In </span>
                </p>

            </div>
        </Modal>
    );
}
export default FinaleModal;
