import React, {useEffect, useReducer} from "react";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";
import {formStateActionTypes, formStateReducer} from "../../../helpers/formState";
import moment from "moment";
import {currentDate} from "../../../config/dateConfig";
import {
    compareNumbers,
    monthObjects,
    returnDateNumber,
    returnDayRamge,
    returnYearRange
} from "../../../helpers/dateHelper";
import compareDateRange from "../../../helpers/compareDateRange";

const VehicleDateInput = React.memo((props) => {

    const [localState, updateLocalState] = useReducer(formStateReducer, {});
    const [rangeState, updateRangeState] = useReducer(formStateReducer, {});
    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, renderMonth:false, renderDay:false});


    const refreshDefaultValue = (localDate) => {
        let event = {
            persist: () => {

            },
            target: {
                name: props.name,
                value: localDate,
            }
        }
        props.handleDate(event, props.parentName);
    }

    const updateParent = (localMonth, localDay) => {
        let event = {
            persist: () => {

            },
            target: {
                name: props.name,
            }
        }

        if(props.type!=='month') {
            if (localState.dateDay && localState.dateMonth && localState.dateYear) {
                event.target = {
                    ...event.target,
                        value:`${localState.dateYear}-${returnDateNumber(localMonth ? localMonth : localState.dateMonth)}-${returnDateNumber(localDay ? localDay : localState.dateDay)}`
                }
            }
        }
        else{
            if (localState.dateMonth && localState.dateYear) {
              let  localMinDay=moment(new Date(rangeState.minDate)).local().format('DD') || '';
              const currentDate = `${localState.dateYear}-${returnDateNumber(localMonth ? localMonth : localState.dateMonth)}`
              const localDate = moment(new Date(rangeState.minDate)).local().format('YYYY-MM')
              if(currentDate!==localDate){
                localMinDay='01';
              }
              debugger;
             moment(new Date(rangeState.minDate)).local().format('DD');

              event.target = {
                    ...event.target,
                    value:`${localState.dateYear}-${returnDateNumber(localMonth ? localMonth : localState.dateMonth)}-${localMinDay}`
                }
            }
        }

        if(event.target.value){
            const validateDate = compareDateRange(event.target.value,props.min, props.max);
            if(validateDate.outOfMax){
                event.target.value = props.max;
            }
            else{
                if(validateDate.outOfMin){
                    event.target.value = props.min;
                }
            }
            props.handleDate(event, props.parentName);
            updateLocalState({
                type: formStateActionTypes.Update,
                payload: {...localState, dateMonth: localMonth, dateDay: localDay}
            });
            updateRenderState({
                type: renderStateActionTypes.UpdateAll,
                payload: {renderDay: false, renderMonth: false}
            });
        }
    }



    useEffect(()=>{

        let localMinMonth = 1;
        let localMaxMonth = 12;
        let localMinDay = 1;
        let localMaxDay = 31;

        let localDay = localState.dateDay;

        let localMonth = localState.dateMonth;

        if(props.type!=='month') {
            if (localState.dateMonth) {
                if(localState.dateMonth===2){
                    if (Number(localState.dateYear) % 4) {
                        localMaxDay = 28;
                    } else {
                        localMaxDay = 29;
                    }
                }
                else{
                    localMaxDay = monthObjects[Number(localState.dateMonth-1)].maxDays;
                }
            }
        }
        if(rangeState.maxYear===Number(localState.dateYear) || rangeState.maxYear===rangeState.minYear){
            localMaxMonth =  Number(moment(rangeState.maxDate).local().format('MM'));
        }
        if(rangeState.minYear===Number(localState.dateYear) || rangeState.maxYear===rangeState.minYear){
            localMinMonth = Number(moment(rangeState.minDate).local().format('MM'));

        }
        localMonth = compareNumbers(localMonth, localMinMonth, localMaxMonth);

        if(rangeState.maxYear===Number(localState.dateYear) || rangeState.maxYear===rangeState.minYear) {
            if (localMaxMonth === localMonth) {
                localMaxDay = Number(moment(new Date(rangeState.maxDate)).local().format('DD'));
            }
        }
        if(rangeState.minYear===Number(localState.dateYear) || rangeState.maxYear===rangeState.minYear){
            if(localMinMonth===localMonth) {
                localMinDay = Number(moment(new Date(rangeState.minDate)).local().format('DD'));
            }
        }

        updateRangeState({type:formStateActionTypes.Update,payload:{
                ...rangeState,
                minMonth: localMinMonth,
                maxMonth: localMaxMonth,
                minDay: localMinDay,
                maxDay: localMaxDay
            }});

        if(!renderState.isFirst && renderState.isRender && renderState.renderDay && renderState.renderMonth) {

            if (props.type !== 'month') {
                if(localDay>localMaxDay){
                    localDay = localMaxDay;
                }
                if(localDay<localMinDay){
                    localDay = localMinDay;
                }
                updateParent(localMonth,localDay);
            } else {
                updateParent(localMonth);
            }
        }

    },[localState]);

    useEffect(()=>{
        if(renderState.isRender){
            if(rangeState.yearRange.length>0){

            }
        }
    },[rangeState]);


    useEffect(() => {
        if (props.defaultValue !== localState.defaultValue || props.min !== localState.min || props.max !== localState.max) {
            updateRenderState({type: renderStateActionTypes.Clear, payload:{renderYear:false,renderMonth:false, renderDay:false}});
        }
    }, [props]);

    useEffect(() => {
        if (!renderState.isFirst && !renderState.isRender) {
            updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
        }
        else {
            if (renderState.isRender){
                if (!renderState.renderDay || !renderState.renderMonth) {
                    updateRenderState({type: renderStateActionTypes.UpdateAll, payload: {renderYear: true, renderDay: true, renderMonth: true}});
                }
            }
        }
    }, [renderState]);

    useEffect(() => {
        if (renderState.isFirst) {

            const minDate = props.min ? props.min.length === 7 ? `${props.min}-01` : props.min : moment(currentDate).local().add(-100,'year').format('YYYY-MM-DD');

            const maxDate = props.max ? props.max.length === 7 ? `${props.max}-01` : props.max : moment(currentDate).local().add(100,'year').format('YYYY-MM-DD');

            let defaultValue = props.defaultValue;

            const compare = compareDateRange(defaultValue, props.min, props.max);

            if(compare.outOfMin && props.defaultValue){
                defaultValue = props.min;
                refreshDefaultValue(defaultValue);
            }
            else{
                if(compare.outOfMax && props.defaultValue){
                    defaultValue = props.max;
                    refreshDefaultValue(defaultValue);
                }
            }

            updateLocalState({
                type: formStateActionTypes.Update,
                payload: {
                    ...localState,
                    defaultValue: defaultValue,
                    errorMessage: localState.errorMessage || '',
                    dateDay: defaultValue ? Number(moment(defaultValue).local().format('DD')) : '',
                    dateMonth: defaultValue ? Number(moment(defaultValue).local().format('MM')) : '',
                    dateYear: defaultValue ? Number(moment(defaultValue).local().format('YYYY')) : ''
                }
            });

            updateRangeState({
                type: formStateActionTypes.Update,
                payload:{
                    ...rangeState,
                    minDay: 1,
                    maxDay: 31,
                    dayRange: returnDayRamge(),

                    minMonth: 1,
                    maxMonth: 12,

                    yearRange: returnYearRange(minDate, maxDate),
                    minYear: Number(moment(minDate).local().format('YYYY')),
                    maxYear: Number(moment(maxDate).local().format('YYYY')),
                    minDate: moment(minDate).local().format('YYYY-MM-DD'),
                    maxDate: moment(maxDate).local().format('YYYY-MM-DD')
                }
            })
            updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
        }
    });

    function selectHandler(event) {
        event.persist();
        updateLocalState({type:formStateActionTypes.Update,payload:{...localState, [event.target.name]:Number(event.target.value)}});
    }

    return (

        <>
            {
                renderState.isRender &&
                <>

                    <div className="inputDateSelects">
                        {
                            props.type!=='month' && rangeState.dayRange.length && renderState.renderDay &&
                            <select className='daySelect' onChange={selectHandler} name='dateDay' disabled={props.disabled} defaultValue={localState.dateDay}>
                                <option disabled={true} value=''>Day</option>
                                {
                                    rangeState.dayRange.filter(fItem=>fItem.value>=rangeState.minDay && fItem.value<=rangeState.maxDay).map((item, index) => {return (
                                        <option key={`day${index}`} value={item.value}>{item.name}</option>
                                    )})
                                }
                            </select>
                        }
                        {
                            renderState.renderMonth &&
                            <select className='monthSelect' onChange={selectHandler} name='dateMonth'
                                    disabled={props.disabled} defaultValue={localState.dateMonth || ''}>
                                <option disabled={true} value=''>Month</option>
                                {
                                    rangeState.yearRange.length > 0 &&
                                    monthObjects.filter(fItem => fItem.value >= rangeState.minMonth && fItem.value <= rangeState.maxMonth).map((item, index) => {
                                        return (
                                            <option key={`month${index}`} value={item.value}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        }
                        <select className='yearSelect' onChange={selectHandler} name='dateYear' disabled={props.disabled} defaultValue={localState.dateYear || ''}>
                            <option disabled={true} value=''>Year</option>
                            {
                                rangeState.yearRange.length > 0 &&
                                rangeState.yearRange.map((item, index)=>{return(
                                    <option key={`year${index}`}  value={item.value}>{item.name}</option>
                                )})
                            }
                        </select>

                    </div>

                    {
                        localState.errorMessage &&
                        <p className='warningMessage'>{localState.errorMessage}</p>
                    }
                </>
            }
        </>
    )

}, (prevProps, nextProps) => {
        if (prevProps.min !== nextProps.min || prevProps.max !== nextProps.max || prevProps.disabled !== nextProps.disabled || prevProps.defaultValue !== nextProps.defaultValue) {
            return false
        } else {
            return true
        }
    }
);


export default VehicleDateInput;
