import React, {useCallback, useContext} from "react";
import VehicleDateInput from "../../../../../../../ui/vehicleFormInputs/VehicleDateInput";
import {returnMonthToRend} from "../../../../../../../../helpers/dateHelper";
import DriverDataContext from "../../../../../../../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {FormConfigStateContext} from "../../../../../../../../contexts/formContexts/FormConfigState";

const GInputBlock = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);
    const {formConfig} = useContext(FormConfigStateContext);

    const handleDateInput = useCallback((evt)=>{
        updateDriverData({
            type: driverDataActionTypes.UpdateLicenceGDate,
            index: driverTab.activeItem,
            payload: evt.target.value,
            config: formConfig.minDates,
        });
    },[formConfig]);

    return(
        <>
            <div className="formLabelAndTooltipWrapper nextToCalendarInput">
                <h4>{formConfig.licenceConfig.g.title} licence date</h4>
            </div>

            <VehicleDateInput
                className='inputDate'
                type='month'
                name='g_licence_date'
                defaultValue={driverData.items[driverTab.activeItem].licence_info.g_licence_date}
                min={driverData.items[driverTab.activeItem].minDates.gMinDate}
                max={driverData.items[driverTab.activeItem].minDates.gMaxDate}
                parentName='licence_info'
                handleDate={handleDateInput}
                disabled={false}
            />
            {
                driverData.items[driverTab.activeItem].minDates.gMinDate &&
                <p className="recommendedRangeInfo licensedHintForDate">
                    {
                        driverData.items[driverTab.activeItem].minDates.gMinDate ? returnMonthToRend(driverData.items[driverTab.activeItem].minDates.gMinDate) : ''

                    } would be your earliest {formConfig.licenceConfig.g.title} licence date based on your first licenced age and date of birth
                </p>
            }
        </>
    )
}

export default GInputBlock;
