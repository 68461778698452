import {UseFormInputTypes} from "../../../helpers/UseForm/constatns";
import {InputTypes} from "../../ui/Inputs/constants";

export const Initial_PasswordChangeFormData = [
    {
        title: 'Current Password *',
        name: 'old_password',
        toolTip: '',
        defaultErrorMessage: 'Please enter your password',
        placeholder: 'Current Password',
        formName: 'old_password',
        type: 'password',
        autoComplete: 'off',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.PASSWORD,
        value: '',
        errorMessage: '',
        isValid: false
    },
    {
        title: 'New password *',
        name: 'new_password1',
        toolTip: '',
        defaultErrorMessage: 'Please enter your password',
        placeholder: 'New Password',
        formName: 'new_password1',
        type: 'password',
        autoComplete: 'off',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.PASSWORD,
        value: '',
        errorMessage: '',
        isValid: false
    },
    {
        title: 'Confirm new password *',
        name: 'new_password2',
        toolTip: '',
        defaultErrorMessage: 'Please enter your password',
        placeholder: 'Confirm New Password',
        formName: 'new_password2',
        type: 'password',
        autoComplete: 'off',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.PASSWORD_CONFIRM,
        value: '',
        errorMessage: '',
        isValid: false
    }
];
