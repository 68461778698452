import React, {useContext} from "react";
import DiscountDataContext from "../../../../../contexts/formContexts/DiscountDataContext";
import PhoneInput from "../../../../ui/Inputs/PhoneInput";
import InputEmailWithValidation from "../../../../ui/Inputs/InputEmailWithValidation";
import {discountDataActionTypes} from "../../../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";

const UserBlock = () => {

    const { discountData, updateDiscountData } = useContext(DiscountDataContext);

    const handleInputMultiObjects = (event, objectsArray) => {
        updateDiscountData({
            type: discountDataActionTypes.UpdateMultiObjects,
            fieldName: event.target.name,
            payload: event.target.value,
            objects: objectsArray,
        })
    };

    const handleEmailInput = (event) => {
        updateDiscountData({type: discountDataActionTypes.UpdateEmail,payload: event.target.value});
    };

    return(
        <>
            <div className="centerTitle">
                <h2>Complete the form below to see which companies are offering your quotes.</h2>
            </div>
            <div className="formLabelAndTooltipWrapper">
                <h4>First Name</h4>
            </div>

            <input
                className="formInput"
                placeholder="Your First Name"
                name='first_name'
                defaultValue={discountData.quoter_info.first_name}
                onChange={(event) => {
                    handleInputMultiObjects(event, ['quoter_info', 'newUser']);
                }}
            />

            <div className="formLabelAndTooltipWrapper">
                <h4>Last Name</h4>
            </div>
            <input
                className="formInput"
                placeholder="Your Last Name"
                name='last_name'
                defaultValue={discountData.quoter_info.last_name}
                onChange={(event) => {
                    handleInputMultiObjects(event, ['quoter_info', 'newUser']);
                }}
            />

            <div className="formLabelAndTooltipWrapper">
                <h4>Phone Number</h4>
            </div>

            <PhoneInput
                name='phone'
                defaultValue={discountData.quoter_info.phone}
                onChange={(event) => {
                    handleInputMultiObjects(event, ['quoter_info']);
                }}
            />

            <InputEmailWithValidation
                label={{
                    title: "Please provide your email address so we \n can send you a copy of your quotes",
                    id: "main-email-address",
                    description: "We will provide you with your insurance quotes immediately \n after this form completion. If you are not ready to apply today, don’t worry as we\n will be emailing your quotes\n to you if you’d like to complete at a later date with the help of one of our trusted \n broker partners.",
                }}
                className="formInput"
                name="email_to"
                defaultValue={discountData.email_to}
                onChange={handleEmailInput}
            />
        </>
    );
}

export default UserBlock;
