import React, {useCallback, useContext} from "react";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";

const DriverOccupation = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);

    const handleSwitch = useCallback((nmb)=>{
        updateDriverData({type: driverDataActionTypes.UpdateOccupation, index: driverTab.activeItem, payload: nmb})
    },[driverTab.activeItem, updateDriverData]);

    return(
        <>
            <VehicleInputTitleWithToolTip id="mainOccupation" extraClassName="nextToCalendarInput" title="Are you currently employed or unemployed?" description="Your employment status reflects your driving frequency, and insurers consider this in your policy." />

            <div className="formButtonsWrapper">
                <button
                    onClick={() => handleSwitch( 998)}
                    className={driverData.items[driverTab.activeItem].occupation === 998 ? "selectedBtn" : "deSelectedBtn"}>Employed
                </button>
                <button
                    onClick={() => handleSwitch( 800)}
                    className={driverData.items[driverTab.activeItem].occupation === 800 ? "selectedBtn" : "deSelectedBtn"}>Unemployed
                </button>
            </div>
        </>
    )
}

export default DriverOccupation;
