import React, {useContext, useEffect} from "react";
import Modal from "react-modal";

import {postalModalStyle} from "../ModalWrapper/constants";
import GlobalDataContext from "../../../../contexts/GlobalDataContext";
import {viewModalActionTypes} from "../../../../contexts/ViewContext/viewContextActions";
import {PostalDataManager} from "../../../../managers/DataManagers";
import {requestStatusTypes} from "../../../../config/constants";
import ViewContext from "../../../../contexts/ViewContext";
import ButtonLoader from "../../../ui/buttons/ButtonLoader";
import PostalInput from "../../../ui/vehicleFormInputs/PostalInput";


const PostalModal = () => {

    const {globalData} = useContext(GlobalDataContext);
    const {updateViewModal} = useContext(ViewContext);

    const {getPostal, postalRequestState} = PostalDataManager();

    const handleSubmit = (event) => {
        event.preventDefault();
        if(postalRequestState!==requestStatusTypes.Loading) {
            getPostal(event.target.postal.value, true);
        }
    };
    useEffect(()=>{
        if(globalData.isPostal){
            updateViewModal({type:viewModalActionTypes.ClearModal});
        }
    },[globalData]);

    return (
        <Modal
            isOpen={true}
            style={postalModalStyle}
            ariaHideApp={false}
        >
            <div className='viewModalWrapper modal-large'>
                <h3>Please Enter Your Postal Code</h3>
                <form onSubmit={(event)=>handleSubmit(event)}>
                    <PostalInput />
                    {
                        globalData.postalError!=='' &&
                        <p className="warningMessage">{globalData.postalError}</p>
                    }

                    <button type='submit' className="selectedBtn" >
                        {
                            postalRequestState !== requestStatusTypes.Loading && 'OK'
                        }
                        {
                            postalRequestState === requestStatusTypes.Loading &&
                                <ButtonLoader />
                        }
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default PostalModal;
