export const activeItemReducerActionTypes = {
    UpdateActive: 'UpdateActive'
}

export const activeItemStateReducer = (state, action) => {
    switch (action.type) {

        case activeItemReducerActionTypes.UpdateActive:
            return {activeItem:action.payload};

        default:
            return state;
    }
}
