import React, {useContext, useEffect, useReducer} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import {PostalDataManager} from "../managers/DataManagers";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../helpers/hooksLocalRenderState";
import {requestStatusTypes} from "../config/constants";
import DiscountDataContext from "../contexts/formContexts/DiscountDataContext";
import {discountDataActionTypes} from "../contexts/formContexts/DiscountDataContext/discountDataActionTypes";
import GlobalDataContext from "../contexts/GlobalDataContext";
import VehicleDataContext from "../contexts/formContexts/VehicleDataContext";
import DriverDataContext from "../contexts/formContexts/DriverDataContext";
import QuoteDataContext from "../contexts/formContexts/QuoteDataContext";
import {pageConfigActionTypes, PageConfigContext} from "../contexts/formContexts/PageConfigContext";
import {globalDataActionTypes} from "../contexts/GlobalDataContext/globalDataActions";
import {vehicleDataActionTypes} from "../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import {driverDataActionTypes} from "../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {quoteDataActionTypes} from "../contexts/formContexts/QuoteDataContext/quoteDataActions";

const PostalCode = () => {

    const {getPostal, postalRequestState} = PostalDataManager();

    const {globalData} = useContext(GlobalDataContext);

  const {updateVehicleData, updateVehicleTab} = useContext(VehicleDataContext);

  const {updateGlobalData} = useContext(GlobalDataContext);
  const {updateDriverData, updateDriverTab} = useContext(DriverDataContext);
  const {updateDiscountData} = useContext(DiscountDataContext);
  const {updateQuoteData} = useContext(QuoteDataContext);
  const {dispatchPageConfig} = useContext(PageConfigContext);

  const clearAllForms = () => {
    updateGlobalData({type: globalDataActionTypes.ClearUser});
    updateGlobalData({type: globalDataActionTypes.ClearPartnerData});
    updateVehicleData({type: vehicleDataActionTypes.Clear});
    updateVehicleTab({type: vehicleDataActionTypes.Clear});
    updateDriverTab({type: driverDataActionTypes.Clear});
    updateDriverData({type: driverDataActionTypes.Clear});
    updateDiscountData({type: discountDataActionTypes.Clear});
    updateQuoteData({type: quoteDataActionTypes.ClearQuote});
    dispatchPageConfig({type: pageConfigActionTypes.ClearViewPage});
  }

    let history = useHistory();

    const queryRoot = useLocation().search.split('?');

    const [renderState, updateRenderState] = useReducer(renderStateReducer, renderInitialState);

    useEffect(()=>{
        if(!renderState.isFirst){
            if(postalRequestState!==requestStatusTypes.Loading && postalRequestState!==requestStatusTypes.Waiting){
                history.push(`/${globalData.partner.id}/`);
            }
        }
    },[renderState, postalRequestState]);

    useEffect(()=>{
        if(renderState.isFirst) {
            if(queryRoot.length>1) {
                const query = JSON.parse('{"' + decodeURI(queryRoot[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
                if(query.postal_code){
                  getPostal(query.postal_code, false, clearAllForms);
                }
                else{
                  history.push(`/${globalData.partner.id}/`);
                }
                if (query.province_name) {
                    updateDiscountData({type: discountDataActionTypes.UpdateProvinceName, payload: query.province_name});
                }

                if (query.province_code) {
                    updateDiscountData({type: discountDataActionTypes.UpdateProvinceCode, payload: query.province_code});
                }

                if (query.utm_source) {
                    updateDiscountData({type: discountDataActionTypes.UpdateQuoterUTMSource, payload: query.utm_source});
                }

                if (query.utm_campaign) {
                    updateDiscountData({type: discountDataActionTypes.UpdateQuoterUTMCampaign, payload: query.utm_campaign});
                }
            }
            else {
                history.push(`/${globalData.partner.id}/`);
            }
            updateRenderState({type:renderStateActionTypes.UpdateFirst,payload:false})
        }
    });

    return (
        <>
        </>
    );
};

export default PostalCode;
