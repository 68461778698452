import {useContext, useEffect, useReducer, useState} from "react";
import axios from "axios";
import httpRequest from "../../services/httpRequest";

import {QUOTE_FORM_API, requestStatusTypes} from "../../config/constants";

//contexts
import GlobalDataContext from "../../contexts/GlobalDataContext";
import ViewContext from "../../contexts/ViewContext";

//locals
import dataManagerReducer from "./dataManagerReducer";
import {DataManagerActionTypes, dataManagerInitialState} from "./constants";
import {globalDataActionTypes} from "../../contexts/GlobalDataContext/globalDataActions";


export const PartnerDataManager = () => {

    const {updateGlobalData} = useContext(GlobalDataContext);
    const {updateViewLoader} = useContext(ViewContext);
    const [status, setStatus] = useState(0);

    const [localState, updateLocalState] = useReducer(dataManagerReducer, {
        ...dataManagerInitialState,
        responseData: [],
        cancelTokenSource: axios.CancelToken.source()
    });

    const getPartner = async (partnerName) => {
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});

        await httpRequest(`${QUOTE_FORM_API.GET_PARTNER}${partnerName}/`, {
            method: 'get'
        }).then((response) => {
            setStatus(response.status);
            if (response.status === 200 ){
                updateGlobalData({
                    type: globalDataActionTypes.UpdatePartnerData,
                    payload: {...response.data}
                });
            }
        }).catch((error) => {
            setStatus(error.status);
            updateGlobalData({
                type: globalDataActionTypes.ClearPartnerData,
            });
            updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
        });
    }

    useEffect(() => {
        return () => {
            localState.cancelTokenSource.cancel();
        }
    }, [localState.cancelTokenSource, updateViewLoader]);

    return{
        status: status,
        requestState: localState.requestState,
        responseData: localState.responseData,
        successMessage: localState.SuccessMessage,
        error: localState.errorMessage,
        getPartner: getPartner,
    }
};
