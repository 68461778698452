import {useContext, useEffect, useReducer} from 'react';

import VehicleDataContext from "../../contexts/formContexts/VehicleDataContext";
import DriverDataContext from "../../contexts/formContexts/DriverDataContext";
import DiscountDataContext from "../../contexts/formContexts/DiscountDataContext";
import {vehicleDataActionTypes} from "../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import ViewContext from "../../contexts/ViewContext";
import {viewRedirectActionTypes} from "../../contexts/ViewContext/viewContextActions";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../helpers/hooksLocalRenderState";
import {driverDataActionTypes} from "../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import QuoteDataContext from "../../contexts/formContexts/QuoteDataContext";
import {discountDataActionTypes} from "../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";
import {quoteDataActionTypes} from "../../contexts/formContexts/QuoteDataContext/quoteDataActions";
import GlobalDataContext from "../../contexts/GlobalDataContext";
import {pageConfigActionTypes, PageConfigContext} from "../../contexts/formContexts/PageConfigContext";


const VehicleClearForm = () => {

    const [renderState, updateRenderState] = useReducer(renderStateReducer, renderInitialState);

    const {updateVehicleData, updateVehicleTab} = useContext(VehicleDataContext);

    const {globalData} = useContext(GlobalDataContext);
    const {updateDriverData, updateDriverTab} = useContext(DriverDataContext);
    const {updateDiscountData} = useContext(DiscountDataContext);
    const {updateQuoteData} = useContext(QuoteDataContext);
    const {updateViewRedirect} = useContext(ViewContext);
    const {dispatchPageConfig} = useContext(PageConfigContext);

    function clearAllForms(){
        updateVehicleData({type: vehicleDataActionTypes.Clear});
        updateVehicleTab({type: vehicleDataActionTypes.Clear});
        updateDriverTab({type: driverDataActionTypes.Clear});
        updateDriverData({type: driverDataActionTypes.Clear});
        updateDiscountData({type: discountDataActionTypes.Clear});
        updateQuoteData({type: quoteDataActionTypes.ClearQuote});
        dispatchPageConfig({type: pageConfigActionTypes.ClearViewPage});
    }

    useEffect(()=>{
        if(!renderState.isFirst){
            updateViewRedirect({type:viewRedirectActionTypes.Redirect,payload:`/${globalData.partner.id}/`});
        }
    },[renderState]);


    useEffect(()=>{
            if(renderState.isFirst){
                clearAllForms();
                updateRenderState({type:renderStateActionTypes.UpdateFirst,payload:false});
            }
         })

    return null;
}

export default VehicleClearForm;
