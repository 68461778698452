import React from "react";

const Header = (props) => {
    const { title, description } = props;
    return(
        <>
            <div className="discountSecondStepTopRow">
                <h5>{title}</h5>
            </div>
            <h4 className="stepIntroTitle">{description}</h4>
        </>
    );
}

export default Header;
