import {driverDataActionTypes} from "./driverDataActionTypes";
import cloneDeep from 'lodash.clonedeep';
import {calculateMinDates, clearGLicence, resetGDefaultValues} from '../../../helpers/calculateGLicence';

export const LicenseTypes = {
    g: 'G',
    gOne: 'G1',
    gTwo: 'G2'
}

export const driverLicenceInitialState = {
    licence_type: "",
    first_licence_age: 16,
    previous_licence: false,
    g_licence_date: '',
    g1_licence_date: '',
    g2_licence_date: '',
    passed_driver_training: false,
    isOnlyG: false
}

export const getDriverLicenceInitialState = (licenceAge = 16) => {
    return {
        licence_type: "",
        first_licence_age: licenceAge,
        previous_licence: false,
        g_licence_date: '',
        g1_licence_date: '',
        g2_licence_date: '',
        passed_driver_training: false,
        isOnlyG: false
    }
}

export const driverVehlinkInitialState = {
    vehicle_index: "",
    model: "",
    priority: "Prn"
}

export const minDateInitialState = {
    gOneMinDate: '',
    gOneMaxDate: '',
    gOneBlocked: true,
    gOneDefaultValue: '',
    gTwoMinDate: '',
    gTwoMaxDate: '',
    gTwoBlocked: true,
    gTwoDefaultValue: '',
    gMinDate: '',
    gMaxDate: '',
    gBlocked: true,
    gDefaultValue: '',
    maxLicenceAge: 16
}

export const driverDataItemInitialState = {
    first_name: "",
    date_of_birth: "",
    marital_status: "S",
    gender: "M",
    occupation: 998,
    licence_info: {...driverLicenceInitialState},
    applicant_relationship: "",
    listed: true,
    listed_year: "",
    insured: true,
    insured_year: "",
    insurance_cancellation: false,
    insurance_cancellation_list: [{reason: '', end_date: '', start_date: ''}],
    licence_suspension: false,
    licence_suspension_list: [{reason: '', reinstatement_date: '', suspension_date: ''}],
    accident: false,
    accident_list: [{accident_date: ''}],
    ticket: false,
    ticket_list: [{reason: '', ticket_date: ''}],
    vehlink: {...driverVehlinkInitialState},
    minDates: {...minDateInitialState}
};

export const getDriverDataInitialState = (licenceAge = 16) => {
    return {
        first_name: "",
        date_of_birth: "",
        marital_status: "S",
        gender: "M",
        occupation: 998,
        licence_info: {...getDriverLicenceInitialState(licenceAge)},
        applicant_relationship: "",
        listed: true,
        listed_year: "",
        insured: true,
        insured_year: "",
        insurance_cancellation: false,
        insurance_cancellation_list: [{reason: '', end_date: '', start_date: ''}],
        licence_suspension: false,
        licence_suspension_list: [{reason: '', reinstatement_date: '', suspension_date: ''}],
        accident: false,
        accident_list: [{accident_date: ''}],
        ticket: false,
        ticket_list: [{reason: '', ticket_date: ''}],
        vehlink: {...driverVehlinkInitialState},
        minDates: {...minDateInitialState}
    }
}

function updateLocalData(state) {
    localStorage.setItem(`${state.partnerID}_driverData`, JSON.stringify(state));
}

function addNewDriver(state, licenceAge) {
    let newDriver = cloneDeep(getDriverDataInitialState(licenceAge));
    state.items.push(newDriver);
    updateLocalData(state);
    return state;
}

function removeDriver(state, removeIndex) {
    const newItems = state.items.filter((item, index) => index !== removeIndex);
    updateLocalData({items: [...newItems], partnerID: state.partnerID});
    return {items: [...newItems], partnerID: state.partnerID};
}

function clearDriverData(state) {
    const newDrivers = {items: [cloneDeep(driverDataItemInitialState)], partnerID: state.partnerID};
    updateLocalData(newDrivers);
    return newDrivers;
}

export const driverDataReducer = (state, action) => {

    let newState = {...state};

    switch (action.type) {

        case driverDataActionTypes.Clear:
            return clearDriverData(state);

        case driverDataActionTypes.AddItem:
            return addNewDriver(state, action.payload);

        case driverDataActionTypes.RemoveItem:
            return removeDriver(state, action.payload);

        case driverDataActionTypes.UpdateFirstName:
            state.items[action.index].first_name = action.payload;
            updateLocalData(state);
            return state;

        case driverDataActionTypes.UpdateMarital:
            newState.items[action.index].marital_status = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateDateOfBirth:
            newState.items[action.index].date_of_birth = action.payload;
            newState.items[action.index] = {...clearGLicence(newState.items[action.index])};
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config, true)};
            newState.items[action.index] = {...resetGDefaultValues(newState.items[action.index], true)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateOccupation:
            newState.items[action.index].occupation = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateGender:
            newState.items[action.index].gender = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateRelation:
            newState.items[action.index].applicant_relationship = action.payload;
            updateLocalData({...newState});
            return {...newState};

        // license info
        case driverDataActionTypes.UpdateLicenceAge:
            newState.items[action.index].licence_info.first_licence_age = action.payload;
            newState.items[action.index] = {...clearGLicence(newState.items[action.index])};
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config, true)};
            newState.items[action.index] = {...resetGDefaultValues(newState.items[action.index], true)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceType:
            newState.items[action.index].licence_info.licence_type = action.payload;
            newState.items[action.index] = {...clearGLicence(newState.items[action.index])};
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config)};
            newState.items[action.index] = {...resetGDefaultValues(newState.items[action.index])};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceGDate:
            newState.items[action.index].licence_info.g_licence_date = action.payload;
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceGTwoDate:
            newState.items[action.index].licence_info.g2_licence_date = action.payload;
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceGOneDate:
            newState.items[action.index].licence_info.g1_licence_date = action.payload;
            newState.items[action.index] = {...calculateMinDates(newState.items[action.index], action.config)};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdatePassedDriverTraining:
            newState.items[action.index].licence_info.passed_driver_training = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicenceAllInfo:
            newState.items[action.index].licence_info = {...newState.items[action.index].licence_info, ...action.payload};
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateLicencePrevious:
            newState.items[action.index].licence_info.previous_licence = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateListed:
            newState.items[action.index].listed = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateListedYear:
            newState.items[action.index].listed_year = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateInsured:
            newState.items[action.index].insured = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateInsuredYear:
            newState.items[action.index].insured_year = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateListNavigation:
            newState.items[action.index][action.name] = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case driverDataActionTypes.UpdateListContent:
            newState.items[action.index][action.name] = action.payload;
            updateLocalData({...newState});
            return {...newState};

        default:
            return state;
    }
}
