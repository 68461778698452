import React from "react";
import ReactTooltip from "react-tooltip";

const InputFIeldReadOnly = (props) => {
    const {item} = props;


    return (
        <>

            <div className='formLabelAndTooltipWrapper'>
                <h4>{item.title}</h4>
                {
                    item.toolTip &&
                    <>
                        <ReactTooltip id={item.formName} className="tooltipWrapper"
                                      arrowColor="transparent">
                            <p className="tooltipText">{item.toolTip}</p>
                        </ReactTooltip>
                    </>
                }
            </div>
            <div className='inputWrapper'>
                <input className='formInputReadOnly'
                       type={props.item.type}
                       defaultValue={props.item.value}
                       readOnly={true}
                />
            </div>
        </>
    );
};
export default InputFIeldReadOnly;
