export const defaultModalStyle = {
    content : {
        top                     : '50%',
        left                    : '50%',
        right                   : 'auto',
        bottom                  : 'auto',
        marginRight             : '-50%',
        transform               : 'translate(-50%, -50%)',
        border                  : 'none',
        boxShadow               : '0px 3px 6px rgba(0, 0, 0, 0.16)',
        whiteSpace              : 'pre-wrap'
    }
};

export const postalModalStyle ={
    overlay: {
        zIndex: '35'
    },
    content : {
        top                     : '50%',
        left                    : '50%',
        right                   : 'auto',
        bottom                  : 'auto',
        marginRight             : '-50%',
        transform               : 'translate(-50%, -50%)',
        border                  : 'none',
        boxShadow               : '0px 3px 6px rgba(0, 0, 0, 0.16)',
        whiteSpace              : 'pre-wrap'
    }
}

export const modalTypes = {
    Message: 'Message',
    MessageRedirect: 'MessageRedirect',
    Action: 'Action',
    Postal: 'Postal',
    Finale: 'Finale',
    QuoteRequest: 'QuoteRequest',
}
