import React, {useContext} from "react";
import {AppPartnerRoutes} from "./AppPartnerRoutes";
import AppResize from "../AppWrapper/AppResize";
import ContentLoader from "../components/layout/ConetntLodaer/ContentLoader";
import ViewContext from "../contexts/ViewContext";

export const AppWrapper = () => {

    const {viewLoader} = useContext(ViewContext);

    return(
        <>
            <AppResize />
            {
                viewLoader.isOpen &&
                <ContentLoader />
            }
            <AppPartnerRoutes />
        </>
    );
}
