import React from "react";
import MyChoiceLogo from "../../../resources/images/logotype-mychooice-primary.png";
import MyChoiceLogo2x from "../../../resources/images/logotype-mychoice-primary-2x.png";

const ButtonLogo = () => {
    return(
        <div>
            <a href="https://www.mychoice.ca/" rel="noopener noreferrer" className="logoLink">
                <img src={MyChoiceLogo} srcSet={MyChoiceLogo2x + ' 2x'}
                     alt="logo"/>
            </a>
        </div>
    )
};

export default ButtonLogo;
