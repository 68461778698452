import React, {useCallback, useContext} from "react";
import VehicleInputTitle from "../../../../../ui/vehicleFormInputs/VehicleInputTitle";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {formRelationshipTypes} from "../../../../../../config/formConstants";

const DriverRelationship = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);

    const handleSelect = useCallback((evt) => {
        updateDriverData({type: driverDataActionTypes.UpdateRelation, index: driverTab.activeItem, payload: evt.target.value});
    },[]);

    return(
        <>
            <VehicleInputTitle title="Relationship to applicant" />
            <select
                name="applicant_relationship"
                defaultValue={driverData.items[driverTab.activeItem].applicant_relationship}
                onChange={(event) => handleSelect(event)}
                className="formSelect"
            >
                <option value="">Select...</option>
                {
                    formRelationshipTypes.map((item, index)=>{
                       return(
                           <option key={`relation${index}`} value={item.value}>
                               {item.name}
                           </option>
                       )
                    })
                }
            </select>
        </>
    )
};

export default DriverRelationship;
