import React from "react";

const ErrorPage = () => {
    return (
        <div className='addVehiclesFormContentWrapper'>
                <h1>404</h1>
        </div>
    );
};

export default ErrorPage;
