import React from "react";

export const NavigationLeftLongArrow = (props) => {
    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 21.4 15"
        >
            <path
                fill={props.primaryColor ? props.primaryColor : "#FFFFFF"}
                d="M7,14.8c0.3,0.3,0.8,0.3,1.1,0s0.3-0.8,0-1.1l0,0L2.6,8.3h17.9c0.4,0,0.8-0.3,0.8-0.8 c0,0,0,
                0,0,0l0,0c0-0.4-0.3-0.8-0.8-0.8c0,0,0,0,0,0H2.6L8,1.3C8.3,1,8.3,0.5,8,0.2C7.8-0.1,7.3-0.1,7.1,
                0.2c0,0,0,0-0.1,0.1l0,0 L0.2,7c-0.3,0.3-0.3,0.8,0,1.1L7,14.8z"
            />
        </svg>
    );
}
