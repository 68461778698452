import React from "react";

export const ValidationDecline = (props) => {
    const {width, height, classNames} = props;
    const iconWidth = width || 50;
    const iconHeight = height || 50;
    const iconClassName = `icon-svg ${classNames || 'icon-primary'}`;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="svg-icon-approve"
            version="1.1"
            x="0px"
            y="0px"
            viewBox={`0 0 ${iconWidth} ${iconHeight}`}
            style={{enableBackground: `new 0 0 ${iconWidth} ${iconHeight}`}}
            className={iconClassName}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#F50011"
                d="M47.2,7c1-1.3,0.8-3.2-0.5-4.2C45.6,2,44.1,2,43,2.8L25,20.8L7,2.8c-1.3-1-3.2-0.7-4.2,0.6
		C2.1,4.5,2.1,6,2.8,7l17.9,18L2.8,43c-1.1,1.2-1.1,3.1,0.1,4.2c1.2,1.1,3,1.1,4.1,0l17.9-18l18,18c1.2,1.1,3.1,1.1,4.2-0.1
		c1.1-1.2,1.1-3,0-4.1l-18-18L47.2,7z"
            />
        </svg>
    )
};
