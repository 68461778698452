import React, {useState} from 'react';
import {Link} from "react-router-dom";

import {AuthDataManager} from "../../../managers/DataManagers";

import UseForm from "../../../helpers/UseForm";
import {Initial_PasswordForgetFormData} from './constants';
import InputWrapper from "../../ui/Inputs/InputWrapper";
import ButtonSubmit from "../../ui/buttons/ButtonSubmit";

//Assets

import BackArrow from '../../../resources/images/back-arrow.svg';

const PasswordForgot = () => {

    const [formData, updateFormData] = useState(Initial_PasswordForgetFormData);

    const {passwordForgot, authRequestState, authRequestError, authSuccessMessage} = AuthDataManager();

    const {inputHandler, inputBlur, submitHandler, disableFormInput, disableSubmitButton} = UseForm({
        submitHandler: passwordForgot,
        requestState: authRequestState,
        formData: formData,
        updateFormData: updateFormData
    });

    return (
        <div className='addVehiclesFormContentWrapper'>

            <div className='signUpWrapper formWrapper'>
                <div className="backBtnAndTitleWrapper">
                    <Link to="/accounts/signin" className="backLink"><img src={BackArrow} alt="back"/></Link>

                    <h4 className='signUpTitle'>Forgot Password</h4>
                </div>

                <form>
                    {
                        formData.map((item, key) => {
                            return (
                                <InputWrapper
                                    key={`signin${key}`}
                                    item={item}
                                    disableFormInput={disableFormInput}
                                    inputHandler={inputHandler}
                                    inputBlur={inputBlur}
                                />
                            )
                        })
                    }

                    {
                        authRequestError &&
                        <span className='errorMsg'>{authRequestError}</span>
                    }

                    <ButtonSubmit
                        classNames="authSubmitBtn"
                        title="Send reset link"
                        requestState={authRequestState}
                        SuccessMessage={authSuccessMessage}
                        disabled={disableSubmitButton}
                        submitHandler={submitHandler}
                    />
                </form>
            </div>
        </div>
    );
};

export default PasswordForgot;
