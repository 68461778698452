import React, {useContext} from "react";
import {Link} from "react-router-dom";

import GlobalDataContext from "../../../../contexts/GlobalDataContext";
import ViewContext from "../../../../contexts/ViewContext";

import {vehicleSteps} from "./constants";
import FormControlButtons from "./FormControlButtons";
import {postalCodeFormatWithSpace} from "../../../../helpers/postalCodeFormat";
import {myChoicePartnerId} from "../../../../config/partnersConfig";

const VehicleDesktopHeader = () => {

    const {globalData} = useContext(GlobalDataContext);

    const {viewHeader, viewSize} = useContext(ViewContext);

    return (
        <>
            <div className={`${globalData.partner.id === myChoicePartnerId ? 'addVehiclesFormNavBar' : 'addVehiclesFormNavBarPartners'}`}>
                <div className="addVehiclesFormNavBarContent">
                    <div className="multiStepContentWrapper">
                        <div className="postalCodeData">
                            <h2>Car Insurance Quote</h2>
                            {
                                globalData.isPostal &&
                                <p>Your Postal Code: {postalCodeFormatWithSpace(globalData.postal.postal_code)}</p>
                            }
                        </div>
                        <ol className='progtrckr'>
                            {
                                vehicleSteps.map((item, index) => (
                                    <Link to={`/${globalData.partner.id}${item.path}`} key={`${index}-${item.title}`}
                                          className={`progtrckr-${
                                              viewHeader.vehicleId <= index ?
                                                  viewHeader.vehicleId === index ?
                                                      'doing' :
                                                      'todo'
                                                  : 'done'
                                          }`}>
                                        <li
                                            className={`progtrckr-${
                                                viewHeader.vehicleId <= index ?
                                                    viewHeader.vehicleId === index ?
                                                        'doing' :
                                                        'todo'
                                                    : 'done'
                                            }`}
                                            value={index}
                                        >
                                            {
                                                item.icon
                                            }
                                            <div>
                                                <span>{viewSize.isMobile ? `${item.name} / 4` : item.name}</span>
                                                <span className='multiStepTitle'>{item.title}</span>
                                            </div>
                                        </li>
                                    </Link>
                                ))
                            }
                        </ol>

                        <FormControlButtons />

                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleDesktopHeader;
