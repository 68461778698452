import React, {useContext} from "react";
import QuoteDataContext from "../../../contexts/formContexts/QuoteDataContext";
import ViewContext from "../../../contexts/ViewContext";
import QuoteItemDescription from "./QuoteItemDescription";
import PhoneNumber from "./PhoneNumber";
import {BrokerLink} from "./BrokerLink";
import {formatPhoneObject} from "../../../helpers/formatUtils";

const QuotesList = () => {
    const {quoteData} = useContext(QuoteDataContext);

    const {viewSize} = useContext(ViewContext);

    return(
        <div>
            {
                quoteData.quoteTab.length > 0 &&
                <>
                    {
                        quoteData.quoteTab.filter((itemRoot, indexRoot)=> viewSize.isMobile ? indexRoot===0 : true).map((item, index)=>{return(

                            <div key={index} className="singleQuotesOfferInfo">
                                <div className="offersFirstRow">
                                    <h3>{quoteData.quotes[item.index].company.name}</h3>
                                    <div className="bestOfferLabel">
                                        <p>Best Offer</p>
                                    </div>
                                </div>
                                <div className="offersSecondRow">
                                    <img src={quoteData.quotes[item.index].company.logo} className="companyLogo" alt="logo"/>
                                    <div>
                                        <h1>${quoteData.quotes[item.index].price_monthly} <span>/ month</span></h1>
                                        <h2>${parseFloat(quoteData.quotes[item.index].price_yearly).toFixed()} / year</h2>
                                    </div>
                                </div>
                              {
                                quoteData.quotes[item.index].broker_profile.redirect_url ?
                                  <BrokerLink redirectUrl={quoteData.quotes[item.index].broker_profile.redirect_url}/>
                                  : <PhoneNumber phoneNumber={formatPhoneObject(quoteData.quotes[item.index].broker_profile.phone).phoneNumber}/>
                              }
                              <QuoteItemDescription item={item}/>
                            </div>

                        )})
                    }
                </>
            }
        </div>
    );
}

export default QuotesList;
