import axios from 'axios';

export default class {
    getAccessToken = (id) => {
        return localStorage.getItem(`${id}_ta`);
    };
    getRefreshToken = (id) => {
        return localStorage.getItem(`${id}_tr`);
    };
    setTokens = (data, id) => {
        localStorage.setItem(`${id}_tr`, data.refresh);
        localStorage.setItem(`${id}_ta`, data.access);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
    };
    clearTokens = (id) => {
        localStorage.removeItem(`${id}_tr`);
        localStorage.removeItem(`${id}_ta`);
        delete axios.defaults.headers.common['Authorization'];
    };
}
