import React, {useCallback, useContext} from "react";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import DiscountDataContext from "../../../../../../contexts/formContexts/DiscountDataContext";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {discountDataActionTypes} from "../../../../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";

const DriverGender = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);
    const {updateDiscountData} = useContext(DiscountDataContext);

    const handleSwitch = useCallback((value) => {

        updateDriverData({type: driverDataActionTypes.UpdateGender, index: driverTab.activeItem, payload: value});
        if(driverTab.activeItem === 0){
            updateDiscountData({
                type: discountDataActionTypes.UpdateUserGender,
                payload: value
            });
        }
    }, [driverTab.activeItem, updateDiscountData, updateDriverData]);

    return(
      <>
          <VehicleInputTitleWithToolTip id="selectGender" title="What is your gender?" description="The gender on the policy should match your official driver’s licence. Some insurers analyze a driver's sex when creating a policy. Men are typically considered higher risk than female drivers, but the statistics supporting this idea vary from province to province. On average, men and women pay roughly the same for insurance, though." />

          <div className="formButtonsWrapper">
              <button
                  onClick={() => handleSwitch("M")}
                  className={`${driverData.items[driverTab.activeItem].gender === 'M' ? 'selectedBtn' : 'deSelectedBtn'} genderBtn`}>
                  Male
              </button>
              <button
                  onClick={() => handleSwitch("F")}
                  className={`${driverData.items[driverTab.activeItem].gender === 'F' ? 'selectedBtn' : 'deSelectedBtn'} genderBtn`}>
                  Female
              </button>
              <button
                  onClick={() => handleSwitch("other")}
                  className={`${driverData.items[driverTab.activeItem].gender === 'other' ? 'selectedBtn' : 'deSelectedBtn'} genderBtn`}>
                  Other
              </button>
          </div>
      </>
    );
}

export default DriverGender;
