export const arrayReducerActionTypes = {
    UpdateArray:'UpdateArray',
    CleanArray:'CleanArray'
}

export const arrayReducer = (state, action) => {
    switch (action.type) {
        case arrayReducerActionTypes.UpdateArray:
            return action.payload;
        case arrayReducerActionTypes.CleanArray:
            return [];
        default:
            return state;
    }
}
