export const formatTitleToCapital = (name) => {
    return name.replace(/-/g,' ').replace(/\+/g,' ').replace(/(\b[a-z](?!\s))/g, (letter) => letter.toUpperCase());
};

export const formatPhoneObject = (phone) => {
    const phoneToArray = phone.split(',');

    const onlyNumbers = phoneToArray[0].replace(/\D/g, '');
    let phoneNumber;
    if (onlyNumbers.length < 11) {
        phoneNumber = `1${onlyNumbers}`
    }
    else {
        phoneNumber = onlyNumbers;
    }
    const phoneToNumbers = Number(phoneNumber).toString();
    const phoneWithoutSpecialKey = phoneToNumbers.length > 10 ? phoneToNumbers.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4') : phoneToNumbers.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, '$1-$2-$3-$4');
    const phoneTitle = phoneToArray.length <2 ? phoneWithoutSpecialKey : `${phoneWithoutSpecialKey} x${phoneToArray[1]}`;

    const phoneSpecialKeys = phoneToArray[1] ? `,${phoneToArray[1]}` : '';

    return{
        phoneNumber: phoneWithoutSpecialKey,
        phoneTitle: phoneTitle,
        phoneSpecialKeys: phoneSpecialKeys
    }
}
