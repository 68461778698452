import React, {useContext, useEffect, useReducer, useState} from "react";

import {driverDataReducer, getDriverDataInitialState} from "./driverDataReducer";

import {driverTabItemInitialState, driverTabReducer} from './driverTabReducer';
import {myChoicePartnerId} from "../../../config/partnersConfig";
import GlobalDataContext from "../../GlobalDataContext";
import {getLocalInitialValue} from "../helper";
import {pageConfigActionTypes, PageConfigContext} from "../PageConfigContext";
import {FormConfigStateContext} from "../FormConfigState";
import {driverDataActionTypes} from "./driverDataActionTypes";

const DriverDataContext = React.createContext();

const getDriverInitialState = (licenceAge = 16) => {
    return{
        items: [{...getDriverDataInitialState(licenceAge)}],
        partnerID: myChoicePartnerId,
    }
}

const driverTabInitialState = {
    activeItem: 0,
    tabs: [{...driverTabItemInitialState}],
    partnerID: myChoicePartnerId,
}



export const DriverDataContextProvider = (props) => {

    const {globalData} = useContext(GlobalDataContext);
    const { formConfig } = useContext(FormConfigStateContext);

    const [driverData, updateDriverData] = useReducer(driverDataReducer, getLocalInitialValue('driverData', getDriverInitialState(formConfig.licenceConfig.licenceAge), globalData.partner.id));
    const [isFirst, setIsFirst] = useState(true);

    const [driverTab, updateDriverTab] = useReducer(driverTabReducer, getLocalInitialValue('driverTab', driverTabInitialState, globalData.partner.id));

    const {dispatchPageConfig} = useContext(PageConfigContext);

    useEffect(() => {
        if (!isFirst) {
            updateDriverData({
                type: driverDataActionTypes.UpdateLicenceAge,
                payload: formConfig.licenceConfig.licenceAge,
                index: 0,
                config: formConfig.minDates,
            });
        }
    }, [formConfig.licenceConfig.licenceAge]);

    useEffect(() => {
        if (isFirst) {
            setIsFirst(false);
        }
        else {
            dispatchPageConfig({type: pageConfigActionTypes.UpdateQuoteRequest, payload: true});
        }
    }, [driverData, driverTab.length]);

    return (
        <DriverDataContext.Provider value={{
            driverData, updateDriverData,
            driverTab, updateDriverTab
        }}>
            {
                props.children
            }
        </DriverDataContext.Provider>
    )
}

export default DriverDataContext;
