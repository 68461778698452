import React, {useContext, useEffect, useReducer} from "react";
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom';
import QuoteItem from "./QuoteItem";
import QuoteList from "./QuoteList";
import {QuoteDataManager} from "../../managers/DataManagers/QuoteDataManager";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../helpers/hooksLocalRenderState";
import {requestStatusTypes} from "../../config/constants";
import ViewContext from "../../contexts/ViewContext";
import {viewLoaderActionTypes} from "../../contexts/ViewContext/viewContextActions";
import GlobalDataContext from "../../contexts/GlobalDataContext";

const QuoteRoutes = (props) => {

    const location = useLocation();
    const {globalData} = useContext(GlobalDataContext);

    useEffect(()=>{
        window.scrollTo(0,0);
    },[location.pathname]);

    return (
        <Switch>
            <Route path={`/${globalData.partner.id}/profile/quotes-history/:quoteID`}>
                <QuoteItem quoteItems={props.quoteItems}/>
            </Route>
            <Route path={`/${globalData.partner.id}/profile/quotes-history`}>
                <QuoteList quoteItems={props.quoteItems}/>
            </Route>
        </Switch>
    )
}

const Quotes = () => {

    const {getQuotes, quoteResponseData, quoteErrorMessage, quoteRequestState} = QuoteDataManager();
    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount: 1});
    const {updateViewLoader} = useContext(ViewContext);


    useEffect(() => {
        if (!renderState.isRender && !renderState.isFirst) {
            if (renderState.requestCount === 1 && quoteRequestState !== requestStatusTypes.Loading && quoteRequestState !== requestStatusTypes.Waiting) {

                updateRenderState({type:renderStateActionTypes.UpdateRequest, payload: 0});
            }
            if (renderState.requestCount === 0){
                updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
                updateRenderState({type:renderStateActionTypes.UpdateRender, payload: true});
            }
        }
    }, [renderState, quoteRequestState])

    useEffect(() => {
        if (renderState.isFirst) {
            getQuotes();
            updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
        }
    })

    return (
        <>
            {
                quoteErrorMessage &&
                <p>{quoteErrorMessage}</p>
            }
            {
                renderState.isRender && quoteErrorMessage==='' &&
                <Router>
                    <QuoteRoutes quoteItems={quoteResponseData}/>
                </Router>
            }
        </>
    );
}

export default Quotes;
