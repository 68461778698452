import {UseFormActionTypes} from './constatns';

const useFormReducer = (state, action) => {
    switch (action.type) {
        case UseFormActionTypes.DISABLE_SUBMIT_BUTTON:
            return {...state, disableSubmitButton: action.data.disable};
        case  UseFormActionTypes.DISABLE_FORM_INPUT:
            return {...state, disableFormInput: action.data.disable};
        default:
            return state;
    }
};
export default useFormReducer;
