import React, {useCallback, useContext} from 'react';
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import VehicleNumberInput from "../../../../../ui/vehicleFormInputs/VehicleNumberInput";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import {FormConfigStateContext} from "../../../../../../contexts/formContexts/FormConfigState";

const DriverLicenceAge = () => {
    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);
    const {formConfig} = useContext(FormConfigStateContext);

    const handleLicenceAge = useCallback((event) => {
        event.persist();
        updateDriverData({
            type: driverDataActionTypes.UpdateLicenceAge,
            payload: event.target.value,
            index: driverTab.activeItem,
            config: formConfig.minDates,
        });
    },[formConfig]);

    return (
        <>
            <VehicleInputTitleWithToolTip
                id="firstLicensedDate"
                title={
                    `What age was ${driverTab.tabs[driverTab.activeItem].name} when first \n licenced?`
                }
                description={formConfig.toolTip.licenceAge}
            />


            <VehicleNumberInput
                type='number'
                className="formInput"
                placeholder="Select Age"
                defaultValue={Number(driverData.items[driverTab.activeItem].licence_info.first_licence_age)}
                min={formConfig.minDates.licenceAge}
                disabled={!driverData.items[driverTab.activeItem].date_of_birth}
                max={driverData.items[driverTab.activeItem].minDates.maxLicenceAge}
                name='first_licence_age'
                onChange={(event) => {
                    handleLicenceAge(event)
                }}
            />

            {
                driverData.items[driverTab.activeItem].date_of_birth &&
                <p className="recommendedRangeInfo licensedHint">
                    {driverTab.tabs[driverTab.activeItem].name} was
                    licenced in {
                    driverData.items[driverTab.activeItem].minDates.gOneMinDate ? driverData.items[driverTab.activeItem].minDates.gOneMinDate.split('-')[0] : ''
                } - {
                    driverData.items[driverTab.activeItem].minDates.gTwoMinDate ? driverData.items[driverTab.activeItem].minDates.gTwoMinDate.split('-')[0] : ''
                }
                </p>
            }

        </>
    );
};

export default DriverLicenceAge;
