import cloneDeep from "lodash.clonedeep";

export const getLocalInitialValue = (stateName, initialState, partnerID) => {
    const getLocalStorage = JSON.parse(localStorage.getItem(`${partnerID}_${stateName}`))
    if ( getLocalStorage ) {
        return {
            ...cloneDeep(initialState),
            ...getLocalStorage,
            partnerID: partnerID,
        }
    }
    return {
        ...cloneDeep(initialState),
        partnerID: partnerID,
    }
};
