import React, { useContext, useEffect, useReducer} from "react";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import ListItems from "./components/ListItems";
import DriverInfoBlock from "./components/DriverInfoBlock";
import DriverLicenceInfoBlock from "./components/DriverLicenceInfoBlock";
import DriverListedBlock from "./components/DriverListedBlock";
import FormBottomNavigationButtons from "../../ui/buttons/FormBottomNavigationButtons";

import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";
import GlobalDataContext from "../../../contexts/GlobalDataContext";


const FormTwoWrapper = () => {
    const {globalData} = useContext(GlobalDataContext);
    const {driverTab} = useContext(DriverDataContext);
    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount: 1});

    useEffect(()=>{
        if(!renderState.isRender && !renderState.isFirst){
            updateRenderState({type:renderStateActionTypes.UpdateRender, payload: true});
        }
    },[renderState.isFirst, renderState.isRender]);

    useEffect(()=>{
        updateRenderState({type:renderStateActionTypes.Clear});
    },[driverTab.activeItem]);

    useEffect(() => {
        if (renderState.isFirst) {
            updateRenderState({type:renderStateActionTypes.UpdateFirst, payload: false});
        }
    });

    return (
        <>
            {
                renderState.isRender &&
                <>
                    <DriverInfoBlock />
                    <DriverLicenceInfoBlock />
                    <DriverListedBlock />
                    <ListItems />
                    <FormBottomNavigationButtons next={`/${globalData.partner.id}/info`} isAddDriver={true} prev={`/${globalData.partner.id}/`}/>
                </>
            }
        </>
    )
};

export default FormTwoWrapper;
