import React, {useContext} from "react";
import DriverName from "./components/DriverName";
import DriverMaritalStatus from "./components/DriverMaritalStatus";
import DriverBirthDay from "./components/DriverBirthDay";
import DriverOccupation from "./components/DriverOccupation";
import DriverGender from "./components/DriverGender";
import DriverRelationship from "./components/DriverRelationship";
import DriverDataContext from "../../../../../contexts/formContexts/DriverDataContext";

const DriverInfoBlock = () => {

    const {driverTab} = useContext(DriverDataContext);

    return(
        <div className="formWrapper">
            <DriverName />
            <DriverMaritalStatus />
            <DriverBirthDay />
            <DriverOccupation />
            <DriverGender />
            {
                driverTab.activeItem > 0 &&
                <DriverRelationship />
            }
        </div>
    )
};

export default DriverInfoBlock;
