import moment from "moment";
import cloneDeep from 'lodash.clonedeep';
import {currentDate, dateFormatFullYear, dateFormatYear} from "../config/dateConfig";
import {compareDates} from "../components/vehicleForms/FormTwo/helper";
import {formGStartDate, formLicenceTypes} from "../config/formConstants";
import compareDateRange from "./compareDateRange";
import {provinceList} from "../config/provinceConfig";

export const clearGLicence = (state) => {
    let newState = cloneDeep(state);

    newState.licence_info.g1_licence_date = '';
    newState.licence_info.g2_licence_date = '';
    newState.licence_info.g_licence_date = '';

    return newState;
}

export const resetLicenceType = (state, checked) => {
    let newState = cloneDeep(state);
    if (checked) {
        if (!newState.minDates.gOneBlocked) {
            newState.licence_info.licence_type = formLicenceTypes.gOne;
            newState.licence_info.g1_licence_date = newState.minDates.gOneMinDate;
        }
        if (!newState.minDates.gTwoBlocked) {
            newState.licence_info.licence_type = formLicenceTypes.gTwo;
            newState.licence_info.g2_licence_date = newState.minDates.gTwoDefaultValue;
        }
        if (!newState.minDates.gBlocked) {
            newState.licence_info.licence_type = formLicenceTypes.g;
            newState.licence_info.g_licence_date = newState.minDates.gMinDate;
            newState.licence_info.g2_licence_date = newState.minDates.gTwoDefaultValue;
        }
    }

    return newState;
}

export const calculateGTwoMinDates = (state, config) => {
    let newState = cloneDeep(state);
    newState.minDates.gTwoMinDate = moment(newState.date_of_birth).add(1,'day').add(newState.licence_info.first_licence_age, 'year').add(config.g2.minMonth, 'month').local().format(dateFormatFullYear);
    newState.minDates.gTwoDefaultValue = moment(newState.minDates.gTwoMinDate).add(config.g2.defaultMonth, 'month').local().format(dateFormatFullYear);

    newState.minDates.gTwoMaxDate = (newState.licence_info.licence_type === formLicenceTypes.g && !newState.minDates.gBlocked) ?
        moment(currentDate).add(-config.g.minMonth || 12,'month').local().format(dateFormatFullYear) :
        moment(currentDate).local().format(dateFormatFullYear);
    newState.minDates.gTwoBlocked = newState.licence_info.isOnlyG || compareDateRange(newState.minDates.gTwoMinDate, null, newState.minDates.gTwoMaxDate).outOfMax;

    return newState.minDates;
}

export const calculateMinDates = (state, config = provinceList[0].minDates, changeGLicenceType) => {
    let newState = cloneDeep(state);
    newState.minDates.maxLicenceAge = Number(moment(currentDate).diff(moment(newState.date_of_birth).local(),'year'));
    if (newState.minDates.maxLicenceAge<newState.licence_info.first_licence_age) {
        newState.licence_info.first_licence_age = newState.minDates.maxLicenceAge;
    }

    newState.licence_info.isOnlyG = compareDates(moment(newState.date_of_birth).local().add(1,'day').add(newState.licence_info.first_licence_age,'year'), config.g.specialDate || formGStartDate);

    if ((Number(moment(newState.date_of_birth).local().format(dateFormatYear))+Number(newState.licence_info.first_licence_age))>Number(currentDate.getFullYear())) {
        newState.licence_info.first_licence_age = newState.minDates.maxLicenceAge;
    }

    newState.minDates.gOneMinDate = moment(newState.date_of_birth).add(1,'day').add(newState.licence_info.first_licence_age, 'year').add(config.g1.minMonth, 'month').local().format(dateFormatFullYear);
    newState.minDates.gOneMaxDate = moment(currentDate).local().format(dateFormatFullYear);
    newState.minDates.gOneDefaultValue = moment(newState.minDates.gOneMinDate).add(config.g1.defaultMonth, 'month').local().format(dateFormatFullYear);
    newState.minDates.gOneBlocked = newState.licence_info.isOnlyG || compareDateRange(newState.minDates.gOneMinDate, null, newState.minDates.gOneMaxDate).outOfMax;

    newState.minDates = calculateGTwoMinDates(newState, config);

    newState.minDates.gMinDate =
        newState.licence_info.isOnlyG ?
            newState.minDates.gOneMinDate :
            moment( newState.licence_info.g2_licence_date || newState.minDates.gTwoDefaultValue).add(config.g.minMonth, 'month').local().format(dateFormatFullYear);
    newState.minDates.gMaxDate =
        newState.licence_info.isOnlyG ?
            moment(config.g.specialDate || formGStartDate).add(-1, 'day').local().format(dateFormatFullYear) :
            moment(currentDate).local().format(dateFormatFullYear);
    newState.minDates.gDefaultValue = moment(newState.minDates.gMinDate).add(config.g.defaultMonth, 'month').local().format(dateFormatFullYear);
    newState.minDates.gBlocked = compareDateRange(newState.minDates.gMinDate, null, moment(currentDate).format(dateFormatFullYear)).outOfMax;

    newState.minDates = calculateGTwoMinDates(newState, config);

    if (newState.licence_info.isOnlyG) {
        newState.licence_info.licence_type = formLicenceTypes.g;
        newState.minDates.gMinDate = moment(newState.date_of_birth).add(1,'day').add(newState.licence_info.first_licence_age, 'year').local().format(dateFormatFullYear);
        if (!newState.licence_info.g_licence_date) {
            newState.licence_info.g_licence_date = newState.minDates.gMinDate;
        }
    }

    if (changeGLicenceType) {
        if (newState.minDates.gBlocked) {
            if (newState.licence_info.licence_type === formLicenceTypes.g) {
                newState.licence_info.licence_type = formLicenceTypes.gTwo;
                newState.licence_info.g_licence_date = '';
            }
        }

        if (newState.minDates.gTwoBlocked) {
            if (newState.licence_info.licence_type === formLicenceTypes.gTwo) {
                newState.licence_info.licence_type = formLicenceTypes.gOne;
                newState.licence_info.g2_licence_date = '';
            }
        }

        if (newState.minDates.gOneBlocked) {
            if (newState.licence_info.licence_type === formLicenceTypes.gOne){
                newState.licence_info.g1_licence_date = '';
                newState.licence_info.licence_type = '';
            }
        }
    }

    if (compareDateRange(newState.licence_info.g_licence_date,newState.minDates.gMinDate, null).outOfMin){
        newState.licence_info.g_licence_date = newState.minDates.gMinDate;
    }

    if (!newState.licence_info.g1_licence_date || compareDateRange(newState.licence_info.g1_licence_date,newState.minDates.gOneMinDate, null).outOfMin){
        newState.licence_info.g1_licence_date = newState.minDates.gOneMinDate;
    }

    if (compareDateRange(newState.licence_info.g2_licence_date,newState.minDates.gTwoMinDate, null).outOfMin){
        newState.licence_info.g2_licence_date = newState.minDates.gTwoMinDate;
    }

    if (changeGLicenceType) {
        if (!newState.minDates.gOneBlocked) {
            newState.licence_info.licence_type = formLicenceTypes.gOne;
        }
        if (!newState.minDates.gTwoBlocked) {
            newState.licence_info.licence_type = formLicenceTypes.gTwo;
        }
        if (!newState.minDates.gBlocked) {
            newState.licence_info.licence_type = formLicenceTypes.g;
        }
    }

    newState.minDates = calculateGTwoMinDates(newState, config);

    return newState;
}

export const resetGValue = (state) => {
    let newState = cloneDeep(state);
    newState.licence_info.g_licence_date = newState.minDates.gDefaultValue;
    return newState;
}

export const resetGDefaultValues = (state, resetDates) => {
    let newState = cloneDeep(state);

    newState.licence_info.g1_licence_date = newState.minDates.gOneDefaultValue;
    newState.licence_info.g2_licence_date = newState.minDates.gTwoDefaultValue;
    newState.licence_info.g_licence_date = newState.minDates.gDefaultValue;
    if(newState.licence_info.licence_type===''){
        if(!newState.minDates.gOneBlocked){
            newState.licence_info.licence_type = formLicenceTypes.gOne;
        }
    }

    return resetLicenceType(newState, resetDates);
}
