export const provinceList = [
    {
        name: 'Ontario', // main licence type
        code: 'ON',
        toolTip: {
          licenceAge: `The selection indicates how old the primary driver was when they received their original driver’s licence. It includes motorcycle and commercial licences, too. If you do not remember your age, it is acceptable to provide a best estimate for the purposes of the policy or quote.`,
          licenceType: `The primary driver should disclose their type of driver’s licence on the policy. If you received your licence in Canada before 1994, it should have a ‘G’ classification.`,
        },
        licenceConfig: {
            title: '',
            licenceAge: 16,
            g1: {
                title: 'G1',
                name: 'g1',
                id: 'g1',
                description: '',
            },
            g2: {
                title: 'G2',
                name: 'g2',
                id: 'g2',
                description: '',
            },
            g: {
                title: 'G',
                name: 'g',
                id: 'g',
                description: '',
            },
        },
        minDates: {
            licenceAge: 16,
            g1: {
                minMonth: 0, // month plus licence age plus birth date
                defaultMonth: 0, // month plus g one min
            },
            g2: {
                minMonth: 8, //
                defaultMonth: 4, // month plus g2 min
            },
            g: {
                minMonth: 12, // month plus g2 value
                defaultMonth: 0, // month plus g min
                specialDate: '1994-04-01', // special g date, before it you can choose only g licence
            },
        },
    },
    {
        name: 'Alberta',
        code: 'AB',
        toolTip: {
            licenceAge: `Please indicate the age at which this driver first received a driver's licence of any type in Alberta. If you don't remember the age at which the licence was received, please make your best estimate.`,
            licenceType: `A Class 7 licence is a learner’s licence that let’s you drive under the supervision of a licenced driver.
            
            A Class 5-GDL licence is a probationary licence that let’s you drive with limited restrictions. This licence is obtained after having a Class 7 licence for 1 year or more.
            
            A Class 5 licence is a full licence obtained after holding a Class 5-GDL licence for 2 years and allows you to drive with almost no restrictions.
            
            If you have you have driving experience from another country, please select your present Alberta driver’s licence.`,
        },
        licenceConfig: {
            licenceAge: 14,
            title: '',
            g1: {
                title: 'Class 7',
                name: 'g1',
                id: 'g1',
                description: '',
            },
            g2: {
                title: 'Class 5-GDL',
                name: 'g2',
                id: 'g2',
                description: '',
            },
            g: {
                title: 'Class 5',
                name: 'g',
                id: 'g',
                description: '',
            },
        },
        minDates: {
            licenceAge: 14,
            g1: {
                minMonth: 0,
                defaultMonth: 0,
            },
            g2: {
                minMonth: 24,
                defaultMonth: 0,
            },
            g: {
                minMonth: 24,
                defaultMonth: 0,
                specialDate: '2004-01-01',
            },
        },
    },
    {
        name: 'Nova Scotia',
        code: 'NS',
        toolTip: {
            licenceAge: `The selection indicates how old the primary driver was when they received their original driver’s licence. It includes motorcycle and commercial licences, too. If you do not remember your age, it is acceptable to provide a best estimate for the purposes of the policy or quote.`,
            licenceType: `A Class 7 licence is the learner's licence you obtain after passing the written test and it allows you to drive with supervision from a licenced driver.
            
            A Class 5N licence is a probationary licence obtained after holding a Class 7 licence for at least 1 year (or 9 months with the completion of a driver's education course) and after you pass an advanced test on the road. Here you can drive with limited restrictions.
            
            A Class 5 licence is a full driving licence obtained after holding a Class 5N licence for 2 years.
            
            If you have driving experience from another country, please select your present Nova Scotia driver’s licence.`,
        },
        licenceConfig: {
            licenceAge: 16,
            title: '',
            g1: {
                title: 'Class 7',
                name: 'g1',
                id: 'g1',
                description: '',
            },
            g2: {
                title: 'Class 5N',
                name: 'g2',
                id: 'g2',
                description: '',
            },
            g: {
                title: 'Class 5',
                name: 'g',
                id: 'g',
                description: '',
            },
        },
        minDates: {
            licenceAge: 16,
            g1: {
                minMonth: 0,
                defaultMonth: 0,
            },
            g2: {
                minMonth: 9,
                defaultMonth: 3,
            },
            g: {
                minMonth: 24,
                defaultMonth: 0,
                specialDate: '1995-01-01',
            },
        },
    },
    {
        name: 'Newfoundland',
        code: 'NF',
        toolTip: {
            licenceAge: `The selection indicates how old the primary driver was when they received their original driver’s licence. It includes motorcycle and commercial licences, too. If you do not remember your age, it is acceptable to provide a best estimate for the purposes of the policy or quote.`,
            licenceType: `A Class 5-Level 1 licence is a learner's licence obtained after passing a written test and allows you to drive under supervision.
            
            A Class 5-Level 2 licence is a probationary licence obtained after holding a Class 5-Level 1 licence for 1 year (or 8 months with the completion of a driver's education course) and passing an advanced test on the road. This allows you to drive with some restrictions.
            
            A Class 5 licence is a full licence obtained after holding a Class 5-Level 2 licence for 1 year and passing the final test on the road.
            
            If you have driving experience from another country, please select your current Newfoundland and Labrador driver’s licence.
            `,
        },
        licenceConfig: {
            licenceAge: 16,
            title: '',
            toolTip: {
                description: "",
            },
            g1: {
                title: 'Class 5-Level 1',
                name: 'g1',
                id: 'g1',
                description: '',
            },
            g2: {
                title: 'Class 5-Level 2',
                name: 'g2',
                id: 'g2',
                description: '',
            },
            g: {
                title: 'Class 5',
                name: 'g',
                id: 'g',
                description: '',
            },
        },
        minDates: {
            licenceAge: 16,
            g1: {
                minMonth: 0,
                defaultMonth: 0,
            },
            g2: {
                minMonth: 8,
                defaultMonth: 4,
            },
            g: {
                minMonth: 12,
                defaultMonth: 0,
                specialDate: '1999-01-01',
            },
        },
    },
    {
        name: 'New Brunswick',
        code: 'NB',
        toolTip: {
            licenceAge: `The selection indicates how old the primary driver was when they received their original driver’s licence. It includes motorcycle and commercial licences, too. If you do not remember your age, it is acceptable to provide a best estimate for the purposes of the policy or quote.`,
            licenceType: `A Class 7-Level 1 licence is a learner's licence obtained after passing a written test.

A Class 7-Level 2 licence is a probationary licence obtained after holding a Class
7-Level 1 licence for 12 months (or 8 months with the completion of a driver's education course) and passing an advanced road test.

A Class 5 licence is a full licence obtained after holding a Class 7-Level 2 licence for 12 months and passing the final road test.

If you do not currently hold any of the licences listed above, but have a licence for another vehicle class, province, or the U.S., please select "Other`,
        },
        licenceConfig: {
            licenceAge: 16,
            title: '',
            g1: {
                title: 'Class 7 - Level 1',
                name: 'g1',
                id: 'g1',
                description: '',
            },
            g2: {
                title: 'Class 7 - Level 2',
                name: 'g2',
                id: 'g2',
                description: '',
            },
            g: {
                title: 'Class 5',
                name: 'g',
                id: 'g',
                description: '',
            },
        },
        minDates: {
            licenceAge: 16,
            g1: {
                minMonth: 0,
                defaultMonth: 0,
            },
            g2: {
                minMonth: 8,
                defaultMonth: 4,
            },
            g: {
                minMonth: 12,
                defaultMonth: 0,
                specialDate: '1995-01-01',
            },
        },
    },
];

