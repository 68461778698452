import moment from "moment";
import {dateFormatFullYear} from "../../../config/dateConfig";
import {returnFullDate} from "../../../helpers/dateHelper";

export function compareDates(defCDate,defMinDate) {

    const cDate = moment(returnFullDate(defCDate)).local();
    const minDate = moment(returnFullDate(defMinDate)).local();
    return cDate < minDate;
}

export function comparePassesDriverTraining(curDate, gTwoDate){
    return compareDates(moment(curDate).add(-3, 'year').add(-1,'month').format(dateFormatFullYear),returnFullDate(gTwoDate));
}
