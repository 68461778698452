import React, {useContext} from "react";
import DriverDataContext from "../../../../../../../contexts/formContexts/DriverDataContext";
import {formLicenceTypes} from "../../../../../../../config/formConstants";
import GOneInputBlock from "./GInputs/GOneInputBlock";
import GTwoInputBlock from "./GInputs/GTwoInputBlock";
import GInputBlock from "./GInputs/GInputBlock";

const GLicenceBlock = () => {

    const {driverTab, driverData} = useContext(DriverDataContext);

    switch (driverData.items[driverTab.activeItem].licence_info.licence_type){

        case formLicenceTypes.g:
            return (
                <>
                    {
                        !driverData.items[driverTab.activeItem].licence_info.isOnlyG &&
                        <GTwoInputBlock />
                    }
                    <GInputBlock />
                </>
            )

        case formLicenceTypes.gOne:
            return <GOneInputBlock />

        case formLicenceTypes.gTwo:
            return <GTwoInputBlock />

        default:
            return <></>
    }
}

export default GLicenceBlock;
