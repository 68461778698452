
/* Global Data Context Actions*/

export const globalDataActionTypes = {
    UpdateUser: 'UpdateUser',
    UPDATE_UID: 'UPDATE_UID',
    UpdateAuth: 'UpdateAuth',
    ClearUser: 'ClearUser',
    UpdatePostal: 'UpdatePostal',
    GetLocalPostal: 'GetLocalPostal',
    UpdatePostalErrors: 'UpdatePostalErrors',
    ClearPostal: 'ClearPostal',
    UpdateSignedInRedirect: 'UpdateSignedInRedirect',
    UpdatePartnerData: 'UpdatePartnerData',
    ClearPartnerData: 'ClearPartnerData',
};
