import React, {useCallback, useContext} from "react";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";

const DriverPreviousLicence = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);

    const handleSwitch = useCallback((value)=>{
        updateDriverData({type:driverDataActionTypes.UpdateLicencePrevious, index: driverTab.activeItem, payload: value})
    },[driverTab.activeItem, updateDriverData]);

    return(
      <>
          <VehicleInputTitleWithToolTip
            id="holdFullLicense"
            extraClassName="nextToCalendarInput"
            title={`Has ${driverData.items[driverTab.activeItem].first_name} ever had a full \n licence anywhere else in Canada or USA?`}
            description="If you have driving experience outside Canada or the United States,
                      it may lower
                      your premium. Your insurer may require proof of insurance in these other locations or some
                      proof of
                      driving experience from the country you indicate (like a copy of a previous driver’s
                      licence)."
          />

          <div className="formButtonsWrapper">
              <button
                  onClick={() => handleSwitch(true)}
                  className={driverData.items[driverTab.activeItem].licence_info.previous_licence ? "selectedBtn" : "deSelectedBtn"}>Yes
              </button>
              <button
                  onClick={() => handleSwitch( false)}
                  className={!driverData.items[driverTab.activeItem].licence_info.previous_licence ? "selectedBtn" : "deSelectedBtn"}>No
              </button>
          </div>
      </>
    );
}
export default DriverPreviousLicence;
