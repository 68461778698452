import React, {useContext} from "react";
import DriverDataContext from "../../../../../contexts/formContexts/DriverDataContext";
import VehicleDataContext from "../../../../../contexts/formContexts/VehicleDataContext";
import DiscountDataContext from "../../../../../contexts/formContexts/DiscountDataContext";
import {discountDataActionTypes} from "../../../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";

const MultipleDrivers = () => {
    const {driverTab} = useContext(DriverDataContext);
    const {vehicleData} = useContext(VehicleDataContext);
    const {discountData, updateDiscountData} = useContext(DiscountDataContext);

    function handleDriverOption(event, vehicleIndex) {

        let newVehlinks = [...discountData.vehLink];

        newVehlinks[vehicleIndex] = {
            ...newVehlinks[vehicleIndex], driver_index: Number(event.target.value),
        }
        updateDiscountData({type: discountDataActionTypes.UpdateVehlinks, payload: [...newVehlinks]});
    }


    return(
        <>
            {
                vehicleData.items.length > 1 && driverTab.tabs.length > 1 &&
                <>
                    <h4 className="stepIntroTitle"> Since there are multiple drivers in this quote,
                        please tell us who drives which vehicle the most. Please provide an answer that
                        best suits your situation. </h4>

                    {
                        vehicleData.items.map((vehicleItem, vehicleIndex) => {
                            return (
                                <div key={`vehicleItem${vehicleIndex}`}>
                                    <div className="formLabelAndTooltipWrapper">
                                        <h4>Who is the principal driver of
                                            the {vehicleItem.year} {vehicleItem.make} {vehicleItem.model} ?</h4>
                                    </div>
                                    <select
                                        className='formSelect'
                                        onChange={(event) => {
                                            handleDriverOption(event, vehicleIndex)
                                        }}
                                        defaultValue={discountData.vehLink[vehicleIndex]?.driver_index}
                                    >
                                        <option value='' disabled={true}>Select driver</option>
                                        {
                                            driverTab.tabs.map((driverTab, driverTabIndex) => {
                                                return (
                                                    <option
                                                        key={`driverOption${driverTabIndex}`}
                                                        value={driverTab.value}
                                                    >
                                                        {
                                                            driverTab.name
                                                        }
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            )
                        })

                    }
                </>
            }
        </>
    )
}
export default MultipleDrivers;
