import React, {useCallback, useContext} from "react";
import VehicleDateInput from "../../../../../ui/vehicleFormInputs/VehicleDateInput";
import {maxCurrentDate} from "../../../constants";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";

const DriverListedDate = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);

    const handleInput = useCallback((evt)=>{
        updateDriverData({type: driverDataActionTypes.UpdateListedYear, index: driverTab.activeItem, payload: evt.target.value});
    },[]);

    const handleCheckBox = useCallback((evt)=>{
        evt.persist();
        updateDriverData({type: driverDataActionTypes.UpdateListed, index: driverTab.activeItem, payload: evt.target.value === 'on'});
    },[]);


    return(
        <>
            <VehicleInputTitleWithToolTip
                id="firstListed"
                title={`When was ${driverTab.tabs[driverTab.activeItem].name} first listed as a driver on a Canadian or US insurance policy?`}
                description="The selection indicates what year the main driver was first listed on a Canadian or US insurance policy. If you do not remember your age, it is acceptable to provide a best estimate for the purposes of the policy or quote."
            />

            <VehicleDateInput
                className='inputDate'
                type='month'
                name='listed_year'
                defaultValue={driverData.items[driverTab.activeItem].listed_year}
                min={driverData.items[driverTab.activeItem].licence_info.g1_licence_date || driverData.items[driverTab.activeItem].minDates.gOneMinDate}
                max={maxCurrentDate}
                parentName=''
                handleDate={handleInput}
                disabled={!driverData.items[driverTab.activeItem].listed}
            />
            <div className="checkbox">
                <input
                    type="checkbox"
                    checked={!driverData.items[driverTab.activeItem].listed}
                    value={driverData.items[driverTab.activeItem].listed ? 'off' : 'on'}
                    name="listed"
                    onChange={(event) => handleCheckBox(event)}
                />
                <label><span>Never listed on an insurance policy</span></label>
            </div>
        </>
    )
}

export default DriverListedDate;
