import React from "react";

import {InputTypes} from "./constants";

import InputHiddenField from "./InputHiddenField";
import InputSwitch from "./InputSwitch";
import InputField from "./InputField";
import InputFIeldReadOnly from "./InputFIeldReadOnly";
import InputSelect from "./InputSelect";
import InputDate from "./InputDate";

const InputWrapper = (props) => {
    return (
        <>
            {
                props.item.componentType === InputTypes.FIELD &&
                <InputField
                    item={props.item}
                    disableFormInput={props.disableFormInput}
                    inputHandler={props.inputHandler}
                    inputBlur={props.inputBlur}
                />
            }
            {
                props.item.componentType === InputTypes.FIELD_READONLY &&
                <InputFIeldReadOnly
                    item={props.item}
                />
            }
            {
                props.item.componentType === InputTypes.HIDDEN_FIELD &&
                <InputHiddenField item={props.item}/>
            }
            {
                props.item.componentType === InputTypes.SWITCH &&
                <InputSwitch
                    item={props.item}
                    disableFormInput={props.disableFormInput}
                    switchHandler={props.switchHandler}
                />
            }
            {
                props.item.componentType === InputTypes.SELECT &&
                    <InputSelect
                    item={props.item}
                    disableFormInput={props.disableFormInput}
                    selectHandler={props.selectHandler}
                    />
            }
            {
                props.item.componentType === InputTypes.DATE &&
                    <InputDate
                        disableFormInput={props.disableFormInput}
                        item={props.item}
                        datePickerHandler={props.datePickerHandler}

                    />
            }
        </>
    );
};
export default InputWrapper;
