import React, {useContext, useEffect, useReducer} from "react";

import ViewContext from "../../../contexts/ViewContext";

import StepOneValidationManager from "../../../managers/ValidationManagers/StepOneValidationManager";
import StepTwoValidationManager from "../../../managers/ValidationManagers/StepTwoValidationManager";
import StepThreeValidationManager from "../../../managers/ValidationManagers/StepThreeValidationManager";
import {validationStatusTypes} from "../../../managers/ValidationManagers/constants";

import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";

import QuotesList from "./QuotesList";
import VehiclesList from "./VehiclesList";
import EditQuote from "./EditQuote";
import BestOffer from "./BestOffer";
import QuoteDataContext from "../../../contexts/formContexts/QuoteDataContext";
import {QuoteDataManager} from "../../../managers/DataManagers/QuoteDataManager";
import {PageConfigContext} from "../../../contexts/formContexts/PageConfigContext";

const FormFor = () => {

    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount: 4});

    const { viewSize } = useContext(ViewContext);
    const { pageConfig } = useContext(PageConfigContext);
    const { quoteData } = useContext(QuoteDataContext);

    const { stepOneStatus, stepOneValidation } = StepOneValidationManager();
    const { stepTwoStatus, stepTwoValidation } = StepTwoValidationManager();
    const { stepThreeStatus, stepThreeValidation } = StepThreeValidationManager();

    const {postRequestQuote} = QuoteDataManager();

    useEffect(() => {
        if (!renderState.isRender && !renderState.isFirst) {
            switch (renderState.requestCount) {
                case 4:
                    if (pageConfig.quoteRequest) {
                        updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 3});
                    } else {
                        updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 1});
                    }
                    break;
                case 3:
                    if (!quoteData.quotes.length || pageConfig.quoteRequest){
                        postRequestQuote(false, true, true, true, false);
                        updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 2});
                    }
                    else {
                        updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 1});
                    }
                    break;
                case 2:
                    if (!pageConfig.quoteRequest) {
                        updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 1});
                    }
                    break;
                case 1:
                    updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
                    break;
                default:
                    break;
            }

        }
    },[renderState, quoteData]);

    useEffect(() => {
        if (renderState.isFirst) {
            if (stepOneStatus === validationStatusTypes.Waiting) {
                stepOneValidation();
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Waiting) {
                stepTwoValidation();
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Validated && stepThreeStatus === validationStatusTypes.Waiting) {
                stepThreeValidation();
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Validated && stepThreeStatus === validationStatusTypes.Validated) {
                updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
            }
        }
    });

    return (
        <div className="add-vehicles-content-wrapper">
            {
                renderState.isRender &&
                <div className="addVehiclesFormContentWrapper">
                    <div className="quotesContentWrapper">
                        {
                            viewSize.isMobile &&
                                <BestOffer />
                        }
                        <div>
                            <EditQuote/>
                            <VehiclesList/>
                        </div>

                        <QuotesList />
                    </div>
                </div>
            }
        </div>
    );
}
export default FormFor;
