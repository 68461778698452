import {validationManagerActionTypes} from "./constants";

export const validationManagerReducer = (state, action) => {
    switch (action.type) {

        case validationManagerActionTypes.UpdateValid:
            return {...state, isValid: action.payload};

        case validationManagerActionTypes.UpdateProcess:
            return {...state, status: action.payload};

        case validationManagerActionTypes.UpdateError:
            return {...state, errorMessage: action.payload};

        default:
            return state;
    }
}
