import React, {useContext, useEffect, useReducer} from "react";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";
import {formStateActionTypes, formStateReducer} from "../../../helpers/formState";
import {driverDataActionTypes} from "../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import VehicleDateInput from "./VehicleDateInput";
import VehicleInputTitleWithToolTip from "./VehicInputTitleWithToolTip";

const ReturnOptions = (props) => {
    return(
        <>
            {
                props.optionList.map((item, index)=>{
                    return(
                        <option key={`optSel${index}`} value={item.value}>{item.name}</option>
                    )
                })
            }
        </>
    );
}

const DriverList = (props) => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);

    const [localActive, updateLocalActive] = useReducer(formStateReducer, {});
    const [localState, updateLocalState] = useReducer(formStateReducer, {});
    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount: 2});


    //update
    useEffect(() => {
        let isRenderAgain = false;
        if(renderState.isRender){
            if (driverData.items[localActive.activeItem]) {

                if (localState.navigation !== driverData.items[localActive.activeItem][props.navigationName]) {
                    updateDriverData({
                        type: driverDataActionTypes.UpdateListNavigation,
                        index: localActive.activeItem,
                        name: props.navigationName,
                        payload: localState.navigation
                    })
                }
                if(localState.list !== driverData.items[localActive.activeItem][props.listName]){


                    if(localState.list.length!==driverData.items[localActive.activeItem][props.listName].length){
                        isRenderAgain = true;
                    }
                    updateDriverData({
                        type: driverDataActionTypes.UpdateListContent,
                        index: localActive.activeItem,
                        name: props.listName,
                        payload: localState.list
                    })
                    if(isRenderAgain){
                        updateRenderState({type: renderStateActionTypes.Clear, payload: {requestCount:2}});
                    }
                }
            }

        }
    }, [localState]);

    // render states
    useEffect(() => {
        if (!renderState.isFirst && !renderState.isRender && localActive) {
            if (renderState.requestCount === 0) {
                updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
            }
            if (renderState.requestCount === 1) {
                if (localState.ready) {
                    updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 0});
                }
            }
            if (renderState.requestCount === 2) {
                updateLocalState({
                    type: formStateActionTypes.Update, payload: {
                        navigation: driverData.items[localActive.activeItem][props.navigationName],
                        list: driverData.items[localActive.activeItem][props.listName],
                        ready: true
                    }
                });
                updateRenderState({type: renderStateActionTypes.UpdateRequest, payload: 1});
            }

        }
    }, [renderState, localState.ready]);

    useEffect(() => {
        if (renderState.isFirst) {
            updateLocalActive({type: formStateActionTypes.Update, payload: {activeItem: driverTab.activeItem}});

            updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
        }
    })


    function handleButton(value) {
        updateLocalState({type: formStateActionTypes.Update, payload: {...localState, navigation: value}});
    }

    function handleInputs(event, index){
        let newItems = localState.list;
        newItems[index][event.target.name] = event.target.value;
        updateLocalState({type: formStateActionTypes.Update, payload: {...localState, list: [...newItems]}});
    }

    function closeItem(index) {
        const newItems = localState.list.filter((item, lIndex)=>lIndex!==index);
        updateLocalState({type: formStateActionTypes.Update, payload: {...localState, list: [...newItems]}});
    }

    function addItem(){
        updateLocalState({type: formStateActionTypes.Update, payload: {...localState, list: [...localState.list, {
                    ...props.emptyItem
                }]}});
    }

    return (
        <>

            {
                renderState.isRender &&
                <>

                    <h4 className="sectionTitle">{props.title}</h4>
                    <VehicleInputTitleWithToolTip
                        title={props.description}
                        id={props.toolTipId}
                        description={props.toolTip}
                    />

                    <div className="formButtonsWrapper">
                        <button
                            onClick={() => handleButton(true)}
                            className={localState.navigation ? "selectedBtn" : "deSelectedBtn"}>Yes
                        </button>
                        <button
                            onClick={() => handleButton(false)}
                            className={!localState.navigation ? "selectedBtn" : "deSelectedBtn"}>No
                        </button>
                    </div>
                    {
                        localState.navigation &&
                        <>

                                {
                                    localState.list.map((listItem, listIndex) => {
                                        return (
                                            <div key={`listItem${listIndex}`} className="additionalFieldsWrapper">
                                                {
                                                    props.itemStructure.map((structure, structureIndex) => {
                                                        return (
                                                            <div key={`structure${structureIndex}`}>
                                                                <div className="formLabelAndTooltipWrapper">
                                                                    <div className="formLabelAndTooltipWrapper">
                                                                        <h4>{structure.name}</h4>
                                                                    </div>
                                                                    {
                                                                        localState.list.length>1 && structureIndex===0 &&
                                                                        <button
                                                                            onClick={()=>{closeItem(listIndex)}}
                                                                            className="removeAdditionalFields">X</button>
                                                                    }
                                                                </div>
                                                                {
                                                                    structure.type === 'select' &&
                                                                    <select
                                                                        name={structure.formName}
                                                                        className='formSelect'
                                                                        defaultValue={listItem[structure.formName] ? listItem[structure.formName] : ''}
                                                                        onChange={(event)=>{
                                                                            handleInputs(event, listIndex);
                                                                        }}
                                                                    >
                                                                        {
                                                                            structure.optionsGroup &&
                                                                            structure.optionsGroup.map((optItem, index)=>{
                                                                                return(
                                                                                    <>
                                                                                        {
                                                                                            optItem.label &&
                                                                                            <optgroup key={`opt${index}`} label={optItem.label}>
                                                                                                <ReturnOptions optionList={optItem.options} />
                                                                                            </optgroup>
                                                                                        }
                                                                                        {
                                                                                            !optItem.label &&
                                                                                            <ReturnOptions optionList={optItem.options} />
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            <ReturnOptions optionList={structure.options} />
                                                                        }
                                                                    </select>
                                                                }
                                                                {
                                                                    structure.type === 'month' &&
                                                                    <VehicleDateInput
                                                                        className='inputDate'
                                                                        type='month'
                                                                        name={structure.formName}
                                                                        defaultValue={listItem[structure.formName] ? listItem[structure.formName] : ''}
                                                                        min={structure.dateType==='end_date' ? listItem[structure.startDateName] !=='' ? listItem[structure.startDateName] : structure.minDate : structure.minDate}
                                                                        max={structure.maxDate}
                                                                        parentName={structure.formName}
                                                                        handleDate={(event)=>{handleInputs(event,listIndex );}}
                                                                        disabled={false}
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }

                                {
                                    localState.list.length < 6 &&
                                    <button className="addAnotherField afterDatePicker" onClick={()=>{addItem()}}>
                                        Add another
                                    </button>
                                }


                        </>

                    }
                </>
            }
        </>
    );
};

export default DriverList;
