import React, {useState, useContext, useMemo, useEffect} from "react";

// contexts
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";

// assets
import downArrowWhite from "../../../resources/images/arrow-down-small-bold-white.svg";
import upArrowWhite from "../../../resources/images/arrow-up-white.svg";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import {vehicleDataActionTypes} from "../../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import {viewModalActionTypes} from "../../../contexts/ViewContext/viewContextActions";
import ViewContext from "../../../contexts/ViewContext";
import {driverDataActionTypes} from "../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import DiscountDataContext from "../../../contexts/formContexts/DiscountDataContext";
import {discountDataActionTypes} from "../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";
import {FormConfigStateContext} from "../../../contexts/formContexts/FormConfigState";


const FormMobileTab = (props) => {

    const {viewModal, updateViewModal} = useContext(ViewContext);

    const {formConfig} = useContext(FormConfigStateContext);
    const {vehicleTab, updateVehicleTab, updateVehicleData} = useContext(VehicleDataContext);
    const {driverTab, updateDriverTab, updateDriverData} = useContext(DriverDataContext);
    const {updateDiscountData} = useContext(DiscountDataContext);

    const [openSelect, updateOpenSelect] = useState(false);


    const localTabs = useMemo(() => {
        if (props.isVehicle) {
            return vehicleTab;
        } else {
            if(props.isDriver) {
                return driverTab;
            }
        }

    }, [vehicleTab.tabs.length, vehicleTab.activeItem, driverTab.tabs.length, driverTab.activeItem, vehicleTab.tabs]);


    function handleChangeItem(value) {
        if (props.isVehicle) {
            updateVehicleTab({type: vehicleDataActionTypes.SelectItem, payload: value});
        }
        if(props.isDriver){
            updateDriverTab({type: driverDataActionTypes.SelectItem, payload: value});
        }
        updateOpenSelect(false);
    }

    function handleRemoveTab(index) {
        if (props.isVehicle) {
            updateViewModal({
                type: viewModalActionTypes.OpenActionModal,
                title: `Are you sure you want to delete ${vehicleTab.tabs[index].name}`,
                actions: {
                    callback: 'REMOVE_VEHICLE',
                    id: index,
                    state: 'VEHICLE',
                    submit: true
                }
            });
        }
        if (props.isDriver) {
            updateViewModal({
                type: viewModalActionTypes.OpenActionModal,
                title: `Are you sure you want to delete ${driverTab.tabs[index].name}`,
                actions: {
                    callback: 'REMOVE_DRIVER',
                    id: index,
                    state: 'DRIVER',
                    submit: true
                }
            });
        }
        updateOpenSelect(false);
    }

    function handleAddItem() {
        if (localTabs.tabs.length < 5) {
            if (props.isVehicle) {
                updateVehicleData({type: vehicleDataActionTypes.AddItem});
                updateVehicleTab({type: vehicleDataActionTypes.AddItem});
                updateDiscountData({type: discountDataActionTypes.AddVehlink});
            }
            if (props.isDriver) {
                updateDriverData({type: driverDataActionTypes.AddItem, payload: formConfig.licenceConfig.licenceAge});
                updateDriverTab({type: driverDataActionTypes.AddItem});
            }
            updateOpenSelect(false);
        }
    }

    useEffect(() => {
        if (viewModal.callback) {
            if (props.isVehicle) {
                if (viewModal.callback.submit && viewModal.callback.state === 'VEHICLE') {
                    updateVehicleData({type: vehicleDataActionTypes.RemoveItem, payload: viewModal.callback.id});
                    updateVehicleTab({type: vehicleDataActionTypes.RemoveItem, payload: viewModal.callback.id});
                    updateDiscountData({type: discountDataActionTypes.RemoveVehlink, payload:viewModal.callback.id});
                }
            }
            if(props.isDriver){
                if (viewModal.callback.submit && viewModal.callback.state === 'DRIVER') {
                    updateDriverData({type: driverDataActionTypes.RemoveItem, payload: viewModal.callback.id});
                    updateDriverTab({type: driverDataActionTypes.RemoveItem, payload: viewModal.callback.id});

                }
            }
            updateViewModal({type: viewModalActionTypes.ClearModal});
        }

    }, [viewModal.callback]);


    return (
        <>
            <div className="select-box" onClick={() => updateOpenSelect(!openSelect)}>
                <div className="select-box__value">
                    <input className="select-box__input" type="radio"
                           defaultChecked="checked"/>
                    <p className="select-box__input-text">
                        {
                            localTabs.tabs[localTabs.activeItem] &&
                            localTabs.tabs[localTabs.activeItem].name ?
                                localTabs.tabs[localTabs.activeItem].name :
                                `Vehicle ${localTabs.activeItem + 1}`
                        }
                    </p>
                </div>
                {
                    !openSelect &&
                    <img className="select-box__icon" src={downArrowWhite} alt="Arrow Icon"
                         aria-hidden="true"/>
                }
                {
                    openSelect &&
                    <img className="select-box__icon" src={upArrowWhite} alt="Arrow Icon" aria-hidden="true"/>
                }
            </div>
            {
                openSelect &&
                <ul className="select-box__list">
                    {
                        localTabs.tabs.map((item, index) => {
                            return <li key={`tabIndex${index}`}>
                                <label className="select-box__option" aria-hidden="true" onClick={() => {
                                    handleChangeItem(index)
                                }}>{item.name}</label>
                                {
                                    localTabs.tabs.length > 1 && index>0 && !props.isRecalculate &&
                                    <button className="mobileDeleteBtn" onClick={() => {
                                        handleRemoveTab(index)
                                    }}>Delete</button>
                                }
                            </li>
                        })
                    }
                    {
                        localTabs.tabs.length < 5 && !props.isRecalculate &&
                        <li onClick={handleAddItem} className="addVehicleMobile">
                            {
                                props.isVehicle &&
                                <>Add a Vehicle</>
                            }
                            {
                                props.isDriver &&
                                <>Add a Driver</>
                            }
                        </li>
                    }
                </ul>

            }
        </>
    )
};

export default FormMobileTab;
