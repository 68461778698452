import React, {useContext, useEffect} from "react";
import {QuoteDataManager} from "../../../../../managers/DataManagers/QuoteDataManager";
import {viewModalActionTypes, viewRedirectActionTypes} from "../../../../../contexts/ViewContext/viewContextActions";
import ViewContext from "../../../../../contexts/ViewContext";
import {requestStatusTypes} from "../../../../../config/constants";
import QuoteDataContext from "../../../../../contexts/formContexts/QuoteDataContext";
import UseClearForm from "../../../../../helpers/UseClearForm";
import GlobalDataContext from "../../../../../contexts/GlobalDataContext";
import {PageConfigContext} from "../../../../../contexts/formContexts/PageConfigContext";

const SubmitBlock = () => {

    const { globalData } = useContext(GlobalDataContext);
    const { quoteData } = useContext(QuoteDataContext);
    const { updateViewModal, updateViewRedirect } = useContext(ViewContext);
    const { pageConfig } = useContext(PageConfigContext);

    const {quoteRequestState, quoteErrorMessage, postRequestQuote} = QuoteDataManager();
    const {checkIsExpired, checkIsExpiredWithModal} = UseClearForm();

    useEffect(() => {
        if (quoteErrorMessage && updateViewModal) {
            updateViewModal({
                type: viewModalActionTypes.OpenMessageModal,
                title: quoteErrorMessage,
                description: '',
                submitPath: ''
            });
        }
    }, [quoteErrorMessage, quoteRequestState]);

    useEffect(() => {
        if (quoteRequestState === requestStatusTypes.Success) {
            updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}/quote`});
        }
    }, [quoteRequestState]);


    const handleQuotes = () => {
        if (!pageConfig.isQuoteRequested && !quoteData.quotes.length) {
            postRequestQuote(true, false, true, true, false);
        }
        else {
            if (checkIsExpired()) {
                checkIsExpiredWithModal();
            }
            else {
                updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}/quote`});
            }
        }
    }

    return(
        <>
            <div className="formButtonsWrapper">
                    <button className="selectedBtn selectedFullWidthBtn" onClick={() => {
                        handleQuotes();
                    }}>
                        Get Your Quotes
                    </button>
            </div>

            <div className="stepIntroTitle">
                On the next page, you will also be able to:
            </div>

            <div className="stepIntroTitle fonts-regular">
                <p>1. See what rates insurance carriers are offering</p>
                <p>2. Get in touch with a broker and secure your rate</p>
                <p>3. Potentially save more by speaking with a broker</p>
            </div>

            <div className="stepEndDescription">
                <p>
                    Once you submit this form, you are agreeing to have your
                    insurance quote sent to you via email and My Choice will provide your contact information
                    to one of our trusted broker partners, who will contact you after your quote is complete
                    to further assist you in securing your best rate. For more details, see our
                    <a href='https://www.mychoice.ca/privacy-policy/' target="_blank"
                       rel="noopener noreferrer"> Privacy Policy</a>.
                </p>
            </div>
        </>
    );
}

export default SubmitBlock;
