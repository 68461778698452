import React, {useContext} from "react";
import ViewContext from "../../contexts/ViewContext";

export const NavigationApplication = () => {
    const {viewSize} = useContext(ViewContext);
    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            height="28px"
            style={{marginBottom: viewSize.isMobile ? '0' : '7px'}}
            viewBox="0 0 21.2 27.6"
        >
            <g fill="var(--primary-color)">
                <path d="M0,3.2v24c0,0.3,0.2,0.5,0.5,0.5H18c0.3,0,0.5-0.2,0.5-0.5V7.8c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
	c0-0.1-0.1-0.1-0.1-0.1l-4.6-4.6c0,0-0.1-0.1-0.1-0.1l0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0H0.5C0.2,2.8,0,3,0,3.2z M16.9,7.4h-3v-3
	L16.9,7.4z M0.9,3.7h12v4.1c0,0.3,0.2,0.5,0.5,0.5h4.1v18.4H0.9V3.7z"/>
                <path d="M19.4,24v0.9h1.4c0.3,0,0.5-0.2,0.5-0.5v-24C21.2,0.2,21,0,20.7,0c0,0,0,0,0,0H3.2C3,0,2.8,0.2,2.8,0.5v1.4h0.9
	V0.9h16.6v23H19.4z"/>
                <path d="M3.7,9.2h11.1v0.9H3.7V9.2z"/>
                <path d="M3.7,6.5h3.7v0.9H3.7V6.5z"/>
                <path d="M3.7,12h7.4v0.9H3.7V12z"/>
                <path d="M12,12h2.8v0.9H12V12z"/>
                <path d="M3.7,14.7h11.1v0.9H3.7V14.7z"/>
                <path d="M3.7,20.3h11.1v0.9H3.7V20.3z"/>
                <path d="M7.8,17.5h6.9v0.9H7.8V17.5z"/>
                <path d="M3.7,17.5h3.2v0.9H3.7V17.5z"/>
                <path d="M3.7,23h1.8V24H3.7V23z"/>
                <path d="M6.4,23h8.3V24H6.4V23z"/>
            </g>
        </svg>
    );
}
