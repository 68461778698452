import cloneDeep from 'lodash.clonedeep';
import {vehicleDataActionTypes} from "./vehicleDataActions";

export const PrimaryUseTypes = {
    BUSINESS:'business',
    PERSONAL:'personal'
}

export const vehicleTabItemInitialState = {
    name: 'Vehicle 1',
    value: 0
}


export const vehicleComprehensiveInitialState = {
    coverage: true,
    deductible: 1000
}
export const vehicleCollisionInitialState = {
    coverage: true,
    deductible: 1000
}
export const vehicleLiabilityInitialState = {
    coverage: true,
    limit: 1000000
}

export const vehicleItemInitialState = {
    year: '',
    make: '',
    model: '',
    condition: 'N',
    purchase_date: '',
    winter_tires: true,
    leased: false,
    parking_location: 'garage',
    primary_use: 'personal',
    distance_daily: '',
    distance_yearly: '',
    distance_business: '',
    comprehensive: {...vehicleComprehensiveInitialState},
    collision: {...vehicleCollisionInitialState},
    liability: {...vehicleLiabilityInitialState},
}

function updateLocalData(state) {
    localStorage.setItem(`${state.partnerID}_vehicleData`, JSON.stringify(state));
}

function vehicleDataRemove(state, removeIndex) {
    const newItems = state.items.filter((item, index) => index !== removeIndex);
    updateLocalData({items:[...newItems], partnerID: state.partnerID})
    return {items: [...newItems], partnerID: state.partnerID};
}

function vehicleDataAdd(state) {
    let newVehicle = cloneDeep(vehicleItemInitialState);
    state.items.push(newVehicle);
    console.log(state.items);
    updateLocalData(state);
    return state;
}

function clearItems(state){
    const newItems = {items:[cloneDeep(vehicleItemInitialState)], partnerID: state.partnerID};
    updateLocalData(newItems);
    return newItems;
}


export const vehicleDataReducer = (state, action) => {

    let newState = {...state};

    switch (action.type) {

        case vehicleDataActionTypes.Clear:
            return clearItems(state);

        case vehicleDataActionTypes.AddItem:
            return {...vehicleDataAdd({...newState})};

        case vehicleDataActionTypes.RemoveItem:
            return {...vehicleDataRemove(newState, action.payload)};

        case vehicleDataActionTypes.UpdateYear:
            newState.items[action.index].year = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateMake:
            newState.items[action.index].make = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateModel:
            newState.items[action.index].model = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateCondition:
            newState.items[action.index].condition = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateLeased:
            newState.items[action.index].leased = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdatePurchaseDate:
            newState.items[action.index].purchase_date = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateWinterTires:
            newState.items[action.index].winter_tires = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateParkingLocation:
            newState.items[action.index].parking_location = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdatePrimaryUse:
            newState.items[action.index].primary_use = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateDistanceDaily:
            newState.items[action.index].distance_daily = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateDistanceBusiness:
            newState.items[action.index].distance_business = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateDistanceYearly:
            newState.items[action.index].distance_yearly = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateComprehensive:
            if(action.payload===true){
                newState.items[action.index].comprehensive.deductible = 1000;
            }
            else{
                newState.items[action.index].comprehensive.deductible = 0;
            }
            newState.items[action.index].comprehensive.coverage = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateCollision:
            if(action.payload===true){
                newState.items[action.index].collision.deductible = 1000;
            }
            else{
                newState.items[action.index].collision.deductible = 0;
            }
            newState.items[action.index].collision.coverage = action.payload;
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateComprehensiveValue:
            newState.items[action.index].comprehensive.deductible = action.payload;
            if (Number(action.payload)>0) {
                newState.items[action.index].comprehensive.coverage = true;
            }
            else{
                newState.items[action.index].comprehensive.coverage = false;
            }
            updateLocalData({...newState});
            return {...newState};


        case vehicleDataActionTypes.UpdateCollisionValue:
            newState.items[action.index].collision.deductible = action.payload;
            if(Number(action.payload)>0){
                state.items[action.index].collision.coverage = true;
            }
            else{
                newState.items[action.index].collision.coverage = false;
            }
            updateLocalData({...newState});
            return {...newState};

        case vehicleDataActionTypes.UpdateLiability:
            newState.items[action.index].liability.limit = action.payload;
            updateLocalData({...newState});
            return {...newState};

        default:
            return state;
    }
};

function updateLocalTab(state) {
    localStorage.setItem(`${state.partnerID}_vehiclesTab`, JSON.stringify(state));
}

function addNewTab(state) {
    const newTab = {
        tabs: [...state.tabs, {name: `Vehicle ${(state.tabs.length + 1)}`, value: (state.tabs.length)}],
        activeItem: (state.tabs.length),
        partnerID: state.partnerID,
    };
    updateLocalTab(newTab);
    return newTab;
}

function removeTab(state, removeIndex) {

    const newTabs = state.tabs.filter((item, index) => index !== removeIndex);

    let newActive = 0;

    for (let i in newTabs) {
        if (newTabs[i].name.includes("Vehicle")) {
            newTabs[i].name = `Vehicle ${(Number(i) + 1)}`;
        }

    }

    if (state.activeItem >= removeIndex && state.activeItem > 0) {
        newActive = (state.activeItem - 1);
    }

    const newTab = {tabs: newTabs, activeItem: newActive, partnerID: state.partnerID};

    updateLocalTab(newTab);
    return newTab;
}

function clearTabs(state){
    const newTabs = {activeItem:0,tabs:[cloneDeep(vehicleTabItemInitialState)], partnerID: state.partnerID};
    updateLocalTab(newTabs);
    return newTabs;
}

export const vehicleTabReducer = (state, action) => {

    switch (action.type) {

        case vehicleDataActionTypes.Clear:
            return clearTabs(state);

        case vehicleDataActionTypes.SelectItem:
            if (!isNaN(action.payload) && action.payload < state.tabs.length) {
                state.activeItem = action.payload;
                updateLocalTab(state);
                return {...state, activeItem: action.payload};
            }
            return state;

        case vehicleDataActionTypes.AddItem:
            return addNewTab(state);

        case vehicleDataActionTypes.RemoveItem:
            return removeTab(state, action.payload);

        case vehicleDataActionTypes.UpdateTabName:
            state.tabs[action.index].name = action.payload !== '' ? action.payload : `Vehicle ${(Number(action.index) + 1)}`;
            updateLocalTab(state);
            return state;

        default:
            return state;
    }
};
