import React from "react";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";

const InputField = (props) => {

    return (
        <>

            <div className='formLabelAndTooltipWrapper'>
                <h4>{props.item.title}</h4>

                {
                    props.item.toolTip &&
                    <>
                        <div className="tooltipIcon" data-tip={true} data-for={props.item.formName}/>
                        <ReactTooltip id={props.item.formName} className="tooltipWrapper"
                                      arrowColor="transparent">
                            <p className="tooltipText">{props.item.toolTip}</p>
                        </ReactTooltip>
                    </>
                }
            </div>
            <div className='inputWrapper'>
                <input
                    className="formInput"
                    type={props.item.type}
                    name={props.item.formName}
                    placeholder={props.item.placeholder}
                    autoComplete={props.item.autoComplete}
                    disabled={props.item.readOnly ? 'off' : props.disableFormInput}
                    onBlur={props.inputBlur}
                    onChange={props.inputHandler}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            props.inputBlur(e);
                        }
                    }}
                    defaultValue={props.item.value}
                />


                {
                    props.item.errorMessage &&
                    <span className='errorMsg'> {props.item.errorMessage} </span>
                }
            </div>
            {
                props.item.title === 'Password' &&
                <Link rel="stylesheet" className="passForgot" to="/accounts/password/forgot">Forgot
                    Password?</Link>
            }
        </>
    );
};
export default InputField;
