import {formActionTypes} from "../formActionTypes";

export const driverDataActionTypes = {
    ...formActionTypes,
    Clear: 'Clear',
    UpdateFirstName: 'UpdateFirstName',
    UpdateLastName: 'UpdateLastName',
    UpdateMarital: 'UpdateMarital',
    UpdateDateOfBirth: 'UpdateDateOfBirth',
    UpdateOccupation: 'UpdateOccupation',
    UpdateGender: 'UpdateGender',
    UpdateRelation: 'UpdateRelation',

    /* license info*/
    UpdateLicenceAge: 'UpdateLicenceAge',
    UpdateLicenceType: 'UpdateLicenceType',
    UpdateLicenceGDate: 'UpdateLicenceGDate',
    UpdateLicenceGOneDate: 'UpdateLicenceGOneDate',
    UpdateLicenceGTwoDate: 'UpdateLicenceGTwoDate',
    UpdateLicencePrevious: 'UpdateLicencePrevious',
    UpdateLicenceAllInfo: 'UpdateLicenceAllInfo',
    UpdatePassedDriverTraining: 'UpdatePassedDriverTraining',

    /* listed */
    UpdateListed: 'UpdateListed',
    UpdateListedYear: 'UpdateListedYear',
    /* insured */
    UpdateInsured: 'UpdateInsured',
    UpdateInsuredYear: 'UpdateInsuredYear',

    /*discount date*/
    UpdateDiscountDate: 'UpdateDiscountDate',

    UpdateListNavigation: 'UpdateListNavigation',
    UpdateListContent: 'UpdateListContent'
}
