import React, {useContext, useEffect, useReducer, useState} from "react";
import {vehicleDataReducer, vehicleTabItemInitialState, vehicleItemInitialState, vehicleTabReducer} from "./vehicleDataReducer";
import GlobalDataContext from "../../GlobalDataContext";
import {myChoicePartnerId} from "../../../config/partnersConfig";
import {getLocalInitialValue} from "../helper";
import {pageConfigActionTypes, PageConfigContext} from "../PageConfigContext";

const vehicleDataInitialState = {
    items: [{...vehicleItemInitialState}],
    partnerID: myChoicePartnerId,
};

const vehicleTabInitialState = {
    tabs: [{...vehicleTabItemInitialState}],
    activeItem: 0,
    partnerID: myChoicePartnerId,
}

const VehicleDataContext = React.createContext();

export const VehicleDataContextProvider = (props) => {

    const {globalData} = useContext(GlobalDataContext);
    const {dispatchPageConfig} = useContext(PageConfigContext);
    const [isFirst, setIsFirst] = useState(true);

    const [vehicleData, updateVehicleData] = useReducer(
        vehicleDataReducer,
        { ...getLocalInitialValue('vehicleData', vehicleDataInitialState, globalData.partner.id) }
    );

    const [vehicleTab, updateVehicleTab] = useReducer(
        vehicleTabReducer,
        { ...getLocalInitialValue('vehiclesTab', vehicleTabInitialState, globalData.partner.id) }
    );

    useEffect(()=>{
        if (isFirst) {
            setIsFirst(false);
        }
        else {
            dispatchPageConfig({type: pageConfigActionTypes.UpdateQuoteRequest, payload: true});
        }
    },[vehicleData, vehicleTab.tabs.length]);

    return (
        <VehicleDataContext.Provider value={{
            vehicleData, updateVehicleData,
            vehicleTab, updateVehicleTab
        }}>
            {
                props.children
            }
        </VehicleDataContext.Provider>
    )
}

export default VehicleDataContext;
