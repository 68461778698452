import {useContext, useEffect, useReducer} from "react";

import axios from "axios";
import httpRequest from "../../services/httpRequest";

/* constants */
import {requestStatusTypes, QUOTE_FORM_API} from "../../config/constants";


// context;

import ViewContext from "../../contexts/ViewContext/ViewContext";
import {viewLoaderActionTypes} from "../../contexts/ViewContext/viewContextActions";


/* locals */
import dataManagerReducer from "./dataManagerReducer";
import {DataManagerActionTypes, dataManagerInitialState} from "./constants";


export const CarDataManager = () => {


    const {updateViewLoader} = useContext(ViewContext);

    const [localState, updateLocalState] = useReducer(dataManagerReducer, {
        ...dataManagerInitialState,
        responseData: {},
        carManufactureResponse: [],
        carModelResponse: [],
        cancelTokenSource: axios.CancelToken.source()

    });

    function defaultLoadingState(isLoader) {
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        if(isLoader) {
            updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: true});
        }
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: ''});
    }

    function defaultErrorState(response, isLoader) {
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: response.message});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: ''});
        if(isLoader) {
            updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
        }
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
    }

    function defaultSuccessState(response, isLoader, specialMessage) {
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
        updateLocalState({
            type: DataManagerActionTypes.UpdateSuccessMessage,
            payload: specialMessage ? specialMessage : response.message
        });
        if(isLoader) {
            updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
        }
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Success});
    }

    const getCarManufacture = async (year, loader) => {

        defaultLoadingState(loader);

        const response = await httpRequest(`${QUOTE_FORM_API.GET_VEHICLE}/${year}/`, {method: 'get'}, localState.cancelTokenSource.token);

        if (response) {

            if (response.status === 200 || response.status === 201) {
                updateLocalState({type: DataManagerActionTypes.UpdateManufactureResponse, payload: response.data});
                updateLocalState({type: DataManagerActionTypes.UpdateModelResponse, payload: []});
                defaultSuccessState(response, loader);
            } else {

                defaultErrorState(response, loader);
            }
        }
    };

    const getCarModel = async (year, manufacture, loader) => {
        defaultLoadingState(loader);

        const response = await httpRequest(`${QUOTE_FORM_API.GET_VEHICLE}/${year}/make/${manufacture}/`, {method: 'get'}, localState.cancelTokenSource.token);

        if (response) {

            if (response.status === 200 || response.status === 201) {
                updateLocalState({type: DataManagerActionTypes.UpdateModelResponse, payload: response.data});
                defaultSuccessState(response, loader);
            } else {

                defaultErrorState(response, loader);
            }
        }
    };


    useEffect(() => {
        return () => {
            updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
            localState.cancelTokenSource.cancel();
        }
    }, [localState.cancelTokenSource, updateViewLoader]);

    return ({
        carRequestState: localState.requestState,
        carResponseData: localState.responseData,
        carSuccessMessage: localState.SuccessMessage,
        carErrorMessage: localState.errorMessage,
        carManufactureResponse: localState.carManufactureResponse,
        carModelResponse: localState.carModelResponse,
        getCarManufacture,
        getCarModel
    });
};
