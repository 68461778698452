import React, {useCallback, useContext} from "react";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {discountDataActionTypes} from "../../../../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";
import DiscountDataContext from "../../../../../../contexts/formContexts/DiscountDataContext";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";

const DriverMaritalStatus = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);
    const {updateDiscountData} = useContext(DiscountDataContext);

    const handleSelect = useCallback((event)=>{

        const maritalStatus = event.target.value;

        updateDriverData({type: driverDataActionTypes.UpdateMarital, index: driverTab.activeItem, payload: maritalStatus});

        if(driverTab.activeItem===0){
            updateDiscountData({type:discountDataActionTypes.UpdateUserMarital, payload: maritalStatus});
        }
    },[driverTab.activeItem, updateDiscountData, updateDriverData]);

    return(
        <>
            <VehicleInputTitleWithToolTip title="What is your marital status?" id="maritalStatus" description="If you are married, it may have a positive effect on your car insurance premiums and coverage. In some provinces, the law now considers same-sex partners to have a common-law marriage, so you will need to check your local regulations. If you are divorced or widowed, select single."/>
            <select
                name='marital_status'
                defaultValue={driverData.items[driverTab.activeItem].marital_status}
                className="formSelect"
                onChange={(event) => handleSelect(event)}
            >
                <option value="">Select from the list</option>
                <option value="S">Single</option>
                <option value="M">Married/Common Law</option>
            </select>
        </>
    )
};

export default DriverMaritalStatus;
