import React, {useContext} from "react";
import GlobalDataContext from "../../../../contexts/GlobalDataContext";
import {myChoicePartnerId} from "../../../../config/partnersConfig";
import {PartnerHeader} from "./PartnerHeader";
import {PrimaryHeader} from "./PrimaryHeader";

const AppHeaderWrapper = () => {

    const {globalData} = useContext(GlobalDataContext);

    return(
        <>
            {
                globalData.partner.id === myChoicePartnerId &&
                <PrimaryHeader />
            }
            {
                globalData.partner.id !== myChoicePartnerId &&
                <PartnerHeader />
            }
        </>
    );
};

export default AppHeaderWrapper;
