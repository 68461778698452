import React from "react";
import FormTabWrapper from "../../ui/FormTab";
import VehicleRecalc from "./VehicleRecalc";

const VehiclesList = ()=>{

    return(
      <div>
          <FormTabWrapper isVehicle={true} isDriver={false} isRecalculate={true}/>
          <VehicleRecalc />
      </div>
    );
};

export default VehiclesList;
