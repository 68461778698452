export const renderStateActionTypes = {
    UpdateRender: 'UpdateRender',
    UpdateFirst: 'UpdateFirst',
    UpdateRequest: 'UpdateRequest',
    UpdateLoading: 'UpdateLoading',
    UpdateAll: 'UpdateAll',
    Clear: 'Clear'
}

export const renderInitialState = {
    isFirst: true,
    isRender: false,
    requestCount: 0,
    isLoading: false
};

export const renderStateReducer = (state, action) => {

    switch (action.type) {

        case renderStateActionTypes.UpdateFirst:
            return {...state, isFirst: action.payload};

        case renderStateActionTypes.UpdateRender:
            return {...state, isRender: action.payload};

        case renderStateActionTypes.UpdateLoading:
            return {...state, isLoading: action.payload};

        case renderStateActionTypes.UpdateRequest:
            return {...state, requestCount: action.payload};

        case renderStateActionTypes.UpdateAll:
            return {...state, ...action.payload};

        case renderStateActionTypes.Clear:
            return {...renderInitialState, ...action.payload};

        default:
            return state;
    }
};
