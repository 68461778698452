import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import './config/axiosConfig';
import './services/axiosInterceptors';

import {GlobalDataContextProvider} from "./contexts/GlobalDataContext";
import {ViewContextProvider} from "./contexts/ViewContext";

// import AppWrapper, {AppPartnersWrapper} from "./AppWrapper";
import {AppWrapper} from './AppWrapperPartners';

function App() {
    return (
        <div className="app-root">
            <GlobalDataContextProvider>
                <ViewContextProvider>
                    <Router>
                        <AppWrapper />
                    </Router>
                </ViewContextProvider>
            </GlobalDataContextProvider>
        </div>
    );
}

export default App;
