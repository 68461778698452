import React, {useContext} from "react";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {FormConfigStateContext} from "../../../contexts/formContexts/FormConfigState";

const AddDriverButton = () => {

    const {formConfig} = useContext(FormConfigStateContext);
    const {driverData, updateDriverData, updateDriverTab} = useContext(DriverDataContext);

    const addDriverHandler = () => {
        updateDriverData({type: driverDataActionTypes.AddItem, payload: formConfig.licenceConfig.licenceAge});
        updateDriverTab({type: driverDataActionTypes.AddItem});
        updateDriverTab({type: driverDataActionTypes.SelectItem, payload: (driverData.items.length-1)});
        window.scrollTo(0,0);
    }

    return(
      <>
          {
              driverData.items.length<5 &&

              <button onClick={addDriverHandler} className="bottom-add-driver-btn">
                  Add A Driver
              </button>
          }
      </>
    );
};

export default AddDriverButton;
