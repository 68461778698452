import React from 'react';
import ReactDOM from 'react-dom';
import 'react-phone-input-2/lib/style.css'
import './_styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';

if(process.env.REACT_APP_ENV==='production' && process.env.REACT_APP_GTAG_ID) {
    TagManager.initialize({
        gtmId: process.env.REACT_APP_GTAG_ID,
        dataLayerName: 'PageDataLayer',
    });
}



ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
    , document.getElementById('root'));

// If you want your AppWrapper to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
