import React, {useContext} from "react";
import {discountDataActionTypes} from "../../../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";
import DiscountDataContext from "../../../../../contexts/formContexts/DiscountDataContext";
import VehicleInputTitleWithToolTip from "../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";

const DiscountBlock = () => {

    const { discountData, updateDiscountData } = useContext(DiscountDataContext);

    function updateLocalStateWithNewUser(name, value) {

        const newDiscount = {
            multiple_policies_discount: discountData.multiple_policies_discount,
            caa_member_discount: discountData.caa_member_discount,
            app_install_discount: discountData.app_install_discount,
        }

        updateDiscountData({
            type:discountDataActionTypes.UpdateAllDiscounts,
            payload: {
                ...newDiscount, [name]: value,
            }
        });
    }

    function handleSwitch(name, value, rootName) {
        updateLocalStateWithNewUser(name, value, rootName);
    }

    return(
      <>
          <VehicleInputTitleWithToolTip
              id="multipleDiscount"
              title={"You could receive a 10-15% discount for bundling insurance with multiple policies with the same insurance company. \n Does that interest you?"}
              description="Bundling your home and auto insurance can save you significantly on insurance premiums as a whole package. Do you want to learn more about the benefits of multiple policies for home, tenant, condo, or car insurance? All you have to do is select yes.."
          />

          <div className="formButtonsWrapper">
              <button
                  onClick={() => handleSwitch('multiple_policies_discount', true)}
                  className={discountData.multiple_policies_discount === true ? "selectedBtn" : "deSelectedBtn"}>Yes
              </button>
              <button
                  onClick={() => handleSwitch('multiple_policies_discount', false)}
                  className={discountData.multiple_policies_discount === false ? "selectedBtn" : "deSelectedBtn"}>No
              </button>
          </div>

          <VehicleInputTitleWithToolTip
            title="Please indicate if you are a member of CAA, you could save up to an additional 20%."
          />

          <div className="formButtonsWrapper">
            <button
              onClick={() => handleSwitch('caa_member_discount', true)}
              className={discountData.caa_member_discount === true ? "selectedBtn" : "deSelectedBtn"}>Yes
            </button>
            <button
              onClick={() => handleSwitch('caa_member_discount', false)}
              className={discountData.caa_member_discount === false ? "selectedBtn" : "deSelectedBtn"}>No
            </button>
          </div>

          <VehicleInputTitleWithToolTip
              id="appInstalled"
              title={"You could receive up to a 30% discount by \n using an app to track driving habits. \n Does that interest you?"}
              description="Get rewarded for safe driving with the click of a button. If you install the app, you may be eligible for a 30% discount on your insurance premiums. Typically, you receive 10% of the discount upfront and the balance after six months of good driving."
          />

          <div className="formButtonsWrapper">
              <button
                  onClick={() => handleSwitch('app_install_discount', true)}
                  className={discountData.app_install_discount === true ? "selectedBtn" : "deSelectedBtn"}>Yes
              </button>
              <button
                  onClick={() => handleSwitch('app_install_discount', false)}
                  className={discountData.app_install_discount === false ? "selectedBtn" : "deSelectedBtn"}>No
              </button>
          </div>
      </>
    );
}

export default DiscountBlock;
