import React from "react";
import ReactTooltip from "react-tooltip";

const PrimaryToolTip = ({id, description}) => (
    <ReactTooltip id={id} className="tooltipWrapper" arrowColor="#FFEEAA">
        <p className="tooltipText">{description}</p>
    </ReactTooltip>
);

export default PrimaryToolTip;
