import {requestStatusTypes} from "../../config/constants";

export const DataManagerActionTypes = {
    RequestState: 'RequestState',
    ResponseState: 'ResponseState',
    UpdateSuccessMessage: 'UpdateSuccessMessage',
    UpdateErrorMessage: 'UpdateErrorMessage',
    ClearErrorMessage: 'ClearErrorMessage',
    UpdateManufactureResponse: 'UpdateManufactureResponse',
    UpdateModelResponse: 'UpdateModelResponse',
    UpdateQuoteResponse: 'UpdateQuoteResponse'
};

export const dataManagerInitialState = {
    requestState: `${requestStatusTypes.Waiting}`,
    SuccessMessage: '',
    errorMessage: ''
}
