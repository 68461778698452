import React, {useState} from 'react';

import {Redirect, useParams} from 'react-router-dom';


import {AuthDataManager} from "../../../managers/DataManagers";
import UseForm from "../../../helpers/UseForm";
import ButtonSubmit from "../../ui/buttons/ButtonSubmit";
import InputWrapper from "../../ui/Inputs/InputWrapper";

import {InputTypes} from "../../ui/Inputs/constants";
import {UseFormInputTypes} from "../../../helpers/UseForm/constatns";
import {Initial_ResetPasswordFormData} from "./constants";
import {requestStatusTypes} from "../../../config/constants";


const PasswordReset = () => {

    const {localUID, localTOKEN} = useParams();

    const [formData, updateFormData] = useState([
        ...Initial_ResetPasswordFormData,
        {
            title: '',
            name: 'uid',
            toolTip: '',
            defaultErrorMessage: '',
            placeholder: '',
            formName: 'uid',
            type: 'hidden',
            autoComplete: 'off',
            componentType: InputTypes.HIDDEN_FIELD,
            validationType: UseFormInputTypes.NO_VALIDATION,
            value: localUID,
            errorMessage: '',
        isValid: true
        },
        {
            title: '',
            name: 'token',
            toolTip: '',
            defaultErrorMessage: '',
            placeholder: '',
            formName: 'token',
            type: 'hidden',
            autoComplete: 'off',
            componentType: InputTypes.HIDDEN_FIELD,
            validationType: UseFormInputTypes.NO_VALIDATION,
            value: localTOKEN,
            errorMessage: '',
        isValid: true
        }
    ]);

    const {passwordReset, authRequestState, authRequestError, authSuccessMessage} = AuthDataManager();

    const {inputHandler, inputBlur, submitHandler, disableFormInput, disableSubmitButton} = UseForm({
        submitHandler: passwordReset,
        requestState: authRequestState,
        formData: formData,
        updateFormData: updateFormData
    });


    return (
        <div className='addVehiclesFormContentWrapper'>
            <div className='signUpWrapper formWrapper'>
                <h4 className='signUpTitle'>Set New Password</h4>

                <form autoComplete='off'>
                    {
                        formData.map((item, key) => {
                            return (
                                <InputWrapper
                                    key={`signin${key}`}
                                    item={item}
                                    disableFormInput={disableFormInput}
                                    inputHandler={inputHandler}
                                    inputBlur={inputBlur}
                                />
                            )
                        })
                    }

                    {
                        authRequestError &&
                        <span className='errorMsg'>{authRequestError}</span>
                    }

                    <ButtonSubmit
                        classNames="authSubmitBtn"
                        title="Reset password"
                        requestState={authRequestState}
                        SuccessMessage={authSuccessMessage}
                        disabled={disableSubmitButton}
                        submitHandler={submitHandler}
                    />
                </form>
                {
                    authRequestState === requestStatusTypes.Success &&
                    <Redirect to='/accounts/signin'/>
                }
            </div>
        </div>
    );
};

export default PasswordReset;
