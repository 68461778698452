import {Route, Switch} from "react-router-dom";
import PostalCode from "../AppWrapper/PostalCode";
import React from "react";
import AuthRoute from "../AppWrapper/routeTypes/AuthRoute";
import SignInForm from "../components/authForms/SignInForm";
import EmailVerification from "../components/authForms/EmailVerification";
import PasswordForgot from "../components/authForms/PasswordForgot";
import PasswordReset from "../components/authForms/PasswordReset";
import PrivateRoute from "../AppWrapper/routeTypes/PrivateRoute";
import {MyProfileEdit, MyProfileView, PasswordChange} from "../components/MyProfile";
import Quotes from "../components/Quotes";
import VehicleClearForm from "../components/vehicleForms/VehicleClearForm";
import FormFour from "../components/vehicleForms/FormFour/FormFor";
import FormThree from "../components/vehicleForms/FormThree";
import FormTwo from "../components/vehicleForms/FormTwo/FormTwo";
import FormOne from "../components/vehicleForms/FormOne";
import ErrorPage from "../views/ErrorPage";

export const AppRoutes = () => {
    return(
        <Switch>
            <AuthRoute exact path='/:partnerId/accounts/signin'>
                <SignInForm />
            </AuthRoute>

            <AuthRoute exact path='/:partnerId/accounts/email/verification/:emailKey'>
                <EmailVerification />
            </AuthRoute>

            {
                /*
                    <AuthRoute exact path='/:partnerId/accounts/signup'>
                        <SignUpForm/>
                    </AuthRoute>
                */
            }

            <AuthRoute exact path='/:partnerId/accounts/password/forgot'>
                <PasswordForgot />
            </AuthRoute>

            <AuthRoute exact path='/:partnerId/accounts/password/reset/:localUID/:localTOKEN'>
                <PasswordReset />
            </AuthRoute>

            <PrivateRoute exact path='/:partnerId/profile/view'>
                <MyProfileView />
            </PrivateRoute>

            <PrivateRoute exact path='/:partnerId/profile/edit'>
                <MyProfileEdit />
            </PrivateRoute>

            <PrivateRoute exact path='/:partnerId/profile/password/change'>
                <PasswordChange />
            </PrivateRoute>

            <PrivateRoute path="/:partnerId/profile/quotes-history" >
                <Quotes />
            </PrivateRoute>

            <Route exact path="/:partnerId/vehicle/form/clear">
                <VehicleClearForm />
            </Route>

            <Route exact path="/:partnerId/quote">
                <FormFour />
            </Route>

            <Route exact path="/:partnerId/info">
                <FormThree />
            </Route>

            <Route exact path="/:partnerId/driver">
                <FormTwo />
            </Route>

            <Route exact path="/:partnerId/">
                <FormOne />
            </Route>

            <Route exact path='/:partnerId/postal'>
                <PostalCode />
            </Route>

            <Route path='*' exact component={() => <ErrorPage />}/>
        </Switch>
    );
}
