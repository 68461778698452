/* View Context Actions */

// view loader
export const viewLoaderActionTypes = {
    UpdateLoader: 'UpdateLoader',
    OpenLoader: 'OpenLoader',
    UpdateMessage: 'UpdateMessage',
    OpenWithoutText: 'OpenWithoutText'
};

// modal
export const viewModalActionTypes = {
    OpenMessageModal: 'OpenMessageModal',
    OpenMessageRedirectModal: 'OpenMessageRedirectModal',
    OpenSignOutModal: 'OpenSignOutModal',
    OpenActionModal: 'OpenActionModal',
    OpenPostalModal: 'OpenPostalModal',
    OpenFinaleModal: 'OpenFinaleModal',
    OpenQuoteRequestModal: 'OpenQuoteRequestModal',
    SubmitAction: 'SubmitAction',
    CloseModal: 'CloseModal',
    ClearModal: 'ClearModal'
};

export const viewSizeActionTypes = {
    UpdateScreenSize: 'UpdateScreenSize',
    UpdateMobile: 'UpdateMobile',
};

export const viewRedirectActionTypes = {
    Redirect: 'Redirect',
    Redirected: 'Redirected',
};

export const viewContentActionTypes = {
    UPDATE_LOADER: "UPDATE_LOADER"
}

export const viewHeaderActionTypes = {
    UpdateDesktopHeader: 'UpdateDesktopHeader',
    UpdateMobileHeader: 'UpdateMobileHeader',
    UpdateVehicleHeader: 'UpdateVehicleHeader',
    UpdateVehicleId: 'UpdateVehicleId',
    CloseAll: 'CloseAll'
};
