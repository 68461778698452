import {MyProfileActionTypes} from "./constants";

export const myProfileReducer = (state, action) => {

    switch (action.type) {
        case MyProfileActionTypes.UPDATE_IS_FIRST:
            return {...state, isFirst: action.payload};
        case MyProfileActionTypes.UPDATE_IS_RENDER:
            return {...state, isRender: action.payload};
        default:
            return state;
    }
};
