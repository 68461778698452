import {quoteDataActionTypes} from "./quoteDataActions";

function updateQuotes(quotes, state, isRated){

    const quoteViewTab = quotes.map((item,index) => ({
        index: index,
        isVisible: index === 0,
    }));

    const newQuotes = {
        quotes:[...quotes],
        quoteTab:[...quoteViewTab],
        isRated: isRated ? isRated : false,
        partnerID: state.partnerID,
    };

    localStorage.setItem(`${state.partnerID}_quoteData`,JSON.stringify(newQuotes));

    return newQuotes;
}

function updateQuoteTab(state, id, value) {

    state.quoteTab[id].isVisible = value;

    localStorage.setItem(`${state.partnerID}_quoteData`,JSON.stringify(state));

    return state;
}

export const quoteDataReducer = (state, action) => {

    switch (action.type) {

        case quoteDataActionTypes.UpdateQuote:
            return updateQuotes(action.payload, state, action.isRated ? action.isRated : false);

        case quoteDataActionTypes.UpdateTab:
            return {...updateQuoteTab(state, action.index, action.payload)};

        case quoteDataActionTypes.ClearQuote:
            return updateQuotes([], state);

        case quoteDataActionTypes.UpdateRate:
            return {...state, isRated: action.payload};

        default:
            return state;

    }
}
