import React from "react";
import {dateFormatFullYear} from "../../../config/dateConfig";
import {
  addYearToDate,
  calculateRangeYear, subMonthFromDate,
} from "../../../helpers/dateHelper";

const VehiclePeriodInput = React.memo((props) => {
    const yearPeriod = calculateRangeYear(new Date(), new Date(props.listedYear)) || 0
    let selectOptions = []
    for (let year = yearPeriod; year >= 0; year--) {
      switch (true) {
        case year === 0:
          selectOptions.push({
            value: subMonthFromDate(new Date(), 10).format(dateFormatFullYear),
            name: 'Less than 1 Year'
          });
          break;
        case year === 1:
          selectOptions.push({value: addYearToDate(props.listedYear, year).format(dateFormatFullYear), name: '1 Year'});
          break;
        default:
          selectOptions.push({
            value: addYearToDate(props.listedYear, year).format(dateFormatFullYear),
            name: `${year} Years`
          })
      }
    }

    function selectHandler(event) {
      props.handleDate(event.target.value)
    }

    return (
      <div className="inputDateSelects">
        <select className='formSelect' onChange={selectHandler} disabled={props.disabled}
                defaultValue={props.defaultValue}>
          <option disabled={true} value=''>Date period</option>
          {
            selectOptions.map((item, index) => (
              <option key={`day${index}`} value={item.value}>{item.name}</option>
            ))
          }
        </select>
      </div>
    )

  }, (prevProps, nextProps) => {
    if (prevProps.listYear === nextProps.listYear || prevProps.defaultValue === nextProps.defaultValue) {
      return false
    } else {
      return true
    }
  }
);


export default VehiclePeriodInput;
