import React, {useCallback, useContext} from "react";
import VehicleDateInput from "../../../../../ui/vehicleFormInputs/VehicleDateInput";
import {MaxDiscountDate, MinDiscountDate} from "../../../constants";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import DiscountDataContext from "../../../../../../contexts/formContexts/DiscountDataContext";
import {discountDataActionTypes} from "../../../../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";

const DriverPolicyStart = () => {

    const {discountData, updateDiscountData} = useContext(DiscountDataContext);

    const handleInput = useCallback((event) => {
       updateDiscountData({type: discountDataActionTypes.UpdatePolicyStart, payload: event.target.value});
    },[]);


    return(
      <>
          <VehicleInputTitleWithToolTip
              id="insuranceStartDate"
              title={"What is the ideal start date for your new \n insurance policy?"}
              description="Select your preferred date for the beginning of your new insurance policy. For instance, you may set the start date for the day that your current insurance expires to ensure that you’re continuously covered. Alternatively, select today's date for a quote or new policy."
          />
          <VehicleDateInput
              className='inputDate'
              type='day'
              name='policy_start'
              defaultValue={discountData.policy_start}
              min={MinDiscountDate}
              max={MaxDiscountDate}
              parentName=''
              handleDate={handleInput}
              disabled={false}
          />
      </>
    );
}

export default DriverPolicyStart;
