import moment from "moment";
import {currentDate} from "../config/dateConfig";
import {returnFullDate} from "./dateHelper";

export default function (defCDate, defMinDate, defMaxDate) {

    let outOfRange = false;
    let errorMessage = '';
    let outOfMin = false;
    let outOfMax = false;

    const cDate = moment(defCDate ? returnFullDate(defCDate) : currentDate).local();

    if (defMinDate && defMinDate.length>6) {
        const minDate =  moment(returnFullDate(defMinDate)).local();
        if(cDate<minDate){
            outOfRange = true;
            outOfMin = true;
            errorMessage = `Minimum date is ${defMinDate}`;
        }
    }
    if (defMaxDate && defMaxDate.length > 6) {
        const maxDate = moment(returnFullDate(defMaxDate)).local();
        if(maxDate<cDate){
            outOfRange = true;
            outOfMax = true;
            errorMessage = `Maximum date is ${defMaxDate}`;
        }
    }

    return {
        errorMessage: errorMessage,
        isError: outOfRange,
        outOfMin: outOfMin,
        outOfMax: outOfMax
    }
}
