import React from "react";
import ReactTooltip from "react-tooltip";

const InputSelect = (props) => {
    return (
        <>
            <div className="formLabelAndTooltipWrapper">
                <h4>{props.item.title}</h4>
                {
                    props.item.toolTip &&
                    <>
                        <div className="tooltipIcon" data-tip={true} data-for={props.item.formName}/>
                        <ReactTooltip id={props.item.formName} className="tooltipWrapper"
                                      arrowColor="transparent">
                            <p className="tooltipText">{props.item.toolTip}</p>
                        </ReactTooltip>
                    </>
                }
            </div>
            <select
                disabled={props.disableFormInput}
                name={props.item.name}
                className="formSelect"
                required defaultValue={props.item.value}
                onChange={props.selectHandler}>
                <option disabled >Select {props.item.title}</option>
                {
                    props.item.switchItems.map((item, key) => {
                        return <option key={`${props.item.name}op${key}`} value={item.value}>{item.name}</option>
                    })
                }
            </select>
        </>
    )
};

export default InputSelect;

