import {useContext, useEffect, useReducer, useState} from "react";

/* network */
import axios from 'axios';
import httpRequest from "../../services/httpRequest";

/* constants */
import {AUTH_API, PROFILE_API, requestStatusTypes} from "../../config/constants";

/* contexts */
import ViewContext from "../../contexts/ViewContext/ViewContext";
import {
    viewLoaderActionTypes,
    viewModalActionTypes, viewRedirectActionTypes
} from "../../contexts/ViewContext/viewContextActions";
import GlobalDataContext from "../../contexts/GlobalDataContext/GlobalDataContext";
import {globalDataActionTypes} from "../../contexts/GlobalDataContext/globalDataActions";

/* locals */
import {DataManagerActionTypes, dataManagerInitialState} from './constants';
import dataManagerReducer from "./dataManagerReducer";
import localStorageService from "../../services/localStorageService";
import {pageConfigActionTypes, PageConfigContext} from "../../contexts/formContexts/PageConfigContext";

export const AuthDataManager = () => {

    const { globalData, updateGlobalData } = useContext(GlobalDataContext);
    const { updateViewModal, updateViewLoader, updateViewRedirect } = useContext(ViewContext);
    const { pageConfig, dispatchPageConfig } = useContext(PageConfigContext);
    const [emailValidationError, setEmailValidationError] = useState('');

    const myLS = new localStorageService();

    const [localState, updateLocalState] = useReducer(dataManagerReducer, {
        ...dataManagerInitialState,
        responseData: {},
        cancelTokenSource: axios.CancelToken.source(),
    });

    function defaultLoadingState() {

        updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: true});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: ''});
    }

    function defaultErrorState(response) {
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: response.message});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: ''});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
        updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
    }

    function defaultSuccessState(response){
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: response.message});
        updateLocalState({type: DataManagerActionTypes.ResponseState, payload: response.data});
        updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Success});
    }

    const signUp = async (data, fromForm) => {
        myLS.clearTokens(globalData.partner.id);

        defaultLoadingState();

        const response = await httpRequest(AUTH_API.POST_SIGN_UP, {
            method: 'post',
            body: JSON.stringify(data)
        }, localState.cancelTokenSource.token);

        if (response) {
            if (response.status === 200 || response.status === 201) {
                /* signup Success */
                if(fromForm){
                    updateViewModal({
                        type: viewModalActionTypes.OpenMessageModal,
                        title: 'We’ll see you soon!',
                        description: 'A confirmation email has been sent to your inbox!\n' +
                            'Please use the same device to click the link in your email to confirm your account.\n' +
                            '\n'
                    });
                }
                else{
                    updateViewModal({
                        type: viewModalActionTypes.OpenMessageModal,
                        title: '',
                        description: 'We have sent you an e-mail activation\n' +
                            'link, please make sure to activate your account\n' +
                            'to see your quotes history.\n' +
                            '\n'
                    });
                    updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}/accounts/signin`});
                }


                defaultSuccessState(response);
            } else {
                defaultErrorState(response);
            }
        }
    };

    const signIn = async (data) => {
        myLS.clearTokens(globalData.partner.id);

        defaultLoadingState();

        const response = await httpRequest(AUTH_API.POST_SIGN_IN, {
            method: 'post',
            body: JSON.stringify(data)
        }, localState.cancelTokenSource.token);

        if (response) {
            if (response.status === 200 || response.status === 201) {

                if (pageConfig.message) {
                    dispatchPageConfig({type: pageConfigActionTypes.ClearPageMessage});
                }
                if (response.data) {
                    if (response.data.user) {
                        updateGlobalData({
                            type: globalDataActionTypes.UpdateUser, data: {...response.data.user}
                        });
                        defaultSuccessState(response);
                        updateGlobalData({type: globalDataActionTypes.UpdateAuth, isAuth: true});
                    }
                }

            } else {
                defaultErrorState(response);
            }
        }
    };

    const refreshSignIn = async () => {

        defaultLoadingState();

        const response = await httpRequest(PROFILE_API.USER, {method: 'get'}, localState.cancelTokenSource.token);
        if (response) {
            if (response.status === 200 || response.status === 201) {
                if (response.data) {
                    updateGlobalData({
                        type: globalDataActionTypes.UpdateUser, data: {...response.data}
                    });
                    updateGlobalData({type: globalDataActionTypes.UpdateAuth, isAuth: true});
                }
                defaultSuccessState(response);
            } else {
                defaultErrorState(response);
            }
        }
    };

    const signOut = async () => {

        defaultLoadingState();

        const response = await httpRequest(AUTH_API.GET_SIGN_OUT, {
            method: 'post',
            body: JSON.stringify({refresh: myLS.getRefreshToken(globalData.partner.id)}, localState.cancelTokenSource.token)
        });
        if (response) {
            if (response.status === 200 || response.status === 201) {
                myLS.clearTokens(globalData.partner.id);
                defaultSuccessState(response);
                updateGlobalData({type: globalDataActionTypes.UpdateAuth, isAuth: false});
            } else {
                myLS.clearTokens(globalData.partner.id);
                defaultErrorState(response);
                updateGlobalData({type: globalDataActionTypes.UpdateAuth, isAuth: false});

            }
        }

    };


    const passwordForgot = async (data) => {
        myLS.clearTokens(globalData.partner.id);

        defaultLoadingState();

        const response = await httpRequest(AUTH_API.POST_FORGOT, {
            method: 'post',
            body: JSON.stringify(data)
        }, localState.cancelTokenSource.token);

        if (response) {

            if (response.status === 200 || response.status === 201) {
                /* forgot Success */
                defaultSuccessState(response);

            } else {
                defaultErrorState(response);
            }
        }
    };

    const passwordReset = async (data) => {
        myLS.clearTokens(globalData.partner.id);

        defaultLoadingState();

        const response = await httpRequest(AUTH_API.POST_RESET, {
            method: 'post',
            body: JSON.stringify(data)
        }, localState.cancelTokenSource.token);
        if (response) {
            if (response.status === 200 || response.status === 201) {
                dispatchPageConfig({
                    type: pageConfigActionTypes.UpdatePageMessage,
                    data: {message: 'Your password had been successfully changed'}
                });
                defaultSuccessState(response);
            } else {
                defaultErrorState(response);
            }
        }
    };

    const emailVerification = async (verificationKey) => {

        myLS.clearTokens(globalData.partner.id);

        defaultLoadingState();

        const response = await httpRequest(AUTH_API.POST_EMAIL_VERIFICATION, {
            method: 'post',
            body: JSON.stringify({key: verificationKey})
        }, localState.cancelTokenSource.token);
        if (response) {
            if (response.status === 200 || response.status === 201) {
                dispatchPageConfig({
                    type: pageConfigActionTypes.UpdatePageMessage,
                    data: {message: 'Your email has been Successfully verified.'}
                });
                defaultSuccessState(response);
            } else {
                defaultErrorState(response);
            }
        }
    };

    const emailValidation = async (email) => {

        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        const response = await httpRequest(AUTH_API.POST_EMAIL_VALIDATION, {
            method: 'post',
            body: JSON.stringify({email: email})
        }, localState.cancelTokenSource.token);
        if (response) {
            if (response.status === 200 || response.status === 201) {
                setEmailValidationError('');
                if (response.data.valid===true){
                    updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Success});
                }
                else {
                    updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
                    setEmailValidationError('Enter a valid email address.');
                }
            }
            else{
                setEmailValidationError(response?.data?.email[0] || response.message);
                updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
            }
        }
    }

    useEffect(() => {
        return () => {
            updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
            localState.cancelTokenSource.cancel();
        }
    }, [localState.cancelTokenSource, updateViewLoader]);

    return ({
        authRequestState: localState.requestState,
        authResponseData: localState.responseData,
        authSuccessMessage: localState.SuccessMessage,
        authRequestError: localState.errorMessage,
        emailValidationError,
        signIn,
        signUp,
        refreshSignIn,
        signOut,
        passwordForgot,
        passwordReset,
        emailVerification,
        emailValidation,
    });
};
