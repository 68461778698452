import React, {useReducer} from "react";



import {
    viewLoaderReducer,
    viewModalReducer,
    viewHeaderReducer,
    viewContentReducer,
    viewSizeReducer, viewRedirectReducer
} from './viewReducer';
import {
    viewLoaderInitialState,
    viewModalInitialState,
    viewContentInitialState,
    viewHeaderInitialState,
    viewSizeInitialState,
    viewRedirectInitialState
} from "./constants";

const ViewContext = React.createContext();


export const ViewContextProvider = (props) => {

    const [viewLoader, updateViewLoader] = useReducer(viewLoaderReducer, viewLoaderInitialState);

    const [viewSize, updateViewSize] = useReducer(viewSizeReducer, viewSizeInitialState);

    const [viewModal, updateViewModal] = useReducer(viewModalReducer, viewModalInitialState);

    const [viewRedirect, updateViewRedirect] = useReducer(viewRedirectReducer, viewRedirectInitialState);

    const [viewHeader, updateViewHeader] = useReducer(viewHeaderReducer, viewHeaderInitialState);

    const [viewContent, updateViewContent] = useReducer(viewContentReducer, viewContentInitialState);


    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    const viewDevice = {
        ios: isIOS,
        isChrome: isChrome,
        android: false,
        desktop: false
    }

    return (
        <ViewContext.Provider value={{
            viewLoader, updateViewLoader,
            viewSize, updateViewSize,
            viewModal, updateViewModal,
            viewRedirect, updateViewRedirect,
            viewHeader, updateViewHeader,
            viewContent, updateViewContent,
            viewDevice
        }}>
            {
                props.children
            }
        </ViewContext.Provider>
    )
};

export default ViewContext;
