import React, {useContext, useEffect, useReducer, useState} from "react";
import isEqual from 'lodash/isEqual';
import {discountDataInitialState, discountDataReducer} from "./discountDataReducer";
import GlobalDataContext from "../../GlobalDataContext";
import {getLocalInitialValue} from "../helper";
import {pageConfigActionTypes, PageConfigContext} from "../PageConfigContext";


const DiscountDataContext = React.createContext();

export const DiscountDataContextProvider = (props) => {

    const {globalData} = useContext(GlobalDataContext);
    const initialDiscountData = getLocalInitialValue('discountData',discountDataInitialState, globalData.partner.id );
    const {dispatchPageConfig} = useContext(PageConfigContext);
    const [oldState, setOldState] = useState(initialDiscountData);
    const [discountData, updateDiscountData] = useReducer(discountDataReducer, initialDiscountData);

    useEffect(() => {
        if (!isEqual(oldState, discountData)) {
            setOldState(discountData);
            dispatchPageConfig({type: pageConfigActionTypes.UpdateQuoteRequest, payload: true});
        }
    }, [discountData]);

    return (
        <DiscountDataContext.Provider value={{
            discountData, updateDiscountData
        }}>
            {
                props.children
            }
        </DiscountDataContext.Provider>
    )
}

export default DiscountDataContext;
