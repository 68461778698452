import moment from "moment";
import {currentDate, dateFormatFullYear} from "../../../config/dateConfig";

export const maxBirthDay = moment(currentDate).local().add(-16, 'year').format(dateFormatFullYear);

export const calculateMaxBirthDay = (licenceAge = 16) => (
    moment(currentDate).local().add(-licenceAge, 'year').format(dateFormatFullYear)
)

export const maxCurrentDate = moment(currentDate).local().format(dateFormatFullYear);

export const MinDiscountDate = moment(new Date()).local().add(1, 'day').format(dateFormatFullYear);
export const MaxDiscountDate = moment(new Date()).local().add(60, 'day').format(dateFormatFullYear);

export const MinTicketDate = moment(new Date()).local().subtract(3, 'year').format(dateFormatFullYear)
