import React, {useContext} from "react";
import {defaultModalStyle} from "../ModalWrapper/constants";
import {viewModalActionTypes} from "../../../../contexts/ViewContext/viewContextActions";
import Modal from "react-modal";
import ViewContext from "../../../../contexts/ViewContext";
const MessageModal = () => {

    const {viewModal, updateViewModal} = useContext(ViewContext);

    return(
        <Modal
            isOpen={true}
            style={defaultModalStyle}
            ariaHideApp={false}
        >
            <div className='viewModalWrapper modal-small'>
                {
                    viewModal.title &&
                    <h3>{viewModal.title}</h3>
                }
                {
                    viewModal.description &&
                    <p>{viewModal.description}</p>
                }
                <div className="viewModalButtonsWrapper">
                    <button className="selectedBtn" onClick={() => updateViewModal({type: viewModalActionTypes.CloseModal})}>OK
                    </button>
                </div>
            </div>
        </Modal>
    );
}
export default MessageModal;
