import React, {useContext} from "react";
import ViewContext from "../../contexts/ViewContext";

export const NavigationCar = () => {
    const {viewSize} = useContext(ViewContext);
    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            height="28px"
            style={{marginBottom: viewSize.isMobile ? '0' : '7px'}}
            viewBox="0 0 34.9 27"
        >
            <g fill="var(--primary-color)">
                <path d="M33.5,10l-0.8-0.8L30.1,2c-0.5-1.2-1.6-2-2.9-2h-19C6.8,0,5.7,0.8,5.2,2L2.6,9.1l-1.1,1c-1,0.8-1.5,2-1.5,3.3
		v10.5C0,25.6,1.4,27,3.1,27h2c1.7,0,3.1-1.4,3.1-3.1v-1.5h18.5v1.5c0,1.7,1.4,3.1,3.1,3.1h2c1.7,0,3.1-1.4,3.1-3.1V13.1
		C34.9,11.9,34.4,10.8,33.5,10z M6.6,2.5c0.2-0.6,0.8-1,1.5-1h19.1c0.7,0,1.3,0.4,1.5,1L31,8.8h-1.7c-0.4-2.8-3-4.7-5.8-4.3
		c-2.2,0.3-4,2.1-4.3,4.3H4.3L6.6,2.5z M27.8,8.8L27.8,8.8h-7.1C21.1,6.8,23,5.6,25,6C26.4,6.3,27.5,7.4,27.8,8.8z M6.7,23.9
		c0,0.9-0.7,1.6-1.6,1.6h-2c-0.9,0-1.6-0.7-1.6-1.6v-2c0.5,0.3,1.1,0.4,1.6,0.4h3.6V23.9z M33.4,23.9c0,0.9-0.7,1.6-1.6,1.6h-2
		c-0.9,0-1.6-0.7-1.6-1.6v-1.5h3.6c0.6,0,1.1-0.1,1.6-0.4L33.4,23.9z M31.7,20.9L31.7,20.9l-28.6,0c-0.9,0-1.6-0.7-1.6-1.6
		c0,0,0,0,0,0v-5.9c0-0.8,0.4-1.6,1-2.1l0,0l1-1h28.2l0.7,0.8c0,0,0,0,0,0c0.6,0.5,0.9,1.3,0.9,2v6.1h0
		C33.4,20.1,32.6,20.9,31.7,20.9z"/>
                <path d="M9.8,13.3H4.2c-0.4,0-0.7,0.3-0.7,0.7v3.9c0,0.4,0.3,0.7,0.7,0.7h5.6c0.4,0,0.7-0.3,0.7-0.7V14
		C10.6,13.6,10.2,13.3,9.8,13.3z M9.1,17.1H5v-2.4h4.1V17.1z"/>
                <path d="M30.6,13.3H25c-0.4,0-0.7,0.3-0.7,0.7v3.9c0,0.4,0.3,0.7,0.7,0.7h5.6c0.4,0,0.7-0.3,0.7-0.7V14
		C31.4,13.6,31,13.3,30.6,13.3z M29.9,17.1h-4.1v-2.4h4.1V17.1z"/>
                <path d="M21,16.4C21,16.4,21,16.4,21,16.4h-7.2c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7H21
		c0.4,0,0.7-0.3,0.7-0.7C21.8,16.7,21.4,16.4,21,16.4z"/>
                <path d="M13.8,15.6H21c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-7.2c-0.4,0-0.7,0.3-0.7,0.7S13.4,15.6,13.8,15.6z"/>
            </g>
        </svg>
    );
}
