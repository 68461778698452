import React, {useContext, useMemo} from 'react';
import {Link, useParams} from 'react-router-dom';
import GlobalDataContext from "../../contexts/GlobalDataContext";
import {formatPhoneObject} from "../../helpers/formatUtils";

const QuoteItem = React.memo((props) => {

    const {quoteID} = useParams();
    const {globalData} = useContext(GlobalDataContext);

    const localID = useMemo(()=> {
        return Number(quoteID);
    },[quoteID]);

    function returnMaritalStatusName(value){
      if (value === 'S') {
        return 'Single';
      } else if (value === 'M'){
        return 'Married/Common Law'
      }
    }

    return (
        <div  className="addVehiclesFormContentWrapper">
          {
            props.quoteItems.filter((filterItem) => filterItem.id === localID).map((item,index)=>{return(
            <div className="quoteWrapper" key={`singleQuote${index}`}>
                <div className="quoteText">
                    <h3>{item.name}</h3>
                    <h3><Link to={`/${globalData.partner.id}/profile/quotes-history`}>Back</Link></h3>
                </div>
                <div className="categoryContainer">
                    <div className="quoteTitle">
                        <h3>Vehicle</h3>
                    </div>
                    <div className="singleQuoteContainer">
                      {item.vehicles.map((singleVehicleItem, singleVehicleIndex) => {
                        return (
                        <div className="eachQuote" key={`singleVehicle${singleVehicleIndex}`}>
                            <div>
                                <h3 className="title">{singleVehicleItem.make}, {singleVehicleItem.model}</h3>
                                <div className="quoteTextContainer">
                                    <div className="quoteText">
                                        <p className="quoteType">Car Year</p>
                                        <p className="quoteDescription">{singleVehicleItem.year}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Car Make</p>
                                        <p className="quoteDescription">{singleVehicleItem.make}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Company</p>
                                        <p className="quoteDescription">{item.company.name}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Yearly $</p>
                                        <p className="quoteDescription">${singleVehicleItem.price_yearly}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Montly $</p>
                                        <p className="quoteDescription">${singleVehicleItem.price_monthly}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Date</p>
                                        <p className="quoteDescription">{item.date}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                      )
                      })
                      }

                    </div>
                    <hr/>
                </div>
                <div className="categoryContainer">
                    <div className="quoteTitle">
                        <h3>Drivers</h3>
                    </div>
                    <div className="singleQuoteContainer">
                      {item.drivers.map((singleDriverItem, singleDriverIndex) => {
                        return (
                        <div className="eachQuote" key={`singleDriver${singleDriverIndex}`}>
                            <div>
                                <h3 className="title">Driver {singleDriverIndex + 1}</h3>
                                <div className="quoteTextContainer">
                                    <div className="quoteText">
                                        <p className="quoteType">Full name</p>
                                        <p className="quoteDescription">{singleDriverItem.first_name} {singleDriverItem.last_name}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Marital Status</p>
                                        <p className="quoteDescription">{returnMaritalStatusName(singleDriverItem.marital_status)}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Date of birth</p>
                                        <p className="quoteDescription">{singleDriverItem.date_of_birth}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Occupation</p>
                                        <p className="quoteDescription">{singleDriverItem.occupation}</p>
                                    </div>
                                    <div className="quoteText">
                                        <p className="quoteType">Email</p>
                                        <p className="quoteDescription">{item.email_to}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                      })
                      }

                    </div>
                    <hr/>
                </div>
                <div className="categoryContainer">
                    <div className="quoteTitle">
                        <h3>Insurance</h3>
                    </div>
                    <div className="singleQuoteContainer">
                        <div className="eachQuote ">
                            <div className="insuranceContainer">
                                <div className="quoteText bestOfferWrapper">
                                    <h2 className="insurancetext">{item.company.name}</h2>
                                    <p className="bestOffer">Best Offer</p>
                                </div>
                                <div className="offersSecondRow">
                                    <img src={item.company.logo} className="companyLogo" alt="logo"/>
                                </div>
                                <div>
                                    <h1 className="insuranceMonthlyPrice">${item.price_monthly} <span>/ month</span></h1>
                                    <h3 className="insuranceText">${item.price_yearly} / year</h3>
                                </div>
                              <div className="offersFourthRow">
                                { item.broker_profile.logo &&
                                  <img src={item.broker_profile.logo} className="brokerLogo" alt="logo"/>
                                }
                                <h3>Brokered by {item.broker_profile.title} Insurance</h3>
                                <p>{item.broker_profile.description}</p>
                                <h3>Call now to get this rate</h3>
                                <a href={`tel:${formatPhoneObject(item.broker_profile.phone).phoneNumber}`} className="phoneNumber">{item.broker_profile.phone}</a>
                                <h3>Hours of Operation</h3>
                                <h5>M - F
                                  : <span>{item.broker_profile.hours_workdays ? item.broker_profile.hours_workdays : 'Closed'}</span>
                                </h5>
                                <h5>Sa
                                  : <span>{item.broker_profile.hours_saturday ? item.broker_profile.hours_saturday : 'Closed'}</span>
                                </h5>
                                <h5>Su
                                  : <span>{item.broker_profile.hours_sunday ? item.broker_profile.hours_sunday : 'Closed'}</span>
                                </h5>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )})
          }
        </div>
    )
}, (prevProps, nextProps) => {
        if (prevProps.quoteItems.count !== nextProps.quoteItems.count) {
            return false
        } else {
            return true
        }
    }
);

export default QuoteItem;
