import {useContext, useEffect, useReducer} from "react";

import axios from "axios";
import httpRequest from "../../services/httpRequest";

/* constants */
import {requestStatusTypes, QUOTE_FORM_API} from "../../config/constants";
import {formLicenceTypes} from "../../config/formConstants";


// contexts
import GlobalDataContext from "../../contexts/GlobalDataContext";
import ViewContext from "../../contexts/ViewContext/ViewContext";
import {
    viewLoaderActionTypes,
    viewRedirectActionTypes
} from "../../contexts/ViewContext/viewContextActions";
import {pageConfigActionTypes, PageConfigContext} from "../../contexts/formContexts/PageConfigContext";

import DriverDataContext from "../../contexts/formContexts/DriverDataContext";
import VehicleDataContext from "../../contexts/formContexts/VehicleDataContext";
import DiscountDataContext from "../../contexts/formContexts/DiscountDataContext";
import QuoteDataContext from "../../contexts/formContexts/QuoteDataContext";
import {quoteDataActionTypes} from "../../contexts/formContexts/QuoteDataContext/quoteDataActions";


//validations
import StepThreeValidationManager from "../ValidationManagers/StepThreeValidationManager";

/* locals */
import dataManagerReducer from "./dataManagerReducer";
import {DataManagerActionTypes, dataManagerInitialState} from "./constants";
import {AuthDataManager} from "./AuthDataManager";
import UseClearForm from "../../helpers/UseClearForm";
import {myChoicePartnerId} from "../../config/partnersConfig";

export const QuoteDataManager = () => {

    const { stepThreeValidationPartOne, stepThreeValidationPartTwo } = StepThreeValidationManager();


    const { updateViewLoader, updateViewRedirect } = useContext(ViewContext);
    const { pageConfig } = useContext(PageConfigContext);

    const { dispatchPageConfig } = useContext(PageConfigContext);

    const { quoteData,updateQuoteData } = useContext(QuoteDataContext);

    const { signOut } = AuthDataManager();

    const { checkIsExpired, checkIsExpiredWithModal } = UseClearForm();


    const { vehicleData } = useContext(VehicleDataContext);
    const { driverData } = useContext(DriverDataContext);
    const { discountData } = useContext(DiscountDataContext);
    const { globalData } = useContext(GlobalDataContext);

    const [localState, updateLocalState] = useReducer(dataManagerReducer, {
        ...dataManagerInitialState,
        responseData: [],
        cancelTokenSource: axios.CancelToken.source()

    });

    function defaultLoadingState(isLoader, loaderDescription) {
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        if(isLoader){
            updateViewLoader({type: viewLoaderActionTypes.OpenLoader, payload: true, message: loaderDescription ? loaderDescription : 'Please wait while we fetch your lowest rates from our insurance partners.'});
        }
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
    }

    function defaultErrorState(response) {
        if(response.action===requestStatusTypes.UserSignOut){
            signOut();
        }
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: response.message});
        updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
    }


    const constructRequestBody = async (isQuoter, isRate, isRecalc) => {

        let localDrivers = [];
        let localVehicles = [];
        let localDiscount = {...discountData};
        let localVehlinks = [];

        localDiscount.quoter_info = {...discountData.quoter_info};
        localDiscount.quoter_info.recalculate = !pageConfig.isQuoteRequested ? false : isRecalc ? isRecalc : !pageConfig.quoteRequest;

        if (localDiscount.province_code !== globalData.postal.province_code) {
            localDiscount.province_code = globalData.postal.province_code;
            localDiscount.province_name = globalData.postal.province_name;
        }

        if (localDiscount.quoter_info.utm_source === '') {
            if ( localDiscount.partnerID !== myChoicePartnerId) {
                localDiscount.quoter_info.utm_source = localDiscount.partnerID;
            }
            else {
                delete localDiscount.quoter_info.utm_source;
            }
        }

        if (localDiscount.quoter_info.utm_campaign === '') {
            delete localDiscount.quoter_info.utm_campaign;
        }

        localDiscount.vehLink = {...discountData.vehLink};
        localDiscount.alert = {...discountData.alert};

        if(!localDiscount.isAlert || !isQuoter){
            delete localDiscount.alert;
            delete localDiscount.isAlert;
        }
        else{
            delete localDiscount.isAlert;
        }

        if(localDiscount.newUser) {
            delete localDiscount.newUser;
        }

        if(!isRate){
            delete localDiscount.quoter_info;
        }
        else
            {
            if (localDiscount.quoter_info.driver_license.length < 1) {
                delete localDiscount.quoter_info.driver_license;
            }
        }

        if (localDiscount.partnerID) {
            delete localDiscount.partnerID;
        }

        // driver

        for(let driverIndex=0; driverIndex<driverData.items.length;driverIndex++){
            let newDriver = {...driverData.items[driverIndex]};

            newDriver.licence_info = {...driverData.items[driverIndex].licence_info};

            if (newDriver.minDates) {
                delete newDriver.minDates;
            }

            if(newDriver.applicant_relationship===''){
                delete newDriver.applicant_relationship;
            }

            if(newDriver.gender==='other'){
                newDriver.gender = 'M';
            }

            if(!newDriver.listed){
                delete newDriver.listed_year;
                newDriver.insured = false;
            }
            if(!newDriver.insured){
                delete newDriver.insured_year;
            }
            // licence
            if(newDriver.licence_info.isOnlyG){
                delete newDriver.licence_info.g1_licence_date;
                delete newDriver.licence_info.g2_licence_date;
                delete newDriver.licence_info.passed_driver_training;
            }
            else{
                if(newDriver.licence_info.licence_type===formLicenceTypes.gOne){
                    delete newDriver.licence_info.g2_licence_date;
                    delete newDriver.licence_info.g_licence_date;
                }
                if(newDriver.licence_info.licence_type===formLicenceTypes.gTwo){
                    delete newDriver.licence_info.g_licence_date;
                }
                else{
                    delete newDriver.licence_info.passed_driver_training;
                }
            }
            delete newDriver.licence_info.isOnlyG;

            // lists

            if(!newDriver.insurance_cancellation){
                delete newDriver.insurance_cancellation_list;
            }
            else{
                newDriver.insurance_cancellation_list = [];
                for(let iX=0;iX<driverData.items[driverIndex].insurance_cancellation_list.length;iX++){
                    newDriver.insurance_cancellation_list.push({
                        reason:driverData.items[driverIndex].insurance_cancellation_list[iX].reason,
                        start_date:driverData.items[driverIndex].insurance_cancellation_list[iX].start_date,
                        end_date:driverData.items[driverIndex].insurance_cancellation_list[iX].end_date
                    })
                }

            }
            if(!newDriver.licence_suspension){
                delete newDriver.licence_suspension_list;
            }
            else{
                newDriver.licence_suspension_list = [];
                for(let sX=0;sX<driverData.items[driverIndex].licence_suspension_list.length;sX++){
                    newDriver.licence_suspension_list.push({
                        reason:driverData.items[driverIndex].licence_suspension_list[sX].reason,
                        reinstatement_date:driverData.items[driverIndex].licence_suspension_list[sX].reinstatement_date,
                        suspension_date:driverData.items[driverIndex].licence_suspension_list[sX].suspension_date
                    })
                }
            }
            if(!newDriver.accident){
                delete newDriver.accident_list;
            }
            else{
                newDriver.accident_list = [];
                for(let aX=0;aX<driverData.items[driverIndex].accident_list.length;aX++){
                    newDriver.accident_list.push({
                        accident_date:driverData.items[driverIndex].accident_list[aX].accident_date
                    });
                }
            }
            if(!newDriver.ticket){
                delete newDriver.ticket_list;
            }
            else{
                newDriver.ticket_list = [];
                for(let tX=0;tX<driverData.items[driverIndex].ticket_list.length;tX++){
                    newDriver.ticket_list.push({
                        reason:driverData.items[driverIndex].ticket_list[tX].reason,
                        ticket_date:driverData.items[driverIndex].ticket_list[tX].ticket_date
                    });
                }
            }

            localDrivers.push(newDriver);
        }



        //vehicle

        for(let vehicleIndex=0; vehicleIndex<vehicleData.items.length; vehicleIndex++){
            let newVehicle = {...vehicleData.items[vehicleIndex], ...globalData.postal};

            //vehlinks
            for(let drX=0; drX<localDrivers.length; drX++){
                let forVehLink = {
                    driver_index: Number(drX+1),
                    vehicle_index: Number(vehicleIndex+1),
                    priority: localDiscount.vehLink[vehicleIndex].driver_index===drX ? 'Prn' : 'Occ'
                };
                localVehlinks.push(forVehLink);
            }

            if(newVehicle.primary_use === 'personal'){
                delete newVehicle.distance_business;
            }
            newVehicle.comprehensive = {...vehicleData.items[vehicleIndex].comprehensive}
            newVehicle.collision = {...vehicleData.items[vehicleIndex].collision}
            if(!newVehicle.collision.coverage){
                newVehicle.collision.deductible = 1000;
            }
            if(newVehicle.collision.deductible === 0){
                newVehicle.collision.coverage = false;
                newVehicle.collision.deductible = 1000;
            }
            if(!newVehicle.comprehensive.coverage){
                newVehicle.comprehensive.deductible = 1000;
            }
            if(newVehicle.comprehensive.deductible === 0){
                newVehicle.comprehensive.coverage = false;
                newVehicle.comprehensive.deductible = 1000;
            }

            localVehicles.push(newVehicle);
        }

        delete localDiscount.vehLink;

        return {
            drivers: [...localDrivers],
            vehicles: [...localVehicles],
            ...localDiscount,
            vehlinks: [...localVehlinks]
        }
    }


    const postRequestQuote = async (isQuoter, noRedirect, isLoader, isRate, isRecalc) => {

        let checked = false;
        let errorMessage = '';
        updateLocalState({type: DataManagerActionTypes.ClearErrorMessage});

        if(isQuoter){
            errorMessage = stepThreeValidationPartTwo();
            if(errorMessage){
                setTimeout(() => {
                    updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: errorMessage});
                },50);
            }
            else{
                checked = true;
            }
        }
        else {
            if(!stepThreeValidationPartOne()) {
                updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: 'Please enter your email'});
            }
            else{
                checked = true;
            }
        }


        if (checkIsExpired()) {
            checkIsExpiredWithModal();
            checked = false;
        }

        if(checked) {

            (isRate && !isRecalc) ?
                defaultLoadingState(isLoader,'Please note: As insurance premiums are based on the information you provide it is important that it be 100% accurate and up to date. If you are not sure it is best to check and edit your information in the next step.')
                : defaultLoadingState(isLoader);

            const requestBody = await constructRequestBody(isQuoter, isRate, pageConfig.isQuoteRequested);

            if (requestBody) {

                const response = await httpRequest(QUOTE_FORM_API.POST_QUOTE_DATA, {
                    method: 'post',
                    body: JSON.stringify(requestBody)
                }, localState.cancelTokenSource.token);

                dispatchPageConfig({type: pageConfigActionTypes.UpdateQuoteRequest, payload: false});

                if (response) {
                    if (response.status === 200 || response.status === 201) {
                        if(!pageConfig.isQuoteRequested){
                            dispatchPageConfig({type: pageConfigActionTypes.UpdateIsQuoteRequested, payload: true});
                            dispatchPageConfig({type: pageConfigActionTypes.UpdateRequestDate, payload: new Date().toISOString()});
                        }
                        updateLocalState({
                            type: DataManagerActionTypes.UpdateQuoteResponse,
                            payload: [...response.data]
                        });
                        if (response.data.length > 0) {
                            updateQuoteData({type: quoteDataActionTypes.UpdateQuote, payload: [...response.data], isRated: isRate});
                        } else {
                            updateQuoteData({type: quoteDataActionTypes.UpdateQuote, payload: [], isRated: isRate});
                        }
                        updateLocalState({
                            type: DataManagerActionTypes.RequestState,
                            payload: requestStatusTypes.Success
                        });
                        updateLocalState({
                            type: DataManagerActionTypes.UpdateSuccessMessage,
                            payload: 'REQUEST_QUOTE_Success'
                        });
                        if(isQuoter){
                            window.scrollTo(0,0);
                        }
                        updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
                        if(isRate && !isRecalc) {
                            if(quoteData.quotes.length){
                                if(!quoteData.quotes.length){
                                    updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: 'Network error'});
                                    updateQuoteData({type: quoteDataActionTypes.UpdateRate, payload: false});
                                }
                            }
                            else{
                                updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: 'Network error'});
                                updateQuoteData({type: quoteDataActionTypes.UpdateRate, payload: false});
                            }
                        }
                    } else {
                        defaultErrorState(response);
                    }
                }

            }
        }
        else{
            if(!isQuoter && !noRedirect) {
                updateViewRedirect({type: viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}/info`});
            }
        }

    };

    //special case for Onlia
  const postRequestQuoteOnliaUrl = async (isLoader, redirectUrl) => {

        defaultLoadingState(isLoader,'Please wait for getting redirect url.')

      const requestBody = await constructRequestBody(true, true, false);

      if (requestBody) {

        const response = await httpRequest(QUOTE_FORM_API.POST_QUOTE_ONLIA_URL, {
          method: 'post',
          body: JSON.stringify(requestBody)
        }, localState.cancelTokenSource.token);

        if (response) {
          if (response.status === 200 || response.status === 201) {
            const url = response.data.redirect_url? response.data.redirect_url: redirectUrl
              window.location.href=url;
            updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
          } else {
            defaultErrorState(response);
          }
        }
      }
  };

    const getQuotes = async () =>{

        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        updateViewLoader({type: viewLoaderActionTypes.OpenLoader, payload: true, message: ''});


        const response = await httpRequest(QUOTE_FORM_API.GET_QUOTE_DATA, {
            method: 'get',
            body: JSON.stringify({})
        }, localState.cancelTokenSource.token);

        if(response){
            if (response.status === 200 || response.status === 201) {
                updateLocalState({
                    type: DataManagerActionTypes.UpdateQuoteResponse,
                    payload: [...response.data]
                });
                updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Success});
            }
            else{
                defaultErrorState(response);
            }
        }
    }

    useEffect(() => {
        return () => {
            updateViewLoader({type: viewLoaderActionTypes.UpdateLoader, payload: false});
            localState.cancelTokenSource.cancel();
        }
    }, [localState.cancelTokenSource, updateViewLoader]);

    return ({
        quoteRequestState: localState.requestState,
        quoteResponseData: localState.responseData,
        quoteSuccessMessage: localState.SuccessMessage,
        quoteErrorMessage: localState.errorMessage,
        postRequestQuote,
        postRequestQuoteOnliaUrl,
        getQuotes
    });
};
