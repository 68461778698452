import React from "react";

const InputHiddenField = (props) => {

    return (
        <input
            className="formInputHidden"
            type={props.item.type}
            name={props.item.formName}
            autoComplete={props.item.autoComplete}
        />
    );
};
export default InputHiddenField;
