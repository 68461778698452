import React, {useReducer} from "react";
import {globalDataReducer} from "./globalDataReducer";
import {myChoicePartnerId} from "../../config/partnersConfig";
import myChoiceLogotype from '../../resources/images/logotype-mychoice-primary-2x.png'

const GlobalDataContext = React.createContext();


export const myChoicePartnerItem = {
    id: myChoicePartnerId.toString(),
    logotype: myChoiceLogotype,
    logotypeLink: 'https://www.mychoice.ca/',
    primaryActiveColor: '#F9733E',
    primarySecondaryColor: '#4ABDAC',
}


export const globalDataInitialState = {
    uid: '',
    id: -1,
    first_name: '',
    last_name: '',
    email: '',
    isAuth: false,
    signedInRedirect: '',
    isPostal: false,
    postal:{},
    postalError: '',
    partner: { ...myChoicePartnerItem },
};


export const GlobalDataContextProvider = (props) => {

    const [globalData, updateGlobalData] = useReducer(globalDataReducer, globalDataInitialState);

    return (
        <GlobalDataContext.Provider value={{
            globalData,
            updateGlobalData
        }}>{
            props.children
        }
        </GlobalDataContext.Provider>
    );

};

export default GlobalDataContext;
