import {useContext, useEffect, useReducer} from "react";

/* network */
import axios from 'axios';
import httpRequest from "../../services/httpRequest";

/* constants */
import {PROFILE_API, requestStatusTypes} from "../../config/constants";

/* contexts */
import ViewContext from "../../contexts/ViewContext/ViewContext";
import {
    viewLoaderActionTypes,
} from "../../contexts/ViewContext/viewContextActions";
import GlobalDataContext from "../../contexts/GlobalDataContext/GlobalDataContext";
import {globalDataActionTypes} from "../../contexts/GlobalDataContext/globalDataActions";

/* locals */
import {DataManagerActionTypes, dataManagerInitialState} from './constants';
import dataManagerReducer from "./dataManagerReducer";

export const AuthRefreshDataManager = () => {

    const { updateGlobalData } = useContext(GlobalDataContext);
    const { updateViewLoader } = useContext(ViewContext);

    const [localState, updateLocalState] = useReducer(dataManagerReducer, {
        ...dataManagerInitialState,
        responseData: {},
        cancelTokenSource: axios.CancelToken.source(),
    });

    function defaultLoadingState() {

        updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: true});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Loading});
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: ''});
    }

    function defaultErrorState(response) {
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: response.message});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: ''});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Error});
        updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
    }

    function defaultSuccessState(response){
        updateLocalState({type: DataManagerActionTypes.UpdateErrorMessage, payload: ''});
        updateLocalState({type: DataManagerActionTypes.UpdateSuccessMessage, payload: response.message});
        updateLocalState({type: DataManagerActionTypes.ResponseState, payload: response.data});
        updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
        updateLocalState({type: DataManagerActionTypes.RequestState, payload: requestStatusTypes.Success});
    }

    const refreshSignIn = async () => {

        defaultLoadingState();

        const response = await httpRequest(PROFILE_API.USER, {method: 'get'}, localState.cancelTokenSource.token);
        if (response) {
            if (response.status === 200 || response.status === 201) {
                if (response.data) {
                    updateGlobalData({
                        type: globalDataActionTypes.UpdateUser, data: {...response.data}
                    });
                    updateGlobalData({type: globalDataActionTypes.UpdateAuth, isAuth: true});
                }
                defaultSuccessState(response);
            } else {
                defaultErrorState(response);
            }
        }
    };

    useEffect(() => {
        return () => {
            updateViewLoader({type:viewLoaderActionTypes.UpdateLoader, payload: false});
            localState.cancelTokenSource.cancel();
        }
    }, [localState.cancelTokenSource, updateViewLoader]);

    return ({
        authRequestState: localState.requestState,
        authResponseData: localState.responseData,
        authSuccessMessage: localState.SuccessMessage,
        authRequestError: localState.errorMessage,
        refreshSignIn,
    });
};
