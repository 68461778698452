import React, {useContext} from "react";
import FormTabWrapper from "../../ui/FormTab";
import FormOneWrapper from "./FormOneWrapper";
import MyChoicePartnerLogo from "../../../resources/images/logotype-mychoice-partner.png";
import {myChoicePartnerId} from "../../../config/partnersConfig";
import GlobalDataContext from "../../../contexts/GlobalDataContext";

const FormOne = () => {
  const {globalData} = useContext(GlobalDataContext);

  return (
    <div className="add-vehicles-content-wrapper">
      <div className="addVehiclesFormContentWrapper">
        <div className="controlAndTabsWrapper">
          <FormTabWrapper isVehicle={true} isDriver={false} isRecalculate={false}/>
          <FormOneWrapper/>
        </div>
        {
          globalData.partner.id !== myChoicePartnerId &&
          <img className='logo-for-partner' src={MyChoicePartnerLogo} alt='partner logo'/>
        }
      </div>
    </div>
  );
}
export default FormOne;
