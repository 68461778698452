export const UseFormActionTypes = {
    ERROR_MESSAGE: 'ERROR_MESSAGE',
    SUBMIT_MESSAGE: 'SUBMIT_MESSAGE',
    DISABLE_FORM_INPUT: 'DISABLE_FORM_INPUT',
    DISABLE_SUBMIT_BUTTON: 'DISABLE_SUBMIT_BUTTON'
};

export const UseFormInputTypes = {
    NAME: 'NAME',
    FULL_NAME: 'FULL_NAME',
    EMAIL: 'EMAIL',
    PASSWORD: 'PASSWORD',
    PASSWORD_CONFIRM: 'PASSWORD_CONFIRM',
    PASSWORD_CURRENT: 'PASSWORD_CURRENT',
    NO_VALIDATION: 'NO_VALIDATION',
    SELECT: 'SELECT',
    SWITCH: 'SWITCH',
    DATE: 'DATE'
};
