import {pageConfigActionTypes} from "./pageConfigActions";
import {pageConfigInitialState} from "./initialState";

const setLocalPageConfig = (state) => {
    localStorage.setItem(`${state.partnerID}_pageConfigState`, JSON.stringify(state));
    return state;
}

export const pageConfigReducer = (state, action) => {

    switch (action.type) {

        case pageConfigActionTypes.UpdatePageMessage:
            return setLocalPageConfig({...state, message: action.data.message});

        case pageConfigActionTypes.ClearPageMessage:
            return setLocalPageConfig({...state, message: ''});

        case pageConfigActionTypes.UpdateQuoteRequest:
            return setLocalPageConfig({...state, quoteRequest: action.payload});

        case pageConfigActionTypes.UpdateIsQuoteRequested:
            return setLocalPageConfig({...state, isQuoteRequested: action.payload});

        case pageConfigActionTypes.UpdateRequestDate:
            return setLocalPageConfig({...state, requestDate: action.payload});

        case pageConfigActionTypes.ClearViewPage:
            return setLocalPageConfig({...state, ...pageConfigInitialState, partnerID: state.partnerID,});

        default:
            return {state}
    }
};
