export const formStateActionTypes = {
    Update: 'Update',
    UpdateVariable: 'UpdateVariable',
    UpdateNestedVariable: 'UpdateNestedVariable',
    Clear: 'Clear'
}

export const formInitialState = {};

export const formStateReducer = (state, action) => {
    let newState = state;
    switch (action.type) {

        case formStateActionTypes.Update:
            return action.payload;

        case formStateActionTypes.UpdateVariable:
            newState[action.name] = action.payload;
            return {...newState};

        case formStateActionTypes.UpdateNestedVariable:
            newState[action.rootName] = {...newState[action.rootName],[action.name]:action.payload};
            return {...newState};


        case formStateActionTypes.Clear:
            return {};

        default:
            return state;
    }
}
