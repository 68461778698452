import axios from 'axios';

import {
    responseLogoutState,
    responseNetworkErrorState,
    responseCancelState
} from '../config/constants';

const httpRequest = async (path, data, cancelToken) => {
    return axios({url: path, cancelToken: cancelToken, method: data.method, data: data.body})
        .then(res => {
            if (!res) {
                return responseLogoutState;
            }
            return res;
        })
        .catch(err => {
            if (err) {
                if (axios.isCancel(err)) {
                    return responseCancelState;
                } else {
                    return err.response;
                }
            } else {
                return responseNetworkErrorState;
            }
        });

};

function defaultResponse(response) {

    let message = '';

    if (typeof response.data.message === "string") {
        message = response.data.message;
    } else {
        if (typeof response.data.detail === "string") {
            message = response.data.detail;
        }
    }

    return {
        status: response.status,
        data: response.data,
        message: message
    }
}

function tryToGetErrorMessage(response) {

    let message = 'Something wrong';

    if (typeof response.data.message === 'string') {
        message = response.data.message;
    } else {

        if (typeof response.data.errorMessage !== 'string') {
            for (let k in response.data) {
                if (message === 'Something wrong') {
                    message = `${k} / ${response.data[k][0]}`;
                }
            }
        } else {
            message = response.data.errorMessage;
        }
    }
    return {
        status: response.status,
        data: response.data,
        message: message
    };
}

export default async (path, data, cancelToken) => {

    try {

        const response = await httpRequest(path, data, cancelToken ? cancelToken : false);

        if (response) {
            if (response.status) {
                switch (response.status) {
                    case 200:
                        return defaultResponse(response);
                    case 201:
                        return defaultResponse(response);
                    case 400:
                        return tryToGetErrorMessage(response);
                    case 403:
                        return tryToGetErrorMessage(response);
                    case 404:
                        return tryToGetErrorMessage(response);
                    case 401:
                        return responseLogoutState;
                    case 499:
                        return null;
                    default:
                        return responseNetworkErrorState;
                }
            } else {
                return responseNetworkErrorState;
            }
        } else {
            return responseNetworkErrorState;
        }
    } catch (e) {
        return responseNetworkErrorState;
    }
};
