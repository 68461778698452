import cloneDeep from 'lodash.clonedeep';
import {discountDataActionTypes} from "./discountDataActionTypes";
import moment from "moment";
import {currentDate, dateFormatFullYear} from "../../../config/dateConfig";
import {formGenderTypes} from "../../../config/formConstants";

export const discountQuoterInfoInitialState = {
    first_name: "",
    last_name: "",
    phone: "",
    driver_license: "",
    recalculate: false,
    utm_source: "",
    utm_campaign: "",
}

export const discountDataInitialState = {
    app_install_discount: false,
    caa_member_discount: false,
    email_to: "",
    multiple_policies_discount: true,
    policy_start: moment(currentDate).local().add(1,'day').format(dateFormatFullYear),
    quoter_info: {...discountQuoterInfoInitialState},
    isAlert:false,
    alert:{
        current_company: '',
        current_price_monthly: ''
    },
    vehLink:[{
        driver_index:0,
        priority: 'Prn',
        vehicle_index: 0
    }],
    newUser:{
        first_name: '',
        last_name: '',
        dob: '',
        occupation: 'Employed',
        marital_status: 'S',
        gender: 'Male',
        email: '',
        password1: '',
        password2: ''
    }
}

function updateLocalDiscount(state) {
    localStorage.setItem(`${state.partnerID}_discountData`, JSON.stringify(state));
}

function updateAllDiscount(newState){
    updateLocalDiscount(newState);
    return newState;
}

function updateFieldsInObjects (state, fieldName, fieldValue, objects) {
    let newState = {...state};
    for (const objetName of objects) {
        newState[objetName][fieldName] = fieldValue;
    }
    return updateAllDiscount(newState);
}

function removeVehlink(state, id){
    let newState = {...state};
    let newVehlink = [...state.vehLink.filter(item=>item.vehicle_index!==id)];
    newState.vehLink =[]
    for(let zV=0;zV<newVehlink.length;zV++){
        newState.vehLink.push({...newVehlink[zV], vehicle_index: zV});
    }
    updateLocalDiscount(newState);
    return newState;
}

function removeDriverFromVehlink(state, id){
    let newState = {...state};
    let newVehlink = state.vehLink.map((item) => {
        if(item.driver_index === id) {
            return {
                ...item,
                driver_index: 0,
            }
        }
        return item;
    });
    newState.vehLink = [ ...newVehlink];
    updateLocalDiscount(newState);
    return newState;
}

function addVehlink(state){
    let newState = {...state};
    newState.vehLink.push({
        driver_index: 0,
        priority: 'Prn',
        vehicle_index: state.vehLink.length
    })
    updateLocalDiscount(newState);
    return newState;
}


function returnNewDiscount(state, name, value){
    const newState = {...state, [name]: value};
    updateLocalDiscount(newState);
    return newState;
}

function returnMultipleNewDiscount(state, names, values) {
    let newState = {...state};
    for(let z=0;z<names.length;z++){
        newState[names[z]] = values[z];
    }
    updateLocalDiscount(newState);
    return newState;
}

function clearDiscountState(state){
    const newState = {...cloneDeep(discountDataInitialState), partnerID: state.partnerID};
    updateLocalDiscount(newState);
    return newState;
}


export const discountDataReducer = (state,action) => {

    switch (action.type) {

        case discountDataActionTypes.Clear:
            return clearDiscountState(state);

        case discountDataActionTypes.UpdatePolicyStart:
            return returnNewDiscount(state, 'policy_start', action.payload);

        case discountDataActionTypes.UpdateMultiplePolicies:
            return returnNewDiscount(state, 'multiple_policies_discount', action.payload);

        case discountDataActionTypes.UpdateCaaMember:
            return returnNewDiscount(state, 'caa_member_discount', action.payload);

        case discountDataActionTypes.UpdateAppInstallDiscount:
            return returnNewDiscount(state, 'app_install_discount', action.payload);

        case discountDataActionTypes.UpdateAllDiscounts:
            return updateAllDiscount({
                ...state,
                multiple_policies_discount: action.payload.multiple_policies_discount,
                caa_member_discount: action.payload.caa_member_discount,
                app_install_discount: action.payload.app_install_discount,
            });

        case discountDataActionTypes.UpdateQuoterPostal:
            return returnNewDiscount(state, 'quoter_info', {...state.quoter_info, postal_code: action.payload});

        case discountDataActionTypes.UpdateQuoterUTMSource:
            return returnNewDiscount(state, 'quoter_info', {...state.quoter_info, utm_source: action.payload});

        case discountDataActionTypes.UpdateQuoterUTMCampaign:
            return returnNewDiscount(state, 'quoter_info', {...state.quoter_info, utm_campaign: action.payload});

        case discountDataActionTypes.UpdateMultiObjects:
            return updateFieldsInObjects(state, action.fieldName, action.payload, action.objects);

        case discountDataActionTypes.UpdateEmail:
            return returnMultipleNewDiscount(state, ['email_to','newUser'], [ action.payload,{...state.newUser, email: action.payload}]);

        case discountDataActionTypes.UpdateQuoterFirstName:
            return returnMultipleNewDiscount(state, ['quoter_info','newUser'], [{...state.quoter_info, first_name: action.payload},{...state.newUser, first_name: action.payload}]);

        case discountDataActionTypes.UpdateQuoterLastName:
            return returnMultipleNewDiscount(state, ['quoter_info','newUser'], [{...state.quoter_info, last_name: action.payload},{...state.newUser, last_name: action.payload}]);

        case discountDataActionTypes.UpdateQuoterPhone:
            return returnNewDiscount(state, 'quoter_info', {...state.quoter_info, phone: action.payload});

        case discountDataActionTypes.AddVehlink:
            return addVehlink(state);

        case discountDataActionTypes.RemoveVehlink:
            return removeVehlink(state, action.payload);

        case discountDataActionTypes.UpdateVehlinks:
            return  returnNewDiscount(state, 'vehLink', action.payload);

        case discountDataActionTypes.RemoveDriverFromVehlinks:
            return removeDriverFromVehlink(state, action.payload);

        case discountDataActionTypes.UpdateIsAlert:
            return returnNewDiscount(state, 'isAlert', action.payload);

        case discountDataActionTypes.UpdateAlertCompany:
            return returnNewDiscount(state, 'alert', {...state.alert, current_company: action.payload});

        case discountDataActionTypes.UpdateAlertPrice:
            return returnNewDiscount(state, 'alert', {...state.alert, current_price_monthly: action.payload});

        case discountDataActionTypes.UpdateUserDob:
            return returnNewDiscount(state, 'newUser', {...state.newUser, dob: action.payload});

        case discountDataActionTypes.UpdateUserGender:
            const activeGender = formGenderTypes.filter((item) => (item.driverValue===action.payload || item.value===action.payload))[0].value || formGenderTypes[0].value;

            return returnNewDiscount(state, 'newUser', {
                ...state.newUser,
                gender: activeGender,
            });

        case discountDataActionTypes.UpdateUserOccupation:
            return returnNewDiscount(state, 'newUser', {
                ...state.newUser,
                occupation: action.payload === 998 ? "Employed" : "Unemployed"
            });

        case discountDataActionTypes.UpdateUserMarital:
            return returnNewDiscount(state, 'newUser', {
                ...state.newUser,
                marital_status: action.payload
            });

        case discountDataActionTypes.UpdateUserPassword:
            return returnNewDiscount(state, 'newUser', {...state.newUser, password1: action.payload});

        case discountDataActionTypes.UpdateConfirmPassword:
            return returnNewDiscount(state, 'newUser', {...state.newUser, password2: action.payload});

        case discountDataActionTypes.UpdateProvinceName:
            return updateAllDiscount({...state, province_name: action.payload});

        case discountDataActionTypes.UpdateProvinceCode:
            return updateAllDiscount({...state, province_code: action.payload});

        case discountDataActionTypes.UpdateAllDiscount:
            return updateAllDiscount(action.payload);

        default:
            return state;

    }
}
