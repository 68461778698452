import React, {useEffect, useState} from "react";
import ReactPhoneInput from "react-phone-input-2";
const PhoneInput = (props) => {
    const { defaultValue, name, onChange, placeholder } = props;
    const [value, setValue] = useState(defaultValue || '');

    useEffect(() => {
        onChange({
           target: {
               value: value,
               name: name,
           }
        });
    },[value]);

    return(
        <div className="phone-number-wrapper">
            <ReactPhoneInput
                inputProps={{
                    className: "formInput phone-input-component",
                    name: name,
                    placeholder: placeholder || '(855) 325-8444'
                }}
                country={"ca"}
                value={value}
                onlyCountries={['ca']}
                disableDropdown={true}
                disableCountryCode={true}
                onChange={(phoneNumber) => {setValue(phoneNumber);}}
                autoFormat={true}
            />
        </div>
    )
}
export default PhoneInput;
