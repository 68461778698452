import React, {useContext} from "react";
import {defaultModalStyle} from "../ModalWrapper/constants";
import {viewModalActionTypes} from "../../../../contexts/ViewContext/viewContextActions";
import Modal from "react-modal";
import ViewContext from "../../../../contexts/ViewContext";
const ActionModal = () => {

    const {viewModal, updateViewModal} = useContext(ViewContext);

    return(
        <Modal
            isOpen={true}
            style={defaultModalStyle}
            ariaHideApp={false}
        >
            <div className='viewModalWrapper modal-large'>
                {
                    viewModal.title &&
                    <h3>{viewModal.title}</h3>
                }
                {
                    viewModal.description &&
                    <p>{viewModal.description}</p>
                }
                <div className="actionModalButtonsWrapper">
                    <button className="deSelectedBtn" onClick={() => updateViewModal({type: viewModalActionTypes.ClearModal})}>Cancel
                    </button>
                    <button className="selectedBtn" onClick={() => updateViewModal({type: viewModalActionTypes.SubmitAction,payload:viewModal.actions})}>Delete
                    </button>
                </div>
            </div>
        </Modal>
    );
}
export default ActionModal;
