import React from "react";

const ButtonLoader = () => {

    return(
        <div className="loading">
            <div/>
            <div/>
            <div/>
        </div>
    )
};

export default ButtonLoader;
