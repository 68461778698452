import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";

// context
import GlobalDataContext from "../../../contexts/GlobalDataContext";

import {UserDataManager} from "../../../managers/DataManagers";

// form components
import UseForm from "../../../helpers/UseForm";
import InputWrapper from "../../ui/Inputs/InputWrapper";
import ButtonSubmit from "../../ui/buttons/ButtonSubmit";
import {InputTypes} from "../../ui/Inputs/constants";
import {UseFormInputTypes} from "../../../helpers/UseForm/constatns";

// local state
import {Initial_PasswordChangeFormData} from "./constants";


// assets

import BackArrow from "../../../resources/images/back-arrow.svg";

export const PasswordChange = () => {

    const {globalData} = useContext(GlobalDataContext);

    const [formData, updateFormData] = useState([...Initial_PasswordChangeFormData,
        {
            title: 'Email',
            name: 'email',
            toolTip: '',
            defaultErrorMessage: '',
            placeholder: '',
            formName: 'enail',
            type: 'email',
            autoComplete: 'username',
            componentType: InputTypes.HIDDEN_FIELD,
            validationType: UseFormInputTypes.NO_VALIDATION,
            value: globalData.email,
            errorMessage: '',
            isValid: true
        }
        ]);

    const {userPasswordUpdate, userRequestState, userSuccessMessage, userErrorMessage} = UserDataManager();

    const {inputBlur, inputHandler, disableFormInput, submitHandler, disableSubmitButton} = UseForm({
        submitHandler: userPasswordUpdate,
        requestState: userRequestState,
        formData: formData,
        updateFormData: updateFormData
    });


    return (
        <div className='addVehiclesFormContentWrapper'>
            <div className='signUpWrapper formWrapper'>
                <Link to={`/${globalData.partner.id}/profile/edit`} className="backLink"><img src={BackArrow} alt="back"/></Link>
                <h4 className='signUpTitle'>Change Password</h4>
                <form autoComplete='off'>
                    {
                        formData.map((item, key) => {
                            return (
                                <InputWrapper
                                    key={`signin${key}`}
                                    item={item}
                                    disableFormInput={disableFormInput}
                                    inputHandler={inputHandler}
                                    inputBlur={inputBlur}
                                />
                            )
                        })
                    }

                    {
                        userErrorMessage &&
                        <span className='errorMsg'>{userErrorMessage}</span>
                    }
                    {
                        userSuccessMessage &&
                        <span className='SuccessMsg'>{userSuccessMessage}</span>
                    }

                    <ButtonSubmit
                        classNames="authSubmitBtn"
                        title="Update"
                        requestState={userRequestState}
                        SuccessMessage={userSuccessMessage}
                        disabled={disableSubmitButton}
                        submitHandler={submitHandler}
                        formLoop={true}
                    />
                </form>
            </div>
        </div>
    );
};
