import React, {useContext} from "react";
import ViewContext from "../../../../contexts/ViewContext/ViewContext";
import {modalTypes} from "./constants";
import MessageModal from "../modals/MessageModal";
import ActionModal from "../modals/ActionModal";
import PostalModal from "../modals/PostalModal";
import FinaleModal from "../modals/FinaleModal";
import MessageRedirectModal from "../modals/MessageRedirectModal";
import QuoteRequestModal from "../modals/QuoteRequestModal";

const ModalContainer = () => {
    const {viewModal} = useContext(ViewContext);

    return (
        <>
            {
                viewModal.type === modalTypes.Message &&
                <MessageModal/>
            }
            {
                viewModal.type === modalTypes.MessageRedirect &&
                <MessageRedirectModal />
            }
            {
                viewModal.type === modalTypes.Action &&
                <ActionModal/>
            }
            {
                viewModal.type === modalTypes.Postal &&
                <PostalModal />
            }
            {
                viewModal.type === modalTypes.Finale &&
                <FinaleModal />
            }
            {
              viewModal.type === modalTypes.QuoteRequest &&
              <QuoteRequestModal />
            }
        </>
    );
};

const ModalWrapper = () => {

    const {viewModal} = useContext(ViewContext);

    return (
        <>
            {
                viewModal.isOpen &&
                <ModalContainer/>
            }
        </>
    );
};

export default ModalWrapper;
