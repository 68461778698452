import React, {useContext} from "react";
import ViewContext from "../../contexts/ViewContext";

export const NavigationDrivingLicence = () => {
    const {viewSize} = useContext(ViewContext);
    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            height="28px"
            style={{marginBottom: viewSize.isMobile ? '0' : '7px'}}
            viewBox="0 0 36 24.1"
        >
            <g fill="var(--primary-color)">
                <path d="M33.1,0H2.8C1.3,0,0,1.3,0,2.8v18.4l0,0c0,1.6,1.3,2.8,2.8,2.8h30.3c1.6,0,2.8-1.3,2.8-2.8l0,0V2.8
		C36,1.3,34.7,0,33.1,0z M2.8,1.4h30.3c0.8,0,1.4,0.6,1.4,1.4v16.4H13.9c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h20.7v0.6
		c0,0.8-0.6,1.4-1.4,1.4H2.8c-0.8,0-1.4-0.6-1.4-1.4v-0.6h6.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7l0,0H1.4V2.8
		C1.4,2.1,2,1.4,2.8,1.4L2.8,1.4z"/>
                <path d="M19.7,5.8h3.8c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-3.8c-0.4,0-0.7,0.3-0.7,0.7S19.3,5.8,19.7,5.8
		C19.7,5.8,19.7,5.8,19.7,5.8z"/>
                <path d="M26.5,5.8h3.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-3.9c-0.4,0-0.7,0.3-0.7,0.7S26.1,5.8,26.5,5.8
		C26.5,5.8,26.5,5.8,26.5,5.8z"/>
                <path d="M19.7,8.8h10.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H19.7c-0.4,0-0.7,0.3-0.7,0.7S19.3,8.8,19.7,8.8
		C19.7,8.8,19.7,8.8,19.7,8.8z"/>
                <path d="M19.7,11.9h3.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-3.2c-0.4,0-0.7,0.3-0.7,0.7S19.3,11.9,19.7,11.9
		C19.7,11.9,19.7,11.9,19.7,11.9z"/>
                <path d="M29.7,10.5h-2.7c-0.9,0-1.7,0.8-1.7,1.7c0,0,0,0,0,0v2.6c0,0.9,0.8,1.7,1.7,1.7l0,0h2.7c0.9,0,1.7-0.8,1.7-1.7
		c0,0,0,0,0,0v-2.6C31.4,11.3,30.6,10.5,29.7,10.5L29.7,10.5z M30,14.8c0,0.2-0.1,0.3-0.3,0.3h-2.7c-0.2,0-0.3-0.1-0.3-0.3v-2.6
		c0-0.2,0.1-0.3,0.3-0.3h2.7c0.2,0,0.3,0.1,0.3,0.3V14.8z"/>
                <path d="M19.7,15h3.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-3.2c-0.4,0-0.7,0.3-0.7,0.7S19.3,15,19.7,15
		C19.7,15,19.7,15,19.7,15z"/>
                <path d="M5.6,16.5h9.9c0.4,0,0.7-0.3,0.7-0.7V4.9c0-0.4-0.3-0.7-0.7-0.7H5.6c-0.4,0-0.7,0.3-0.7,0.7v10.9
		C4.9,16.1,5.2,16.5,5.6,16.5z M14.8,15.1H6.3v-2l0,0c0.3-1.1,1.3-1.8,2.5-1.8h3.6c1.1,0,2.1,0.7,2.5,1.8l0,0L14.8,15.1z M9,8.3
		c0-0.8,0.7-1.5,1.5-1.5S12,7.5,12,8.3s-0.7,1.5-1.5,1.5C9.7,9.8,9,9.1,9,8.3C9,8.3,9,8.3,9,8.3L9,8.3z M14.8,10.6
		c-0.5-0.4-1.1-0.7-1.8-0.8c0.9-1.4,0.5-3.1-0.9-4c-0.1-0.1-0.3-0.2-0.4-0.2h3.1L14.8,10.6L14.8,10.6z M9.4,5.6
		C7.9,6.2,7.2,7.9,7.8,9.4C7.9,9.6,8,9.7,8.1,9.8c-0.7,0.1-1.3,0.4-1.8,0.8v-5L9.4,5.6z"/>
                <path d="M11.2,19.4c-0.3-0.2-0.8-0.1-1,0.2c-0.2,0.3-0.1,0.8,0.2,1s0.8,0.1,1-0.2c0,0,0.1-0.1,0.1-0.1
		C11.6,19.9,11.5,19.5,11.2,19.4z"/>
            </g>
        </svg>
    );
}
