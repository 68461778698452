import React, {useContext} from "react";
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";
import DiscountDataContext from "../../../contexts/formContexts/DiscountDataContext";
import {vehicleDataActionTypes} from "../../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import {discountDataActionTypes} from "../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";

const AddVehicleDriverButton = () => {

    const {vehicleData, updateVehicleData, updateVehicleTab} = useContext(VehicleDataContext);
    const {updateDiscountData} = useContext(DiscountDataContext);

    const addVehicleHandler = () =>{
        updateVehicleData({ type:vehicleDataActionTypes.AddItem });
        updateVehicleTab({type:vehicleDataActionTypes.AddItem});
        updateDiscountData({type: discountDataActionTypes.AddVehlink});
        window.scrollTo(0,0);
    }

    return(
      <>
          {
              vehicleData.items.length<5 &&
              <button onClick={() => {addVehicleHandler()}} className='bottom-add-driver-btn'>Add A Vehicle
              </button>
          }
      </>
    );
}
export default AddVehicleDriverButton;
