import cloneDeep from 'lodash.clonedeep';
import {driverDataActionTypes} from "./driverDataActionTypes";

export const driverTabItemInitialState = {
    name: 'Driver 1',
    value: 0
};

function updateLocalTab(state) {
    localStorage.setItem(`${state.partnerID}_driverTab`, JSON.stringify(state));
}

function addNewTab(state) {
    const newTab = {
        tabs: [...state.tabs, {name: `Driver ${(state.tabs.length + 1)}`, value: (state.tabs.length)}],
        activeItem: (state.tabs.length),
        partnerID: state.partnerID,
    };
    updateLocalTab(newTab);
    return newTab;
}

function removeTab(state, removeIndex) {

    const newTabs = state.tabs.filter((item, index) => index !== removeIndex);

    let newActive = 0;

    for (let i in newTabs) {
        if (newTabs[i].name.includes("Driver")) {
            newTabs[i].name = `Driver ${(Number(i) + 1)}`;
        }

    }

    if (state.activeItem >= removeIndex && state.activeItem > 0) {
        newActive = (state.activeItem - 1);
    }

    const newTab = {tabs: newTabs, activeItem: newActive, partnerID: state.partnerID};

    updateLocalTab(newTab);
    return newTab;
}

function clearDriverTabs(state) {
    const newTabs = {activeItem: 0, tabs: [cloneDeep(driverTabItemInitialState)], partnerID: state.partnerID};
    updateLocalTab(newTabs);
    return newTabs;
}

export const driverTabReducer = (state, action) => {

    switch (action.type) {

        case driverDataActionTypes.Clear:
            return clearDriverTabs(state);

        case driverDataActionTypes.SelectItem:
            if (!isNaN(action.payload) && action.payload < state.tabs.length) {
                state.activeItem = action.payload;
                updateLocalTab(state);
                return {...state, activeItem: action.payload};
            }
            return state;

        case driverDataActionTypes.AddItem:
            return addNewTab(state);

        case driverDataActionTypes.RemoveItem:
            return removeTab(state, action.payload);

        case driverDataActionTypes.UpdateTabName:
            let newTabs = state.tabs;
            newTabs[action.index].name = action.payload !== '' ? action.payload : `Driver ${(Number(action.index) + 1)}`;
            updateLocalTab({...state, tabs: newTabs});
            return {...state, tabs: newTabs};

        default:
            return state;
    }
}
