import React, {useCallback, useContext} from "react";
import {calculateMaxBirthDay} from "../../../constants";
import VehicleDateInput from "../../../../../ui/vehicleFormInputs/VehicleDateInput";
import DriverDataContext from "../../../../../../contexts/formContexts/DriverDataContext";
import {driverDataActionTypes} from "../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {discountDataActionTypes} from "../../../../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";
import DiscountDataContext from "../../../../../../contexts/formContexts/DiscountDataContext";
import VehicleInputTitleWithToolTip from "../../../../../ui/vehicleFormInputs/VehicInputTitleWithToolTip";
import {FormConfigStateContext} from "../../../../../../contexts/formContexts/FormConfigState";

const DriverBirthDay = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);
    const {formConfig} = useContext(FormConfigStateContext);
    const {updateDiscountData} = useContext(DiscountDataContext);

    const handleInput = useCallback((evt) => {
        updateDriverData({
            type: driverDataActionTypes.UpdateDateOfBirth,
            index: driverTab.activeItem,
            payload: evt.target.value,
            config: formConfig.minDates,
        });
        if (driverTab.activeItem === 0) {
            updateDiscountData({
                type: discountDataActionTypes.UpdateUserDob,
                payload: evt.target.value
            });
        }
    }, [driverTab.activeItem, updateDiscountData, updateDriverData]);


    return (
        <>
            <VehicleInputTitleWithToolTip title="When were you born?" id="birthDate" description="Insurers generally consider your age and driving experience when
                        calculating a vehicle insurance quote. The safest drivers are often those who are over
                        thirty, but each insurer will have their own parameters. The youngest and oldest drivers
                        have the greatest liability reflected in their premiums due to inexperience or health
                        complications, respectively."/>

            <VehicleDateInput
                className='inputDate'
                type='date'
                name='date_of_birth'
                defaultValue={driverData.items[driverTab.activeItem].date_of_birth ? driverData.items[driverTab.activeItem].date_of_birth : ''}
                min={false}
                max={calculateMaxBirthDay(formConfig.minDates.licenceAge)}
                parentName={false}
                handleDate={handleInput}
                disabled={false}
            />
        </>

    );
}

export default DriverBirthDay;
