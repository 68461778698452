export const discountDataActionTypes = {
    UpdatePolicyStart: 'UpdatePolicyStart',
    UpdateMultiplePolicies:'UpdateMultiplePolicies',
    UpdateAppInstallDiscount:'UpdateAppInstallDiscount',
    UpdateCaaMember:'UpdateCaaMember',
    UpdateAllDiscounts: 'UpdateAllDiscounts',
    UpdateEmail: 'UpdateEmail',
    UpdateQuoterPostal: 'UpdateQuoterPostal',
    UpdateMultiObjects: 'UpdateMultiObjects',
    UpdateQuoterFirstName: 'UpdateQuoterFirstName',
    UpdateQuoterLastName: 'UpdateQuoterLastName',
    UpdateQuoterPhone: 'UpdateQuoterPhone',
    AddVehlink: 'AddVehlink',
    RemoveVehlink: 'RemoveVehlink',
    UpdateVehlinks: 'UpdateVehlinks',
    RemoveDriverFromVehlinks: 'RemoveDriverFromVehlinks',
    UpdateIsAlert: 'UpdateIsAlert',
    UpdateAlertCompany: 'UpdateAlertCompany',
    UpdateAlertPrice: 'UpdateAlertPrice',
    UpdateUserLastName: 'UpdateUserLastName',
    UpdateUserDob: 'UpdateUserDob',
    UpdateUserGender: 'UpdateUserGender',
    UpdateUserOccupation: 'UpdateUserOccupation',
    UpdateUserMarital: 'UpdateUserMarital',
    UpdateUserPassword: 'UpdateUserPassword',
    UpdateConfirmPassword: 'UpdateConfirmPassword',
    UpdateProvinceName: 'UpdateProvinceName',
    UpdateProvinceCode: 'UpdateProvinceCode',
    UpdateQuoterUTMSource: 'UpdateUTMSource',
    UpdateQuoterUTMCampaign: 'UpdateUTMCampaign',
    UpdateAllDiscount: 'UpdateAllDiscount',
    Clear: 'Clear'
};
