import moment from "moment";
import {currentDate} from "../../../config/dateConfig";
import React from "react";

export function returnPurchaseMinDate(year){
    if(year) {
        return moment(year).subtract(1, 'years').format('YYYY-MM-DD');
    }
    return moment(currentDate).format('YYYY-MM-DD');
}

export function vehicleYearRange (){
    let vehicleYear = [];
    for (let i = (currentDate.getFullYear()); i >= 2001; i--) {
        vehicleYear.push(
            <option key={i} value={i}>{i}</option>
        );
    }
    return vehicleYear;
}

export function checkRecommendedRange(distance_daily, distance_business) {

    let kmEachDay;

    if(Number(distance_business)) {
        kmEachDay = Number(distance_business) > Number(distance_daily) ? Number(distance_business) :
            (Number(distance_daily) > Number(distance_business) ?
                Number(distance_daily) : Number(distance_business));
    } else {
        kmEachDay = Number(distance_daily)
    }

    switch (kmEachDay !== '') {

        case kmEachDay === 1:
            return 8000;

        case ((kmEachDay >= 2) && (kmEachDay <= 10)) :
            return 10000;

        case ((kmEachDay >= 15) && (kmEachDay <= 20)) :
            return 15000;

        case ((kmEachDay >= 30) && (kmEachDay <= 40)) :
            return 20000;

        case ((kmEachDay >= 50) && (kmEachDay <= 80)) :
            return 30000;

        case ((kmEachDay >= 90) && (kmEachDay <= 100)) :
            return 50000;

        case kmEachDay === 150:
            return 60000;

        case kmEachDay === 200 :
            return 80000;

        case kmEachDay === 250 :
            return 100000;

        case kmEachDay === 300 :
            return 150000;

        case kmEachDay === 350 :
            return 200000;

        case kmEachDay === 400 :
            return 250000;

        default:
            return kmEachDay;
    }

}
