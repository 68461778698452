import React, {useContext} from "react";
import GlobalDataContext from "../../../../../contexts/GlobalDataContext";

export const PartnerHeader = () => {

    const {globalData} = useContext(GlobalDataContext);

    return(
        <div className="appPartnerNavbarRoot">
            <div className="appPartnerNavbarWrapper">
                <a href={globalData.partner.logotypeLink} rel="noopener noreferrer">
                    <img className="logotype" src={globalData.partner.logotype} alt={`${globalData.partner.id}-logotype`} />
                </a>
                <div />
            </div>
        </div>
    );
}
