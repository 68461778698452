import React from "react";
import ReactTooltip from "react-tooltip";
import VehicleDateInput from "../vehicleFormInputs/VehicleDateInput";

const InputDate = (props) => {
    return(
        <>
            <div className="formLabelAndTooltipWrapper">
                <h4>{props.item.title}</h4>
                {
                    props.item.toolTip &&
                    <>
                        <div className="tooltipIcon" data-tip={true} data-for={props.item.formName}/>
                        <ReactTooltip id={props.item.formName} className="tooltipWrapper"
                                      arrowColor="transparent">
                            <p className="tooltipText">{props.item.toolTip}</p>
                        </ReactTooltip>
                    </>
                }
            </div>
        <VehicleDateInput
            className='datePickerInput'
            type='date'
            name={props.item.name}
            defaultValue={props.item.localValue}
            parentName=''
            handleDate={(event, parentName) => {props.datePickerHandler(event.target.value,event.target.name)}}
            disabled={props.disableFormInput}
            max={props.item.max}
        />
        </>
    );
};

export default InputDate;
