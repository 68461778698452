import React, {useContext} from "react";

import GlobalDataContext from "../../../../../../contexts/GlobalDataContext";
import ViewContext from "../../../../../../contexts/ViewContext";
import {viewHeaderActionTypes} from "../../../../../../contexts/ViewContext/viewContextActions";

import {defaultPages, userPublicPages} from "../../constants";
import ButtonHeaderNav from "../../../../../ui/header/ButtonHeaderNav";
import ButtonLogo from "../../../../../ui/header/ButtonLogo";
import DesktopHeaderDropDown from "./DesktopHeaderDropDown";

import downArrowIcon from "../../../../../../resources/images/arrow-down-small-bold-white.svg";
import upArrowIcon from "../../../../../../resources/images/arrow-up-white.svg";

const AppHeaderDesktop = (props) => {

    const {globalData} = useContext(GlobalDataContext);
    const {viewHeader, updateViewHeader} = useContext(ViewContext);

    return(
        <div className="navbarContentWrapper">
            <ButtonLogo />
            <div className='linksWrapper'>
                {
                    defaultPages.map((item, index)=>{
                        return(
                            <ButtonHeaderNav item={item} key={`desktop-nav-public-${index}`}/>
                        );
                    })
                }
                {
                    !globalData.isAuth &&
                        <>
                            {
                                userPublicPages.map((item, index)=>{
                                    return(
                                        <ButtonHeaderNav item={item} key={`desktop-nav-user-logged-out-${index}`}/>
                                    )
                                })
                            }
                        </>
                }
                {
                    globalData.isAuth &&
                        <h3 onClick={()=>{updateViewHeader({type:viewHeaderActionTypes.UpdateDesktopHeader, payload:!viewHeader.isDesktopOpen})}}>
                            {globalData.first_name}
                            <span>
                                    {
                                        !viewHeader.isDesktopOpen &&
                                        <img src={downArrowIcon} alt="downArrow"/>
                                    }
                                {
                                    viewHeader.isDesktopOpen &&
                                    <img src={upArrowIcon} alt="upArrow"/>
                                }
                                </span>
                        </h3>
                }
                {
                    viewHeader.isDesktopOpen &&
                    <DesktopHeaderDropDown signOut={props.signOut} />
                }
            </div>
        </div>
    );
};

export default AppHeaderDesktop;
