import React, {useCallback, useContext} from "react";
import VehicleDateInput from "../../../../../../../ui/vehicleFormInputs/VehicleDateInput";
import {comparePassesDriverTraining} from '../../../../../helper';
import {currentDate} from "../../../../../../../../config/dateConfig";
import DriverDataContext from "../../../../../../../../contexts/formContexts/DriverDataContext";
import {returnMonthToRend} from "../../../../../../../../helpers/dateHelper";
import {formLicenceTypes} from "../../../../../../../../config/formConstants";
import {driverDataActionTypes} from "../../../../../../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {FormConfigStateContext} from "../../../../../../../../contexts/formContexts/FormConfigState";

const GTwoInputBlock = () => {

    const {driverTab, driverData, updateDriverData} = useContext(DriverDataContext);
    const {formConfig} = useContext(FormConfigStateContext);

    const handleDateInput = useCallback((evt)=>{
        updateDriverData({
            type: driverDataActionTypes.UpdateLicenceGTwoDate,
            payload: evt.target.value,
            index: driverTab.activeItem,
            config: formConfig.minDates,
        });
    },[formConfig]);

    const handleSwitch = useCallback((value)=>{
        updateDriverData({type: driverDataActionTypes.UpdatePassedDriverTraining, payload: value, index: driverTab.activeItem});
    },[]);

    return(
        <>
            <div className="formLabelAndTooltipWrapper nextToCalendarInput">
                <h4>{formConfig.licenceConfig.g2.title} licence date</h4>
            </div>
            <VehicleDateInput
                className='inputDate'
                type='month'
                name='g2_licence_date'
                defaultValue={
                    driverData.items[driverTab.activeItem].licence_info.g2_licence_date ?
                        driverData.items[driverTab.activeItem].licence_info.g2_licence_date :
                        driverData.items[driverTab.activeItem].minDates.gTwoDefaultValue
                }
                min={driverData.items[driverTab.activeItem].minDates.gTwoMinDate}
                max={driverData.items[driverTab.activeItem].minDates.gTwoMaxDate}
                parentName='licence_info'
                handleDate={(evt)=>handleDateInput(evt)}
                disabled={false}
            />
            {
                driverData.items[driverTab.activeItem].minDates.gTwoMinDate &&
                <p className="recommendedRangeInfo licensedHintForDate">
                    {driverData.items[driverTab.activeItem].minDates.gTwoMinDate ? returnMonthToRend(driverData.items[driverTab.activeItem].minDates.gTwoMinDate) : ''} would be
                    your earliest {formConfig.licenceConfig.g2.title} licence date based on your first licenced age and date of birth if you completed a driver education course
                </p>
            }
            {
                (driverData.items[driverTab.activeItem].licence_info.licence_type !== formLicenceTypes.g && comparePassesDriverTraining(currentDate, driverData.items[driverTab.activeItem].licence_info.g2_licence_date)) &&
                <>
                    <div className="formLabelAndTooltipWrapper nextToCalendarInput">
                        <h4>Has {driverTab.tabs[driverTab.activeItem].name} completed a driver education course within the last 3 years?
                        </h4>
                    </div>
                    <div className="formButtonsWrapper">
                        <button
                            onClick={() => handleSwitch(true)}
                            className={driverData.items[driverTab.activeItem].licence_info.passed_driver_training ? "selectedBtn" : "deSelectedBtn"}>Yes
                        </button>
                        <button
                            onClick={() => handleSwitch(false)}
                            className={!driverData.items[driverTab.activeItem].licence_info.passed_driver_training ? "selectedBtn" : "deSelectedBtn"}>No
                        </button>
                    </div>
                </>
            }
        </>
    )
}

export default GTwoInputBlock;
