import React from "react";
import {QuoteDataManager} from "../../../managers/DataManagers/QuoteDataManager";

export const BrokerLink = ({redirectUrl}) => {
  const {postRequestQuoteOnliaUrl} = QuoteDataManager();

  const handleClick = () => {
    postRequestQuoteOnliaUrl(true, redirectUrl)
  }

  return <div className="quotePhoneRow">
    <h3>Click below to get this rate</h3>
    <div onClick={handleClick} className="phoneNumber">Get details</div>
  </div>
}
