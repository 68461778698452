import {UseFormInputTypes} from "../../../helpers/UseForm/constatns";
import {InputTypes} from '../../ui/Inputs/constants';

export const Initial_PasswordForgetFormData = [
    {
        title: 'Email',
        name: 'email',
        toolTip: '',
        defaultErrorMessage: 'Please enter your email',
        placeholder: 'example@example.com',
        formName: 'email',
        type: 'email',
        autoComplete: 'username',
        componentType: InputTypes.FIELD,
        validationType: UseFormInputTypes.EMAIL,
        value: '',
        errorMessage: '',
        isValid: false
    }
];
