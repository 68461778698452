import React, {useState} from "react";

function formatPostalCode(str) {
    return str ? str.replace(/[^a-zA-Z0-9]/g, "").replace(/ /g, "").replace(/-/g, "").replace(/.{3}(?=.)/g, '$& ').toUpperCase() : '';
}


const PostalInput = () => {
    const [value, setValue] = useState('');

    const onChange = (evt) => {
        setValue(formatPostalCode(evt.target.value));
    }

    return(
        <input
            name='postal'
            type="text"
            className="formInput postalInput"
            maxLength="7"
            value={value}
            onChange={onChange}
            placeholder="A1A 1A1"
        />
    );
}

export default PostalInput;
