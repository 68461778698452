import React, {useContext, useEffect, useReducer} from "react";
import FormTabWrapper from "../../ui/FormTab";
import FormTwoWrapper from "./FormTwoWrapper";
import StepOneValidationManager from "../../../managers/ValidationManagers/StepOneValidationManager";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";
import {validationStatusTypes} from "../../../managers/ValidationManagers/constants";
import MyChoicePartnerLogo from "../../../resources/images/logotype-mychoice-partner.png";
import {myChoicePartnerId} from "../../../config/partnersConfig";
import GlobalDataContext from "../../../contexts/GlobalDataContext";


const FormOne = () => {
  const {globalData} = useContext(GlobalDataContext);
  const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState});
  const {stepOneStatus, stepOneValidation} = StepOneValidationManager();


  useEffect(() => {
    if (!renderState.isRender && !renderState.isFirst) {
      updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
    }
  }, [renderState])

  useEffect(() => {
    if (renderState.isFirst) {
      if (stepOneStatus === validationStatusTypes.Waiting) {
        stepOneValidation();
      }
      updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
    }
  })

  return (
    <div className="add-vehicles-content-wrapper">
      {
        renderState.isRender &&
        <div className="addVehiclesFormContentWrapper">
          <div className="controlAndTabsWrapper">
            <FormTabWrapper isVehicle={false} isDriver={true} isRecalculate={false}/>
            <FormTwoWrapper/>
          </div>
          {
            globalData.partner.id !== myChoicePartnerId &&
            <img className='logo-for-partner' src={MyChoicePartnerLogo} alt='partner logo'/>
          }
        </div>
      }
    </div>
  );
}
export default FormOne;
