import React, {useContext, useReducer} from "react";
import {pageConfigReducer} from "./pageConfigReducer";
import {pageConfigInitialState} from "./initialState";
import GlobalDataContext from "../../GlobalDataContext";
import {getLocalInitialValue} from "../helper";

export const PageConfigContext = React.createContext();

export const PageConfigContextProvider = (props) => {
    const {globalData} = useContext(GlobalDataContext);

    const [pageConfig, dispatchPageConfig] = useReducer(
        pageConfigReducer,
        getLocalInitialValue('pageConfigState', pageConfigInitialState, globalData.partner.id)
    );

    return(
      <PageConfigContext.Provider
          value={{
              pageConfig, dispatchPageConfig,
          }}
      >
          {
              props.children
          }
      </PageConfigContext.Provider>
    );
}
