import React from "react";
import DriverPolicyStart from "./components/DriverPolicyStart";
import DriverListedDate from "./components/DriverListedDate";
import DriverInsuredDate from "./components/DriverInsuredDate";

const DriverListedBlock = () => (
    <div className="bottomFormWrapper">
        <DriverListedDate/>
        <DriverInsuredDate/>
        <DriverPolicyStart/>
    </div>
);

export default DriverListedBlock;
