import React, {useContext} from "react";
import {defaultModalStyle} from "../ModalWrapper/constants";
import {viewModalActionTypes} from "../../../../contexts/ViewContext/viewContextActions";
import Modal from "react-modal";
import ViewContext from "../../../../contexts/ViewContext";
import {formatPhoneObject} from "../../../../helpers/formatUtils";

const QuoteRequestModal = () => {

  const {viewModal, updateViewModal} = useContext(ViewContext);

  return(
    <Modal
      isOpen={true}
      style={defaultModalStyle}
      ariaHideApp={false}
    >
      <div className='viewModalWrapper modal-large'>
        {
          viewModal.title &&
          <h3>{viewModal.title}</h3>
        }
        {
          viewModal.description &&
          <p>{viewModal.description}</p>
        }
        <div className="actionModalButtonsWrapper">
          <button className="deSelectedBtn" onClick={() => updateViewModal({type: viewModalActionTypes.CloseModal})}>OK
          </button>
          {
            viewModal.phoneNumber &&
            <a target="_blank" rel="noreferrer noopener" href={`tel:${formatPhoneObject(viewModal.phoneNumber).phoneNumber}`}
               className="phoneNumber">Call the Broker</a>
          }
        </div>
      </div>
    </Modal>
  );
}
export default QuoteRequestModal;
