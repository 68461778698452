import React, {useContext, useEffect, useReducer} from "react";
import {formConfigReducer} from "./FormConfigReducer";
import {provinceList} from "../../../config/provinceConfig";
import GlobalDataContext from "../../GlobalDataContext";
import {formConfigActionTypes} from "./FormConfigActions";

export const FormConfigStateContext = React.createContext();

const formConfigDefaultState = {
    ...provinceList[0],
}

export const FormConfigStateProvider = (props) => {
    const {globalData} = useContext(GlobalDataContext);
    const [formConfig, dispatchFormConfig] = useReducer(formConfigReducer,
        localStorage.getItem(`${globalData.partner.id}_formConfig`) ?
            {...formConfigDefaultState, ...JSON.parse(localStorage.getItem(`${globalData.partner.id}_formConfig`))} :
            {...formConfigDefaultState}
    );

    useEffect(() => {
        if (globalData.postal.province_code) {
            dispatchFormConfig({
                type: formConfigActionTypes.SetConfig,
                payload: globalData.postal.province_code,
                partnerID: globalData.partner.id,
            });
        }
    },[globalData.postal.province_code]);

    return(
        <FormConfigStateContext.Provider value={{
            formConfig, dispatchFormConfig
        }}>
            {
                props.children
            }
        </FormConfigStateContext.Provider>
    )
};
