import React, {useContext, useEffect, useReducer} from "react";
import Header from "./components/Header";
import MultipleDrivers from "./components/blocks/MultipleDrivers";
import DiscountBlock from "./components/blocks/DiscountBlock";
import UserBlock from "./components/blocks/UserBlock";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../../../helpers/hooksLocalRenderState";
import StepOneValidationManager from "../../../managers/ValidationManagers/StepOneValidationManager";
import StepTwoValidationManager from "../../../managers/ValidationManagers/StepTwoValidationManager";
import {validationStatusTypes} from "../../../managers/ValidationManagers/constants";
import {discountDataActionTypes} from "../../../contexts/formContexts/DiscountDataContext/discountDataActionTypes";
import DiscountDataContext from "../../../contexts/formContexts/DiscountDataContext";
import GlobalDataContext from "../../../contexts/GlobalDataContext";
import SubmitBlock from "./components/blocks/SubmitBlock";

const FormThreeWrapper = () => {

    const [renderState, updateRenderState] = useReducer(renderStateReducer, {...renderInitialState, requestCount: 2});

    const {globalData} = useContext(GlobalDataContext);
    const {discountData, updateDiscountData} = useContext(DiscountDataContext);

    const {stepOneStatus, stepOneValidation} = StepOneValidationManager();
    const {stepTwoStatus, stepTwoValidation} = StepTwoValidationManager();

    useEffect(() => {
        if (!renderState.isFirst && !renderState.isRender) {
            updateRenderState({type: renderStateActionTypes.UpdateRender, payload: true});
        }
    }, [renderState]);

    useEffect(() => {
        if (renderState.isFirst) {
            if (stepOneStatus === validationStatusTypes.Waiting) {
                stepOneValidation();
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Waiting) {
                stepTwoValidation();
            }
            /*
                if (globalData.postal.original_postal_code !== discountData.quoter_info.original_postal_code) {
                    updateDiscountData({
                        type: discountDataActionTypes.UpdateQuoterPostal,
                        payload: globalData.postal.original_postal_code
                    });
                }
             */
            if (globalData.postal.province_code !== discountData.quoter_info.province_code) {
                updateDiscountData({
                    type: discountDataActionTypes.UpdateProvinceName,
                    payload: globalData.postal.province_name
                });
                updateDiscountData({
                    type: discountDataActionTypes.UpdateProvinceCode,
                    payload: globalData.postal.province_code
                });
            }
            if (stepOneStatus === validationStatusTypes.Validated && stepTwoStatus === validationStatusTypes.Validated) {
                updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
            }
        }
    });

    return (
        <div className="discountSecondStep">
            <div className='discountSecondStepBottomRow'>
                {
                    renderState.isRender &&
                    <>
                        <Header
                            title="You are just seconds away from the best quote"
                            description="You are seconds away from receiving your car insurance quotes, please provide your email after completing the discount section so we can send you your personalized free car insurance quotes!"
                        />
                        <MultipleDrivers/>
                        <DiscountBlock/>
                        <UserBlock/>
                        <SubmitBlock/>
                    </>
                }
            </div>
        </div>
    );
}

export default FormThreeWrapper;
