export const defaultPages = [
    {
        name: 'CAR',
        link: '/',
        isVehicleForm: true,
        isLocal: true
    },
    {
        name: 'HOME',
        link: 'https://www.mychoice.ca/insurance/home/',
        isLocal: false
    },
    {
        name: 'BLOG',
        link: 'https://www.mychoice.ca/blog/',
        isLocal: false
    },
    {
        name: 'ABOUT US',
        link: 'https://www.mychoice.ca/about-us/',
        isLocal: false
    },
    {
        name: 'CONTACT',
        link: 'https://www.mychoice.ca/contact/',
        isLocal: false
    }
];

export const userPublicPages = [
    {
        name: 'LOGIN',
        link: '/accounts/signin',
        className: 'logInLink',
        isLocal: true
    },
    /*
    {
        name: 'SIGN UP',
        link: '/accounts/signup',
        className: 'signUpLink',
        isLocal: true
    }
     */
];

export const userPrivatePages = [
    {
        name: 'My Profile',
        link: '/profile/view',
        className: 'userMenuLink',
        isLocal: true
    },
    {
        name: 'Quotes History',
        link: '/profile/quotes-history',
        className: 'userMenuLink',
        isLocal: true
    }
];
