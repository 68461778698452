import React, {useContext, useEffect, useReducer} from "react";
import ViewContext from "../contexts/ViewContext";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../helpers/hooksLocalRenderState";
import {viewSizeActionTypes} from "../contexts/ViewContext/viewContextActions";
import {MOBILE_MAX_WIDTH} from "../config/viewConfig";

const AppResize = () => {

    const {viewSize, updateViewSize} = useContext(ViewContext);
    const [localState, updateLocalState] = useReducer(renderStateReducer, renderInitialState);

    function resizeHandler() {
        const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if (viewSize.width !== screenWidth || viewSize.height !== screenHeight) {
            updateViewSize({type: viewSizeActionTypes.UpdateScreenSize, width: screenWidth, height: screenHeight});
        }
    }

    useEffect(() => {
        if (localState.isFirst) {
            resizeHandler();
            window.addEventListener('resize', resizeHandler);
            updateLocalState({type: renderStateActionTypes.UpdateFirst, payload: false});
        }
    });


    useEffect(() => {
        if (viewSize.width <= MOBILE_MAX_WIDTH) {
            if (!viewSize.isMobile) {
                updateViewSize({type: viewSizeActionTypes.UpdateMobile, payload: true});
            }
        } else {
            if (viewSize.isMobile) {
                updateViewSize({type: viewSizeActionTypes.UpdateMobile, payload: false});
            }
        }
    }, [viewSize.width, updateViewSize, viewSize.isMobile]);

    return (
        <>
        </>
    );
};

export default AppResize;
