import {formActionTypes} from "../formActionTypes";

export const vehicleDataActionTypes = {
    ...formActionTypes,
    UpdateYear: 'UpdateYear',
    UpdateMake: 'UpdateMake',
    UpdateModel: 'UpdateModel',
    UpdateCondition: 'UpdateCondition',
    UpdateLeased: 'UpdateLeased',
    UpdatePurchaseDate: 'UpdatePurchaseDate',
    UpdateWinterTires: 'UpdateWinterTires',
    UpdateParkingLocation: 'UpdateParkingLocation',
    UpdatePrimaryUse: 'UpdatePrimaryUse',
    UpdateDistanceDaily: 'UpdateDistanceDaily',
    UpdateDistanceBusiness: 'UpdateDistanceBusiness',
    UpdateDistanceYearly: 'UpdateDistanceYearly',
    UpdateCollision: 'UpdateCollision',
    UpdateCollisionValue: 'UpdateCollisionValue',
    UpdateComprehensive:'UpdateComprehensive',
    UpdateComprehensiveValue:'UpdateComprehensiveValue',
    UpdateLiability: 'UpdateLiability',
    Clear: 'Clear'
}
