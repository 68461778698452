import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import React from "react";

const InputSwitch = (props) => {
    return (
        <>

            <div className='formLabelAndTooltipWrapper'>
                <h4>{props.item.title}</h4>

                {
                    props.item.toolTip &&
                    <>
                        <div className="tooltipIcon" data-tip={true} data-for={props.item.formName}/>
                        <ReactTooltip id={props.item.formName} className="tooltipWrapper"
                                      arrowColor="transparent">
                            <p className="tooltipText">{props.item.toolTip}</p>
                        </ReactTooltip>
                    </>
                }
            </div>
            <div className='inputWrapper'>
                <div className='genderButtonWrapper'>
                    {
                        props.item.switchItems.map((item, key)=>{
                            return <button disabled={props.disableFormInput} key={`gender${key}`} type='button' className={props.item.localValue === key ? 'selectedGenderBtn' : 'deSelectedGenderBtn'} onClick={
                                ()=>{props.switchHandler(key, props.item.name)}}>{item.name}</button>
                        })
                    }
                </div>

                {
                    props.item.errorMessage &&
                    <span className='errorMsg'> {props.item.errorMessage} </span>
                }
            </div>
            {
                props.item.title === 'Password' &&
                <Link rel="stylesheet" className="passForgot" to="/forgot-pass">Forgot
                    Password?</Link>
            }
        </>
    );
};
export default InputSwitch;
