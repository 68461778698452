import React, {useContext} from "react";
import GlobalDataContext from "../contexts/GlobalDataContext";
import ViewContext from "../contexts/ViewContext";
import {myChoicePartnerId} from "../config/partnersConfig";

export const ContentWrapper = ({children}) => {

    const {globalData} = useContext(GlobalDataContext);
    const {viewSize, viewHeader} = useContext(ViewContext);

    return(
        <>
            {
                globalData.partner.id === myChoicePartnerId &&
                <div className={`contentWrapper ${
                    viewHeader.vehicleNavigation ? viewSize.isMobile ? 'contentWrapperVehicleMobile' : 'contentWrapperVehicle' : ''}`
                }>
                    {
                      children
                    }
                </div>
            }
            {
                globalData.partner.id !== myChoicePartnerId &&
                <div>
                    {
                        children
                    }
                </div>
            }
        </>
    )
}
