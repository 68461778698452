import React, {useState, useEffect, useContext} from "react";
import {Redirect, useParams} from 'react-router-dom';
import {AuthDataManager} from "../../../managers/DataManagers";
import {requestStatusTypes} from "../../../config/constants";
import DiscountDataContext from "../../../contexts/formContexts/DiscountDataContext";
import {globalDataActionTypes} from "../../../contexts/GlobalDataContext/globalDataActions";
import GlobalDataContext from "../../../contexts/GlobalDataContext";

const EmailVerification = () => {

    const {emailKey} = useParams();

    const {updateGlobalData} = useContext(GlobalDataContext);
    const {discountData} = useContext(DiscountDataContext);

    const [localRedirect, updateLocalRedirect] = useState(false);
    const [localEmailKey, updateLocalEnailKey] = useState('');

    const {authRequestState, emailVerification} = AuthDataManager();


    useEffect(() => {
        if (authRequestState) {
            if (authRequestState !== requestStatusTypes.Loading && authRequestState !== requestStatusTypes.Waiting) {
                if(discountData.email!==''){
                    updateGlobalData({
                        type: globalDataActionTypes.UpdateSignedInRedirect,
                        payload: 'thirdFormSecondPart'
                    });
                }
                updateLocalRedirect(true);
            }
        }
    }, [authRequestState]);

    useEffect(() => {
        if (localEmailKey !== emailKey) {
            updateLocalEnailKey(emailKey);
            emailVerification(emailKey);
        }
    }, [emailKey, localEmailKey, emailVerification]);

    return (
        <>
            {
                localRedirect &&
                <Redirect to='/accounts/signin'/>
            }
        </>
    );
};

export default EmailVerification;
