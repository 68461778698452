import React, {useContext} from "react";
import ViewContext from "../../contexts/ViewContext";

export const NavigationDiscount = () => {
    const {viewSize} = useContext(ViewContext);
    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            height="28px"
            style={{marginBottom: viewSize.isMobile ? '0' : '7px'}}
            viewBox="0 0 26.1 26.1"
        >
            <g fill="var(--primary-color)">
                <path d="M9.8,11.6c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,0,0,0,0,0
		C7.9,10.8,8.8,11.6,9.8,11.6z M9.8,9c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8S9,10.2,9,9.8l0,0C9,9.3,9.3,9,9.8,9z"/>
                <path d="M15.8,13.9c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c0,0,0,0,0,0
		C15,15.8,15.8,15,15.8,13.9z M13.2,13.9c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8l0,0
		C13.5,14.7,13.2,14.4,13.2,13.9z"/>
                <path d="M15.3,8.4c-0.2-0.2-0.5-0.2-0.8,0c0,0,0,0,0,0l-5.9,5.9c-0.2,0.2-0.2,0.5,0,0.8c0,0,0,0,0,0
		c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l5.9-5.9C15.5,9,15.5,8.6,15.3,8.4z"/>
                <path d="M19.8,17.5c0.5,0,0.9,0.4,0.9,0.9c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0,0,0,0,0,0c0-0.9-0.6-1.7-1.4-1.9
		v-0.3c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5c0,0,0,0,0,0v0.3c-1,0.3-1.7,1.4-1.4,2.4c0.2,0.8,1,1.4,1.9,1.4
		c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9l0,0c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5c0,0,0,0,0,0
		c0,0.9,0.6,1.7,1.4,1.9v0.4c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0,0,0,0,0,0v-0.4c1-0.3,1.7-1.4,1.4-2.4c-0.2-0.8-1-1.4-1.9-1.4
		c-0.5,0.1-0.9-0.3-1-0.8c-0.1-0.5,0.3-0.9,0.8-1C19.7,17.5,19.8,17.5,19.8,17.5L19.8,17.5z"/>
                <path d="M22.6,14.2l0.8-1.2c0.5-0.7,0.5-1.6,0-2.4l-0.9-1.3c-0.1-0.2-0.2-0.4-0.2-0.6l0.1-1.6c0-0.9-0.4-1.6-1.2-2
		l-1.4-0.7c-0.2-0.1-0.4-0.3-0.5-0.5l-0.7-1.4c-0.4-0.8-1.2-1.2-2-1.2L15,1.4c-0.2,0-0.5,0-0.6-0.2L13,0.4c-0.7-0.5-1.6-0.5-2.4,0
		L9.4,1.2C9.2,1.3,8.9,1.4,8.7,1.4L7.1,1.3c-0.9,0-1.6,0.4-2,1.2L4.4,3.9C4.3,4.1,4.1,4.3,3.9,4.4L2.5,5.1c-0.8,0.4-1.2,1.2-1.2,2
		l0.1,1.6c0,0.2,0,0.5-0.2,0.6l-0.9,1.3c-0.5,0.7-0.5,1.6,0,2.4l0.9,1.3c0.1,0.2,0.2,0.4,0.2,0.6l-0.1,1.6c0,0.9,0.4,1.6,1.2,2
		l1.4,0.7c0.2,0.1,0.4,0.3,0.5,0.5l0.7,1.4c0.4,0.8,1.2,1.2,2,1.2l1.6-0.1c0.2,0,0.5,0,0.6,0.2l1.3,0.9c0.7,0.5,1.6,0.5,2.4,0
		l1.2-0.8c1.5,3.1,5.3,4.4,8.4,2.9s4.4-5.3,2.9-8.4C24.9,15.8,23.8,14.8,22.6,14.2L22.6,14.2z M13.8,21.6l-1.3,0.9
		c-0.4,0.2-0.8,0.2-1.2,0l-1.3-0.9c-0.4-0.3-0.8-0.4-1.3-0.3l-1.6,0.1c-0.4,0-0.8-0.2-1-0.6l-0.7-1.4c-0.2-0.4-0.5-0.7-0.9-0.9
		L3,17.7c-0.4-0.2-0.6-0.6-0.6-1L2.5,15c0-0.5-0.1-0.9-0.3-1.3l-0.9-1.3c-0.2-0.4-0.2-0.8,0-1.2l0.9-1.3c0.3-0.4,0.4-0.8,0.3-1.3
		L2.4,7c0-0.4,0.2-0.8,0.6-1l1.4-0.7c0.4-0.2,0.7-0.5,0.9-0.9L6,3c0.2-0.4,0.6-0.6,1-0.6l1.6,0.1c0.5,0,0.9-0.1,1.3-0.3l1.3-0.9
		c0.4-0.2,0.8-0.2,1.2,0l1.3,0.9c0.4,0.3,0.8,0.4,1.3,0.3l1.6-0.1c0.4,0,0.8,0.2,1,0.6l0.7,1.4c0.2,0.4,0.5,0.7,0.9,0.9L20.7,6
		c0.4,0.2,0.6,0.6,0.6,1l-0.1,1.6c0,0.5,0.1,0.9,0.3,1.3l0.9,1.3c0.2,0.4,0.2,0.8,0,1.2l-0.9,1.3c0,0,0,0,0,0c-3.3-1-6.8,1-7.7,4.3
		C13.5,19.2,13.5,20.4,13.8,21.6C13.8,21.6,13.8,21.6,13.8,21.6L13.8,21.6z M19.8,25c-2.9,0-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2
		S25,17,25,19.8S22.7,25,19.8,25C19.8,25,19.8,25,19.8,25L19.8,25z"/>
            </g>
        </svg>
    );
}
