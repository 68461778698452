import React from "react";
import PrimaryToolTip from "../tooltips/PrimaryToolTip";
import {ToolTipIcon} from "../../icons";

const VehicleInputTitleWithToolTip = ({title, id, description, extraClassName}) => (
    <div className={`formLabelAndTooltipWrapper ${extraClassName || ''}`}>
        <h4>{title}</h4>
        {
            (id && description) &&
            <>
                <ToolTipIcon data-tip={true} data-for={id}  />
                <PrimaryToolTip id={id} description={description}/>
            </>
        }
    </div>
);

export default VehicleInputTitleWithToolTip;
