import React, {useContext} from "react";
import ViewContext from "../../../../contexts/ViewContext";
import VehicleMobileHeader from "./VehicleMobileHeader";
import VehicleDesktopHeader from "./VehicleDesktopHeader";

const VehicleHeaderWrapper = () => {

    const {viewSize} = useContext(ViewContext);

    return(
        <>
            {
                viewSize.isMobile &&
                <VehicleMobileHeader />
            }
            {
                !viewSize.isMobile &&
                <VehicleDesktopHeader />
            }
        </>
    );
};

export default VehicleHeaderWrapper;
