import React, {useContext, useEffect, useState} from "react";

import GlobalDataContext from "../../../../../../contexts/GlobalDataContext";
import ViewContext from "../../../../../../contexts/ViewContext";
import {viewHeaderActionTypes} from "../../../../../../contexts/ViewContext/viewContextActions";

import ButtonLogo from "../../../../../ui/header/ButtonLogo";
import {defaultPages, userPrivatePages, userPublicPages} from "../../constants";
import ButtonHeaderNav from "../../../../../ui/header/ButtonHeaderNav";
import ButtonSignOut from "../../../../../ui/header/ButtonSignOut";

const AppHeaderMobile = (props) => {

    const {globalData} = useContext(GlobalDataContext);
    const {viewHeader, updateViewHeader} = useContext(ViewContext);

    const [animatedClassName, setAnimatedClassName] = useState('');

    useEffect(() => {

        let animationTimeOut = setTimeout(() => {
            setAnimatedClassName('animated-ease-out-in')
        }, 500);

        return () => {
            clearTimeout(animationTimeOut);
        }
    }, [animatedClassName]);


    return (
        <>
            <div className="navbarContentWrapper">

                <ButtonLogo/>
                <div>
                    <button
                        type="button"
                        className={`mobile-menu-toggle-button ${viewHeader.isMobileOpen ? 'open' : ''}`}
                        onClick={() => {
                            window.scrollTo(0,0);
                            updateViewHeader({
                                type: viewHeaderActionTypes.UpdateMobileHeader,
                                payload: !viewHeader.isMobileOpen
                            });

                        }}
                    >
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                        <span/>
                    </button>
                </div>
            </div>

            <div
                className={`mobile-menu-links ${viewHeader.isMobileOpen ? `${animatedClassName} mobile-menu-links-active` : `${animatedClassName} mobile-menu-links-passive`}`}
            >
                <div
                    className={`mobile-menu-links-wrapper animated-ease-out-in-slow ${viewHeader.isMobileOpen ? `mobile-menu-links-wrapper-active` : `mobile-menu-links-wrapper-passive`}`}
                >
                    {
                        defaultPages.map((item, index) => {
                            return (
                                <ButtonHeaderNav item={item} key={`mobile-nav-public-${index}`} />
                            );
                        })
                    }
                    {
                        !globalData.isAuth &&
                        userPublicPages.map((item, index)=>{
                            return (
                                <ButtonHeaderNav item={item} key={`mobile-nav-logged-out-${index}`} />
                            );
                        })

                    }
                    {
                        globalData.isAuth &&
                            <>
                                {
                                    userPrivatePages.map((item, index)=>{
                                        return (
                                            <ButtonHeaderNav item={item} key={`mobile-nav-logged-in-${index}`} />
                                        );
                                    })
                                }
                                <ButtonSignOut signOut={props.signOut}/>
                            </>
                    }

                </div>
            </div>
        </>
    );
};

export default AppHeaderMobile;
