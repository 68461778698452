import React, {useContext} from "react";
import {Link} from "react-router-dom";

import ViewContext from "../../../contexts/ViewContext";
import VehicleDataContext from "../../../contexts/formContexts/VehicleDataContext";
import DriverDataContext from "../../../contexts/formContexts/DriverDataContext";
import DiscountDataContext from "../../../contexts/formContexts/DiscountDataContext";
import {driverDataActionTypes} from "../../../contexts/formContexts/DriverDataContext/driverDataActionTypes";
import {vehicleDataActionTypes} from "../../../contexts/formContexts/VehicleDataContext/vehicleDataActions";
import {viewRedirectActionTypes} from "../../../contexts/ViewContext/viewContextActions";
import GlobalDataContext from "../../../contexts/GlobalDataContext";

const EditQuote = () => {

    const {globalData} = useContext(GlobalDataContext);
    const {vehicleData,updateVehicleTab} = useContext(VehicleDataContext);
    const {driverTab, updateDriverTab} = useContext(DriverDataContext);
    const {discountData} = useContext(DiscountDataContext);
    const {updateViewRedirect} = useContext(ViewContext);

    function handleDriverEdit(id){
        updateDriverTab({type:driverDataActionTypes.SelectItem, payload:id});
        updateViewRedirect({type:viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}/driver`});

    }
    function handleVehicleEdit(id){
        updateVehicleTab({type:vehicleDataActionTypes.SelectItem, payload:id});
        updateViewRedirect({type:viewRedirectActionTypes.Redirect, payload: `/${globalData.partner.id}`});
    }

    return(
        <div className="editQuotesWrapper">
            <h3 className="editQuotesTitle">Edit Quote</h3>
            <div className="infoTitleRow">
                <h5>Vehicle Information</h5>
            </div>
            {
                vehicleData.items.map((item, index)=>{return(
                    <div className="infoAndEditSingeRow" key={`veh${index}`}>

                        <p>{item.year + ' ' + item.make + ' ' + item.model}</p>
                        <div onClick={()=>{handleVehicleEdit(index)}} className="editLink">Edit</div>
                    </div>
                )})
            }
            <div className="infoTitleRow">
                <h5>Driver Information</h5>
            </div>
            {
                driverTab.tabs.map((item, index)=>{return(
                    <div className="infoAndEditSingeRow" key={`veh${index}`}>
                        <p>{item.name}</p>
                        <div onClick={()=>{handleDriverEdit(index)}} className="editLink">Edit</div>
                    </div>
                )})
            }
            <div className="infoTitleRow">
              <h5>Discount Information</h5>
                <div className="discountInfoRow">
                  <Link to={`/${globalData.partner.id}/info`} className="editDiscountInfoLink">Edit</Link>
                  <p>{discountData.app_install_discount ? 'Driving habits scoring app' : ''}</p>
                  <p>{discountData.multiple_policies_discount ? 'Bundle w/ Home' : ''}</p>
                  <p>{discountData.caa_member_discount ? 'CAA Member' : ''}</p>
                  <p>{(!discountData.caa_member_discount && !discountData.multiple_policies_discount && !discountData.app_install_discount) ? 'No discounts' : ''}</p>
                </div>

            </div>
        </div>
    );
}

export default EditQuote;
