import React from 'react';
import DriverLicenceAge from './components/DriverLicenceAge';
import DriverGLicence from './components/DriverGLicence';
import DriverPreviousLicence from './components/DriverPreviousLicence';

const DriverLicenceInfoBlock = () => (
    <div className="bottomFormWrapper">
        <DriverLicenceAge/>
        <DriverGLicence/>
        <DriverPreviousLicence/>
    </div>
);

export default DriverLicenceInfoBlock;
