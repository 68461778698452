import React, {useContext} from "react";

import QuoteDataContext from "../../../contexts/formContexts/QuoteDataContext";
import {quoteDataActionTypes} from "../../../contexts/formContexts/QuoteDataContext/quoteDataActions";

const QuoteItemDescription = (props) => {

  const {quoteData, updateQuoteData} = useContext(QuoteDataContext);

  const hideText = quoteData.quotes[props.item.index].broker_profile.redirect_url? 'Less Info': 'Hide Details';
  const viewText = quoteData.quotes[props.item.index].broker_profile.redirect_url? 'More Info': 'View Details';

  return (
    <>
      <div className="offersThirdRow">
        <button className="viewOffersDetails" onClick={(e) => {
          updateQuoteData({
            type: quoteDataActionTypes.UpdateTab,
            index: props.item.index,
            payload: !props.item.isVisible
          });
        }}>
          {
            props.item.isVisible ? hideText : viewText
          }
        </button>
      </div>
      {
        props.item.isVisible &&
        <div className="offersFourthRow">
          <h3>Who is {quoteData.quotes[props.item.index].company.name}?</h3>
          <p>{quoteData.quotes[props.item.index].company.description}</p>
          {!quoteData.quotes[props.item.index].broker_profile.redirect_url &&
          <>
            {quoteData.quotes[props.item.index].broker_profile.logo &&
            <img src={quoteData.quotes[props.item.index].broker_profile.logo} className="brokerLogo" alt="logo"/>
            }

            <h3>Brokered by {quoteData.quotes[props.item.index].broker_profile.title}</h3>
            <p>{quoteData.quotes[props.item.index].broker_profile.description}</p>
          </>
          }
          <h3>Hours of Operation</h3>
          <h5>M - F
            : <span>{quoteData.quotes[props.item.index].broker_profile.hours_workdays ? quoteData.quotes[props.item.index].broker_profile.hours_workdays : 'Closed'}</span>
          </h5>
          <h5>Sa
            : <span>{quoteData.quotes[props.item.index].broker_profile.hours_saturday ? quoteData.quotes[props.item.index].broker_profile.hours_saturday : 'Closed'}</span>
          </h5>
          <h5>Su
            : <span>{quoteData.quotes[props.item.index].broker_profile.hours_sunday ? quoteData.quotes[props.item.index].broker_profile.hours_sunday : 'Closed'}</span>
          </h5>
        </div>
      }
    </>
  )
};

export default QuoteItemDescription;
