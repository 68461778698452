import React, {useContext} from "react";
import {Redirect, Route} from "react-router-dom";

import GlobalDataContext from "../../contexts/GlobalDataContext";

const AuthRoute = ({children, ...rest}) => {

    const {globalData} = useContext(GlobalDataContext);

    return (
        <Route exact
            {...rest}
            render={({location}) =>
                !globalData.isAuth ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: globalData.signedInRedirect==='thirdFormSecondPart' ? `/${globalData.partner.id}/info` : `/${globalData.partner.id}/profile/quotes-history`,
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
};

export default AuthRoute;
