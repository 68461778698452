import React, {useEffect, useReducer, useState} from "react";
import {formStateReducer, formStateActionTypes} from "../../../helpers/formState";

const VehicleNumberInput = (props) => {
    const [isRender, setIsRender] = useState(false);
    const [localState, updateLocalState] = useReducer(formStateReducer, {defaultValue: props.defaultValue});

    const handleBlur = (event) => {
        let nmb = Number(event.target.value);
        if (nmb < Number(props.min)) {
            nmb = Number(props.min);
        }
        if (nmb > Number(props.max || 16)) {
            nmb = Number(props.max || 16);
        }
        if (Number(event.target.value) !== Number(localState.defaultValue)) {
            updateLocalState({type: formStateActionTypes.Update, payload: {defaultValue: nmb}});
            props.onChange({...event, target: {...event.target, value: nmb}});
        }
    }

    const handleChange = (event) => {
        updateLocalState({type: formStateActionTypes.Update, payload: {defaultValue: event.target.value}});
        props.onChange(event);
    }

    useEffect(() => {
        if (Number(props.defaultValue) !== Number(localState.defaultValue)) {
            setIsRender(false);
            updateLocalState({type: formStateActionTypes.Update, payload: {defaultValue: props.defaultValue}});
        } else {
            if (!isRender)
                setIsRender(true);
        }
    }, [props, isRender, localState.defaultValue]);

    return (
        <>
            {
                isRender &&
                <input
                    type='number'
                    className={props.className}
                    placeholder={props.placeholder}
                    defaultValue={props.defaultValue}
                    min={props.min}
                    disabled={props.disabled}
                    max={props.max || ''}
                    name={props.name}
                    onChange={(event) => handleChange(event)}
                    onBlur={(event) => handleBlur(event)}
                />
            }
        </>
    );
};
export default VehicleNumberInput;
