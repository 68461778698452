import React from "react";

export const NavigationRightLongArrow = (props) => {
    return(
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 21.3 15"
        >
            <path
                fill={props.primaryColor ? props.primaryColor : "#FFFFFF"}
                d="M14.4,0.2c-0.3-0.3-0.8-0.3-1.1,0S13,1,13.3,1.3l0,0l5.5,5.5h-18C0.3,6.7,0,7.1,0,7.5c0,
                0,0,0,0,0 c0,0.4,0.3,0.8,0.8,0.8h18l-5.5,5.4c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,
                0c0,0,0,0,0,0L21.1,8c0.3-0.3,0.3-0.8,0-1.1 L14.4,0.2z"
            />
        </svg>
    );
}
