import React from "react";

export const ValidationApprove = (props) => {
    const {width, height, classNames} = props;
    const iconWidth = width || 50;
    const iconHeight = height || 50;
    const iconClassName = `icon-svg ${classNames || 'icon-primary'}`;
    return(
        <svg
            version="1.1"
            id="svg-icon-approve"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox={`0 0 ${iconWidth} ${iconHeight}`}
            style={{enableBackground:`new 0 0 ${iconWidth} ${iconHeight}`}}
            className={iconClassName}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#02B917"
                d="M47.3,12c0.9-0.8,1-2.1,0.2-3c-0.8-0.9-2.1-1-3-0.2c-0.1,0.1-0.1,0.1-0.2,0.2L17.6,35.9L5.6,23.9
                c-0.8-0.8-2.2-0.8-3,0s-0.8,2.2,0,3l13.4,13.4c0.8,0.8,2.1,0.8,3,0c0,0,0,0,0,0L47.3,12z"
            />
        </svg>
    )
};
