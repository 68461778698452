import React from 'react';
import {
    NavigationApplication,
    NavigationCar,
    NavigationDrivingLicence,
    NavigationDiscount,
} from "../../../icons";

export const vehicleSteps = [
    {key: 0, path: `/`, name: 'Step 1', title: 'Vehicle Info', icon: <NavigationCar />},
    {key: 1, path: `/driver`, name: 'Step 2', title: 'Driver Info', icon: <NavigationDrivingLicence />},
    {key: 2, path: `/info`, name: 'Step 3', title: 'Discount Info', icon:  <NavigationDiscount />},
    {key: 3, path: `/quote`, name: 'Step 4', title: 'Your Quotes', icon:  <NavigationApplication />}
];
