import React, {useContext} from "react";
import ButtonFormClear from "../../../ui/header/ButtonFormClear";
import ViewContext from "../../../../contexts/ViewContext";
import {viewModalActionTypes, viewRedirectActionTypes} from "../../../../contexts/ViewContext/viewContextActions";
import {vehicleSteps} from "./constants";
import GlobalDataContext from "../../../../contexts/GlobalDataContext";
import {NavigationLeftLongArrow, NavigationRightLongArrow} from "../../../icons";

const FormControlButtons = () => {

    const {globalData} = useContext(GlobalDataContext);
    const {viewHeader, updateViewRedirect, updateViewModal} = useContext(ViewContext);

    function previous() {
        if (viewHeader.vehicleId > 0) {
            updateViewRedirect({
                type: viewRedirectActionTypes.Redirect,
                payload: `/${globalData.partner.id}${vehicleSteps[(viewHeader.vehicleId - 1)].path}`
            })
        }
    }

    function next() {
        if (viewHeader.vehicleId < vehicleSteps.length) {
            updateViewRedirect({
                type: viewRedirectActionTypes.Redirect,
                payload: `/${globalData.partner.id}${vehicleSteps[(viewHeader.vehicleId + 1)].path}`,
            })
        }
    }

    function finishQuotes() {
        updateViewModal({
            type: viewModalActionTypes.OpenFinaleModal
        });
    }

    return(
        <div className="btns">
            {
                viewHeader.vehicleId === 0 &&
                <ButtonFormClear />

            }
            {
                viewHeader.vehicleId > 0 &&
                <button
                    style={viewHeader.vehicleId > 0 ? {} : {display: 'none'}}
                    className={viewHeader.vehicleId === 3 ? 'last-prev-btn' : 'prev-btn'}
                    onClick={() => {
                        previous();
                    }}
                >
                    <NavigationLeftLongArrow
                        primaryColor="var(--secondary-color)"
                    />
                </button>
            }
            {
                viewHeader.vehicleId < 3 &&

                <button
                    style={viewHeader.vehicleId < 3 ? {} : {display: 'none'}}
                    className="next-btn"
                    onClick={() => next()}
                >
                    <NavigationRightLongArrow />
                </button>
            }
            {
                viewHeader.vehicleId === 3 &&

                <button
                    style={viewHeader.vehicleId === 3 ? {} : {display: 'none'}}
                    className="finish-btn" onClick={finishQuotes}
                >
                    Finish
                </button>
            }
        </div>
    );
}

export default FormControlButtons;
