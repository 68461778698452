import {useReducer, useEffect} from "react";
import useFormReducer from "./useFormReducer";
import {UseFormActionTypes, UseFormInputTypes} from "./constatns";
import {requestStatusTypes} from "../../config/constants";
import {InputTypes} from "../../components/ui/Inputs/constants";

const createExportFormData = (data) => {
    let exportFormData = {};
    for (let i in data) {
        if (data[i].componentType !== InputTypes.FIELD_READONLY) {
            exportFormData[data[i].formName] = data[i].value;
        }
    }
    return exportFormData;
};


const UseForm = (props) => {

    const validationInput = (item) => {

        let isValid = false;
        let errorMessage = '';

        if (item.validationType === UseFormInputTypes.PASSWORD || item.validationType === UseFormInputTypes.PASSWORD_CONFIRM || item.validationType === UseFormInputTypes.PASSWORD_CURRENT) {
            if (item.value.match(/^(?=.*\d)[0-9a-zA-Z]{8,}$/)) {
                if (item.validationType === UseFormInputTypes.PASSWORD_CONFIRM) {
                    const paswordCheck = props.formData.filter(itemCheckFilter => itemCheckFilter.validationType === UseFormInputTypes.PASSWORD && itemCheckFilter.value === item.value);
                    if (paswordCheck.length > 0) {
                        isValid = true;
                        errorMessage = '';
                    } else {
                        isValid = false;
                        errorMessage = 'Please check your confirm password';
                    }
                } else {
                    isValid = true;
                    errorMessage = '';
                }
            } else {
                errorMessage = 'Your password must be at least 8 characters and include one number';
            }
        }


        /* email validation */
        if (item.validationType === UseFormInputTypes.EMAIL) {
            // eslint-disable-next-line no-useless-escape
            if (item.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                isValid = true;
                errorMessage = '';
            } else {
                errorMessage = 'Please enter your email address in format example@example.com';
            }
        }

        /* name validation */
        if (item.validationType === UseFormInputTypes.NAME) {
            if (item.value.match(/^[a-zA-Z]{2,}$/)) {
                isValid = true;
            } else {
                errorMessage = item.defaultErrorMessage;
            }
        }

        return {
            isValid: isValid,
            errorMessage: errorMessage
        };
    };

    const Initial_state = {
        errorMessage: '',
        submitMessage: '',
        disableFormInput: false,
        disableSubmitButton: true
    };

    const [localState, updateLocalState] = useReducer(useFormReducer, Initial_state);


    useEffect(() => {
        let checker = false;
        for (let x in props.formData) {
            if (!props.formData[x].isValid) {
                checker = true;
            }
        }
        updateLocalState({type: UseFormActionTypes.DISABLE_SUBMIT_BUTTON, data: {disable: checker}});
    }, [props.formData]);

    useEffect(() => {
        if (props.requestState === requestStatusTypes.Loading || (props.requestState === requestStatusTypes.Success && !props.formLoop)) {
            updateLocalState({type: UseFormActionTypes.DISABLE_FORM_INPUT, data: {disable: true}});
        } else {
            updateLocalState({type: UseFormActionTypes.DISABLE_FORM_INPUT, data: {disable: false}});
        }
    }, [props.requestState, props.formLoop]);

    const datePickerHandler = (date, name) => {
        let newForm = props.formData;

        for (let i in newForm) {
            if (newForm[i].name === name) {
                newForm[i].localValue = date;
                newForm[i].value = date;
                newForm[i].isValid = true;
            }
        }
        props.updateFormData([...newForm]);
    };

    const selectHandler = (event) => {
        event.persist();
        let newForm = props.formData;
        for (let i in newForm) {
            if (newForm[i].name === event.target.name) {
                newForm[i].value = event.target.value;
                newForm[i].isValid = true;
            }
        }
        props.updateFormData([...newForm]);
    };

    const switchHandler = (switchId, targetName) => {
        let newForm = props.formData;
        for (let i in newForm) {
            if (newForm[i].name === targetName) {
                newForm[i].localValue = switchId;
                newForm[i].value = newForm[i].switchItems[switchId].value;
                newForm[i].isValid = true;
            }
        }
        props.updateFormData([...newForm]);
    };

    const inputHandler = (event) => {

        event.persist();

        if (event.target.value) {

            let newForm = props.formData;

            for (let i in newForm) {
                if (newForm[i].name === event.target.name) {
                    newForm[i].value = event.target.value;
                    const validItem = validationInput(newForm[i]);
                    newForm[i].isValid = validItem.isValid;
                    if (validItem.isValid) {
                        newForm[i].errorMessage = validItem.errorMessage;
                    }
                }
            }

            props.updateFormData([...newForm]);
        }
    };

    const inputBlur = (event) => {

        event.persist();

        if (event.target.value) {

            let newForm = props.formData;

            for (let i in newForm) {
                if (newForm[i].name === event.target.name) {
                    const validItem = validationInput(newForm[i]);
                    newForm[i].value = event.target.value;
                    newForm[i].errorMessage = validItem.errorMessage;
                    newForm[i].isValid = validItem.isValid;
                }
            }

            props.updateFormData([...newForm]);
        }
    };

    const submitHandler = (event) => {
        event.preventDefault();
        const exportBody = createExportFormData(props.formData);
        props.submitHandler(exportBody);
    };

    return ({
        errorMessage: localState.errorMessage,
        submitMessage: localState.submitMessage,
        disableFormInput: localState.disableFormInput,
        disableSubmitButton: localState.disableSubmitButton,
        inputHandler: inputHandler,
        inputBlur: inputBlur,
        selectHandler: selectHandler,
        switchHandler: switchHandler,
        datePickerHandler: datePickerHandler,
        submitHandler: submitHandler
    });
};

export default UseForm;
