import React, {useContext, useEffect, useReducer, useState} from "react";

import {UserDataManager} from "../../managers/DataManagers";
import {InputTypes} from "../ui/Inputs/constants";
import {UseFormInputTypes} from "../../helpers/UseForm/constatns";

import {formGenderTypes, formOccupationTypes, formMaritalTypes} from '../../config/formConstants';
import InputWrapper from "../ui/Inputs/InputWrapper";
import UseForm from "../../helpers/UseForm";
import ButtonSubmit from "../ui/buttons/ButtonSubmit";
import {requestStatusTypes} from "../../config/constants";
import {MyProfileActionTypes, myProfileInitialState} from "./constants";
import {myProfileReducer} from "./myProfileReducer";
import {Link} from "react-router-dom";
import {currentDate} from "../../config/dateConfig";
import GlobalDataContext from "../../contexts/GlobalDataContext";

function updateLocalUserData(data) {
    const genderActiveValueArray = formGenderTypes.filter((item) => {
        return item.value === data.gender
    }).map((mItem) => {
        return mItem.id
    });

    const genderActiveValue = genderActiveValueArray.length > 0 ? genderActiveValueArray[0] : 0;
    const maritalActiveValueArray = formMaritalTypes.filter((item) => {
        return item.value === data.marital_status
    }).map((mItem) => {
        return mItem.id
    });

    const maritalActiveValue = maritalActiveValueArray.length > 0 ? maritalActiveValueArray[0] : 0;
    const occupationActiveValueArray = formOccupationTypes.filter((item) => {
        return item.value === data.occupation
    }).map((mItem) => {
        return mItem.id
    });

    const occupationActiveValue = occupationActiveValueArray.length > 0 ? occupationActiveValueArray[0] : 0;

    return [
        {
            title: 'First Name',
            name: 'first_name',
            toolTip: '',
            defaultErrorMessage: 'Please enter your First Name',
            placeholder: 'First Name',
            formName: 'first_name',
            type: 'text',
            autoComplete: 'first-name',
            componentType: InputTypes.FIELD,
            validationType: UseFormInputTypes.NAME,
            value: data.first_name,
            errorMessage: '',
            isValid: true
        },
        {
            title: 'Last Name',
            name: 'last_name',
            toolTip: '',
            defaultErrorMessage: 'Please enter your Last Name',
            placeholder: 'Last Name',
            formName: 'last_name',
            type: 'text',
            autoComplete: 'last-name',
            componentType: InputTypes.FIELD,
            validationType: UseFormInputTypes.NAME,
            value: data.last_name,
            errorMessage: '',
            isValid: true
        },
        {
            title: 'Marital Status',
            name: 'marital_status',
            toolTip: '',
            defaultErrorMessage: 'Please choose Your Gender',
            placeholder: '',
            formName: 'marital_status',
            type: 'switch',
            autoComplete: 'last-name',
            componentType: InputTypes.SELECT,
            validationType: UseFormInputTypes.SELECT,
            switchItems: formMaritalTypes,
            localValue: maritalActiveValue,
            value: data.marital_status ? data.marital_status : formMaritalTypes[0].value,
            errorMessage: '',
            isValid: true
        },
        {
            title: 'Date of birth',
            name: 'dob',
            toolTip: '',
            defaultErrorMessage: 'Please choose Your Gender',
            placeholder: '',
            formName: 'dob',
            type: 'date',
            autoComplete: 'off',
            componentType: InputTypes.DATE,
            validationType: UseFormInputTypes.DATE,
            localValue: data.dob,
            value: data.dob,
            errorMessage: '',
            max: currentDate,
            isValid: true
        },
        {
            title: 'What is your main Occupation',
            name: 'occupation',
            toolTip: '',
            defaultErrorMessage: 'Please choose Your Gender',
            placeholder: '',
            formName: 'occupation',
            type: 'switch',
            autoComplete: 'last-name',
            componentType: InputTypes.SWITCH,
            validationType: UseFormInputTypes.SWITCH,
            switchItems: formOccupationTypes,
            localValue: occupationActiveValue,
            value: data.occupation ? data.occupation : formOccupationTypes[0].value,
            errorMessage: '',
            isValid: true
        },
        {
            title: 'Gender',
            name: 'gender',
            toolTip: '',
            defaultErrorMessage: 'Please choose Your Gender',
            placeholder: '',
            formName: 'gender',
            type: 'switch',
            autoComplete: 'last-name',
            componentType: InputTypes.SWITCH,
            validationType: UseFormInputTypes.SWITCH,
            switchItems: formGenderTypes,
            localValue: genderActiveValue,
            value: data.gender,
            errorMessage: '',
            isValid: true
        },
        {
            title: 'Email',
            name: 'email',
            toolTip: '',
            defaultErrorMessage: 'Please enter your Last Name',
            placeholder: 'Last Name',
            formName: 'email',
            type: 'text',
            autoComplete: 'last-name',
            componentType: InputTypes.FIELD_READONLY,
            validationType: UseFormInputTypes.NO_VALIDATION,
            value: data.email,
            errorMessage: '',
            readOnly: true,
            isValid: true
        }
    ]
}

const ProfileEditForm = (props) => {
    const [formData, updateFormData] = useState(props.userData);
    const {updateUserData, userRequestState, userErrorMessage, userSuccessMessage} = UserDataManager();

    const {inputHandler, inputBlur, switchHandler, selectHandler, datePickerHandler, submitHandler, disableFormInput, disableSubmitButton} = UseForm({
        submitHandler: updateUserData,
        requestState: userRequestState,
        formData: formData,
        updateFormData: updateFormData,
        formLoop: true
    });
    return (
        <form>
            <div className='myProfileInfo'>
                <div className='myProfileEditLeftSide'>
                    {
                        props.userData &&
                        props.userData.filter((itemF, keyF) => {
                            return keyF < props.userData.length / 2
                        }).map((item, key) => {
                            return (
                                <InputWrapper
                                    key={`signin${key}`}
                                    item={item}
                                    disableFormInput={disableFormInput}
                                    inputHandler={inputHandler}
                                    switchHandler={switchHandler}
                                    inputBlur={inputBlur}
                                    selectHandler={selectHandler}
                                    datePickerHandler={datePickerHandler}
                                />
                            );

                        })
                    }
                </div>
                <div className='myProfileEditRightSide'>
                    {
                        props.userData &&
                        props.userData.filter((itemF, keyF) => {
                            return keyF >= props.userData.length / 2
                        }).map((item, key) => {
                            return (
                                <InputWrapper
                                    key={`signin${key}`}
                                    item={item}
                                    disableFormInput={disableFormInput}
                                    inputHandler={inputHandler}
                                    switchHandler={switchHandler}
                                    inputBlur={inputBlur}
                                    selectHandler={selectHandler}
                                />
                            );
                        })
                    }
                </div>
            </div>

            {
                userErrorMessage &&
                <span className='errorMsg'>{userErrorMessage}</span>
            }
            {
                userSuccessMessage &&
                <span className='SuccessMsg'>{userSuccessMessage}</span>
            }

            <div className='btnWrapper'>
                <ButtonSubmit
                    classNames="profileActionBtn"
                    title="Save"
                    requestState={userRequestState}
                    SuccessMessage={userSuccessMessage}
                    disabled={disableSubmitButton}
                    submitHandler={submitHandler}
                    formLoop={true}
                />
            </div>
        </form>
    );
};

export const MyProfileEdit = () => {

    const [localState, updateLocalState] = useReducer(myProfileReducer, myProfileInitialState);
    const {globalData} = useContext(GlobalDataContext);

    const {getUserData, userResponseData, userRequestState} = UserDataManager();

    const [userData, updateUserData] = useState([]);

    useEffect(() => {
        if (userRequestState === requestStatusTypes.Success) {
            updateUserData(updateLocalUserData(userResponseData));
        }
    }, [userResponseData, userRequestState]);

    useEffect(() => {
        if (!localState.isFirst) {
            getUserData();
            updateLocalState({type: MyProfileActionTypes.UPDATE_IS_FIRST, payload: true});
        }
    });

    return (
        <div className='myProfileWrapper'>
            {
                userData.length > 0 &&
                <>
                    <ProfileEditForm userData={userData}/>
                    <Link to={`/${globalData.partner.id}/profile/password/change`} className='passChangeLink'>Change Password</Link>
                </>
            }
        </div>
    );
};

