import React from "react";
import FormThreeWrapper from "./FormThreeWrapper";

const FormThree = () => {

    return(
        <div className="add-vehicles-content-wrapper">
            <div className="addVehiclesFormContentWrapper">
                <FormThreeWrapper />
            </div>
        </div>
    );
}

export default FormThree;
