import React, {useContext, useEffect, useReducer} from "react";
import {useHistory, useLocation} from 'react-router-dom';

import ViewContext from "../contexts/ViewContext/ViewContext";
import {
    viewHeaderActionTypes, viewModalActionTypes,
    viewRedirectActionTypes
} from "../contexts/ViewContext/viewContextActions";
import {renderInitialState, renderStateActionTypes, renderStateReducer} from "../helpers/hooksLocalRenderState";
import GlobalDataContext from "../contexts/GlobalDataContext";
import TagManager from "react-gtm-module";
import UseClearForm from "../helpers/UseClearForm";

export const ContentRedirect = () => {

    let history = useHistory();
    const {viewRedirect, updateViewRedirect} = useContext(ViewContext);

    useEffect(() => {
        if (viewRedirect.isRedirect) {
            history.push(viewRedirect.redirectPath);
            updateViewRedirect({type: viewRedirectActionTypes.Redirected});
        }
    }, [viewRedirect.isRedirect, history, updateViewRedirect, viewRedirect.redirectPath]);


    return null;
};

export const RouterListener = () => {

    const [renderState, updateRenderState] = useReducer(renderStateReducer, renderInitialState);

    const {globalData} = useContext(GlobalDataContext);

    const { checkIsExpiredWithClear } = UseClearForm();

    const {viewHeader, updateViewHeader, updateViewModal, viewModal} = useContext(ViewContext);

    let routeLocation = useLocation();

    const vehiclePaths = [
        {
            id: 0,
            path: `/${globalData.partner.id}`,
        },
        {
            id: 0,
            path: `/${globalData.partner.id}/`,
        },
        {
            id: 1,
            path: `/${globalData.partner.id}/driver`,
        },
        {
            id: 2,
            path: `/${globalData.partner.id}/info`,
        },
        {
            id: 3,
            path: `/${globalData.partner.id}/quote`,
        }
    ];

    const leavePage = () => {

        let checkRoute = vehiclePaths.filter(item => item.path === window.location.pathname);

        if(checkRoute.length>0) {

            if (window.location.pathname === '/') {
                //localStorage.removeItem("vehicles");
            }

            if (window.location.pathname === '/driver') {
                //localStorage.removeItem("drivers");
            }

            return "Leaving this page will reset the wizard";
        }
        else{
            return false;
        }
    }

    useEffect(() => {

        checkIsExpiredWithClear();

        let isOpenVehicleNav = false;
        let formId = -1;

        for (let k in vehiclePaths) {
            if (routeLocation.pathname === vehiclePaths[k].path) {
                isOpenVehicleNav = true;
                formId = Number(vehiclePaths[k].id);
            }
        }

        if (formId > -1) {
            updateViewHeader({type: viewHeaderActionTypes.UpdateVehicleId, payload: formId});
            if(!globalData.isPostal){
                updateViewModal({type:viewModalActionTypes.OpenPostalModal});
            }
        }
        else{
            if(!globalData.isPostal){
                if(viewModal.isOpen){
                    updateViewModal({type:viewModalActionTypes.ClearModal});
                }
            }
        }

        if (isOpenVehicleNav !== viewHeader.vehicleNavigation) {
            updateViewHeader({type: viewHeaderActionTypes.UpdateVehicleHeader, payload: isOpenVehicleNav});
        }


        if (viewHeader.isMobileOpen) {
            updateViewHeader({type: viewHeaderActionTypes.UpdateMobileHeader, payload: false});
        }
        if (viewHeader.isDesktopOpen) {
            updateViewHeader({type: viewHeaderActionTypes.UpdateDesktopHeader, payload: false});
        }

        if(process.env.REACT_APP_ENV==='production') {
            TagManager.dataLayer({
                dataLayer: {
                    page: routeLocation.pathname
                },
                dataLayerName: 'PageDataLayer'
            });
        }

        window.scrollTo(0, 0);

    }, [routeLocation]);

    useEffect(() => {
        if (renderState.isFirst) {

            window.onbeforeunload = (event) => {
                let checker = leavePage();
                if(checker){
                    return checker;
                }

            }
            updateRenderState({type: renderStateActionTypes.UpdateFirst, payload: false});
        }
    });


    return null;
};
